import { useEffect, useState } from 'react';
import Hub from '../models/Hub';
import Site from '../models/Site';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import { HubStatusResponse } from '../models/HubStatusResponse';

const useSiteHubStatuses = (accountId: string, site: Site): [Record<string, HubStatusResponse | null> | null, string] => {

  const siteBionicsApplication = useSiteBionicsApplication();
  const [hubStatuses, setHubStatuses] = useState<Record<string, HubStatusResponse | null> | null>(null);  
  const [color, setColor] = useState("grey");

  useEffect(() => {

    const fetchStatuses = async () => {
        const statusPromises = site.hubs!.map(hub =>
            siteBionicsApplication.service.getHubStatus(accountId, site.siteId, hub.id, false)
        );
        
        const hubStatusResponses = await Promise.all(statusPromises);

        const newStatuses: Record<string, HubStatusResponse | null> = {};

        let newColor = "green";
        for (let i = 0; i < hubStatusResponses.length; i++) {
            newStatuses[site.hubs![i].hubId] = hubStatusResponses[i];
            
            if (hubStatusResponses[i] === null) {
                newColor = "red";
            } else {
                const hubColor = hubStatusResponses[i]!.getHubStatusHealth();
                if (hubColor === "red") {
                    newColor = "red";
                } else if (hubColor === "yellow" && newColor != "red") {
                    newColor = "yellow";
                }                
            }
        }

        setColor(newColor);

        setHubStatuses(newStatuses);
    };

    if (!site.hubs) {
        site.loadHubsAsync();
        return;
    }

    fetchStatuses();

    // Schedule subsequent fetches
    const interval = setInterval(() => {
      fetchStatuses();
    }, 10 * 1000); // 10 seconds

    // Clear interval after 5 attempts
    const timeout1 = setTimeout(() => {
      clearInterval(interval);
    }, 5 * 10 * 1000); // 5 attempts, every 10 seconds

    // Schedule fetch every minute after initial attempts
    const minuteInterval = setInterval(() => {
      fetchStatuses();
    }, 60 * 1000); // Every minute

    // Clear interval after 4 attempts
    const timeout2 = setTimeout(() => {
      clearInterval(minuteInterval);
    }, 4 * 60 * 1000); // 4 attempts, every minute

    // Schedule fetch every 5 minutes after minute
    const fiveMinuteInterval = setInterval(() => {
      fetchStatuses();
    }, 5 * 60 * 1000); // Every 5 minutes

    return () => {
      clearInterval(interval);
      clearInterval(minuteInterval);
      clearInterval(fiveMinuteInterval);
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [site.hubs]);

  return [hubStatuses, color];
};

export default useSiteHubStatuses;