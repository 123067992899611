import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { observer } from "mobx-react-lite";
import { useForm } from 'react-hook-form';

interface NameDialogProps {

  title: string;

  name?: string;

  saveLabel?: string;

  nameLabel?: string;

  onClose: () => void;

  onSave: (name: string) => void;
}

interface FormValues {
  name: string;
}

const NameDialog: React.FC<NameDialogProps> = ({
    title,
    name = "",
    saveLabel = "Save",
    nameLabel = "Name",
    onClose,
    onSave
 }) => {
  
  //const [formData, setFormData] = useState({name: name})
  const {register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      name: name
    },
    mode: "onBlur"
  });

      
  const handleCancelEditClick = () => {
    onClose();
  }

  const handleSave = (data: FormValues) => {        
      onSave(data.name);    
  };

  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    
    if (reason === "backdropClick") return;

    onClose();

  }
    
  return (
    <>    
      <Dialog open={true} onClose={handleCancelLocal} maxWidth="lg" >
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSave)} >
          <DialogContent>

            <TextField 
              id="name" 
              label={nameLabel} 
              margin="dense" 
              type="string" 
              fullWidth 
              required
              variant="standard"
              {...register("name", { required: nameLabel + " is required"})}
              error={!!(touchedFields["name"] && errors["name"])}
              helperText={touchedFields["name"] && errors.name?.message}               
            /> 
              
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleCancelEditClick} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="outlined" disabled={!isValid} color="primary">
              {saveLabel}
            </Button>
          
          </DialogActions>
        </form>
      </Dialog>      

</>
  )
};

export default observer(NameDialog);