
import { action, makeObservable, observable } from "mobx";

export default class Address {

    @observable street1: string;
    @observable street2: string;
    @observable country: string;
    @observable city: string;
    @observable state: string;
    @observable postalCode: string;
    
    constructor(street1: string, street2: string, country: string, city: string, state: string, postalCode: string) {
        this.street1 = street1;
        this.street2 = street2;
        this.country= country;
        this.city = city;
        this.state= state;
        this.postalCode = postalCode;        
        
        makeObservable(this);   
    }    

    @action
    setStreet1(street1: string) {
        this.street1 = street1;
    }

    @action
    setStreet2(street2: string) {
        this.street2 = street2;
    }

    @action
    setCity(city: string) {
        this.city = city;
    }

    @action
    setState(state: string) {
        this.state = state;
    }

    @action
    setPostalCode(postalCode: string) {
        this.postalCode = postalCode;
    }

    @action
    setCountry(country: string) {
        this.country = country;
    }

    @action
    updateFrom(data: Address) {
        this.street1 = data.street1;
        this.street2 = data.street2;
        this.country = data.country;
        this.city = data.city;
        this.state = data.state;
        this.postalCode = data.postalCode;
    }

    clone(): Address {
        return new Address(this.street1, this.street2, this.country, this.city, this.state, this.postalCode);
    }
}
