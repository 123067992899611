import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, MenuItem, TextField, Grid } from '@mui/material';

export interface FilterEntry {
    tag: string;
    operation: 'None' | 'GreaterThan' | 'LessThan';
    duration: number;
}

interface FilterDialogProps {
    open: boolean;
    onClose: () => void;
    entries: FilterEntry[];
    onSave: (updatedEntries: FilterEntry[]) => void;
}

const FilterDialog: React.FC<FilterDialogProps> = ({ open, onClose, entries, onSave }) => {
    const [localEntries, setLocalEntries] = useState<FilterEntry[]>([]);

    // Update localEntries when entries prop changes
    useEffect(() => {
        setLocalEntries([...entries]);
    }, [entries]);

    const handleOperationChange = (index: number, operation: 'None' | 'GreaterThan' | 'LessThan') => {
        const updatedEntries = [...localEntries];
        updatedEntries[index].operation = operation;
        setLocalEntries(updatedEntries);
    };

    const handleDurationChange = (index: number, duration: number) => {
        const updatedEntries = [...localEntries];
        updatedEntries[index].duration = duration;
        setLocalEntries(updatedEntries);
    };

    const handleSave = () => {
        onSave(localEntries);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Activity Filters</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} paddingTop={2} paddingLeft={2}>
                    {localEntries.length > 0 ? (
                        localEntries.map((entry, index) => (
                            <Grid container key={index} spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
                                <Grid item xs={4}>
                                    <span>{entry.tag}</span>
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        fullWidth
                                        value={entry.operation}
                                        onChange={(e) => handleOperationChange(index, e.target.value as 'None' | 'GreaterThan' | 'LessThan')}
                                    >
                                        <MenuItem value="None">None</MenuItem>
                                        <MenuItem value="GreaterThan">Greater Than</MenuItem>
                                        <MenuItem value="LessThan">Less Than</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={4}>
                                    {(entry.operation === 'GreaterThan' || entry.operation === 'LessThan') && (
                                        <TextField
                                            label="Duration (seconds)"
                                            type="number"
                                            value={entry.duration}
                                            onChange={(e) => handleDurationChange(index, Number(e.target.value))}
                                            fullWidth
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <span>No filters available.</span>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
