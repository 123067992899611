import { FunctionComponent, ReactElement } from "react";
import { observer } from "mobx-react";
import { Toolbar, Paper, TableContainer, Box, Typography } from '@mui/material';

interface TitleToolbarProps {
  title?: string;
  children?: ReactElement;
  sx?: React.CSSProperties; // Define sx as an optional prop
}

const TitleToolbar: FunctionComponent<TitleToolbarProps> = observer(({ title, children, sx }) => {
  return (
    <Box component="div" sx={sx}>
      <TableContainer component={Paper}>
        <Toolbar variant="dense" sx={{ justifyContent: "space-between", gap: "10pt" }}>
          {title && 
            <Typography variant="h6" color="inherit" component="div">
              {title}
            </Typography>
          }

          <Box component="div" sx={{display: "flex", gap: "10pt", flexWrap: "wrap"}}>
            {children}
          </Box>
        </Toolbar>
      </TableContainer>
    </Box>
  )
});

export default TitleToolbar;
