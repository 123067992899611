import { FunctionComponent } from "react";
import { observer } from "mobx-react"

import { Box } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs } from "./SitePage";

const Areas : FunctionComponent = observer(() => {

  const breadcrumbs = useSitePageBreadcrumbs("Areas");

  
  return (
    <Box component="div" display="flex" height="100%" overflow="auto" flexDirection="column">

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>
      <Box component="div"  flexGrow={1}>
      </Box>
    </Box>
  )
})

export default Areas;