import TrackedObject from "./TrackedObject";

export enum TrackedObjectEventType { Entered, Exited, Dwelled, Interacted, MadeTransaction }

export default class TrackledObjectEvent {

    trackedObject?: TrackedObject;
    trackedObjectEventId: string;
    eventType: TrackedObjectEventType;
    startTime: Date;
    endTime: Date;
    zone: string;
    

    constructor(trackedObjectEventId: string, eventType: TrackedObjectEventType, startTime: Date, endTime: Date, zone: string, trackedObject?: TrackedObject) {
        this.trackedObject = trackedObject;
        this.trackedObjectEventId = trackedObjectEventId;
        this.eventType = eventType;
        this.startTime = startTime;
        this.endTime = endTime;
        this.zone = zone;
    }

}