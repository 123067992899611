import React, {useEffect, ReactElement}  from 'react';

interface WithTitleProps {
  title?: string;
  children?: ReactElement;
}


const WithTitle: React.FC<WithTitleProps> = ({
    title,
    children
  }) => {


    useEffect(() => {
        if (title) {
            document.title = "Site Bionics - " + title;
        }
      }, [title]);

  
  
    return (
        <>
            {children}
        </>
    );
};

export default WithTitle;
