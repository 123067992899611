import { FunctionComponent, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import { Color3 } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import TriggerEntity from "../models/layout/TriggerEntity";
import { ActionManager, ExecuteCodeAction, Mesh } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Vector3Model from "../models/layout/Vector3Model";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";

const TriggerLayer: FunctionComponent<{trigger: TriggerEntity, layoutViewModel: ScanAreaLayoutViewModel}> = observer(({trigger, layoutViewModel}) => {
  const transformNodeRef = useRef<Mesh>(null);
  const meshRef = useRef<Mesh>(null);
  const scene = useScene();

  useEffect(() => {
    if (scene && meshRef.current) {
        meshRef.current.actionManager = new ActionManager(scene);
        meshRef.current.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, (event: any) => {
                layoutViewModel.selectEntity(trigger);
            })
        );
    }
  }, [scene, meshRef.current]);

  let isSelected = layoutViewModel.selectedEntity === trigger;

  return (
    <>
          <transformNode name="transformNode" ref={transformNodeRef} position={new Vector3(-(trigger.pose.position.x), trigger.pose.position.y, trigger.pose.position.z)}>
            {layoutViewModel.selectedEntity === trigger && 
              <positionGizmo
                        onDragEndObservable={ (e:any) => {
                          trigger.pose.setPosition(new Vector3Model({x: -transformNodeRef.current!.position.x, y: transformNodeRef.current!.position.y, z: transformNodeRef.current!.position.z}));
                        }}/>
             }
            <cylinder ref={meshRef} name="cylinder" scaling={new Vector3(trigger.radius, 1, trigger.radius)} diameter={2} height={1} updatable={true} >
              <standardMaterial name="material" alpha={isSelected ? 0.8 : 0.6} diffuseColor={isSelected ? Color3.White() : Color3.Red()} />
            </cylinder>
          </transformNode>
    </>
  )
})

const TriggersLayer: FunctionComponent<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  return (
    <>
      <utilityLayerRenderer>
      {layoutViewModel.scanAreaLayout.triggers.map((trigger, index) => (
        <TriggerLayer key={`trigger-${index}`} trigger={trigger} layoutViewModel={layoutViewModel} />
      ))}
      </utilityLayerRenderer>

    </>
  )
})

export default TriggersLayer;


