
export default class TaggedObjectCounts {

    tag: string;
    time: Date;
    min: number;
    max: number;
    avg: number;
    

    constructor(tag: string, time: Date, min: number, max: number, avg: number) {
        this.tag = tag;
        this.time = time;
        this.min = min;
        this.max = max;
        this.avg = avg;
    }

}