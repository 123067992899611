import { Quaternion, Vector, Vector3 } from '@babylonjs/core';
import { computed } from 'mobx';
import { Model, model, modelAction, prop } from 'mobx-keystone';


@model("SiteBionics/QuaternionModel")
class QuaternionModel extends Model({
    x: prop<number>(0).withSetter(),
    y: prop<number>(0).withSetter(),
    z: prop<number>(0).withSetter(),
    w: prop<number>(1).withSetter(),
    isIdentity: prop<boolean>(true).withSetter(),
  },
  {
    valueType: true
  }
) {

    @computed
    get asQuaternion() : Quaternion {
      return new Quaternion(this.x, this.y, this.z, this.w);
    }

    @computed
    get asLHQuaternion() : Quaternion {
      return new Quaternion(this.x, -this.y, -this.z, this.w);
    }

    @computed
    get asEulerAngles() : Vector3 {
        const RAD_TO_DEG = 180 / Math.PI;
        return this.asQuaternion.toEulerAngles().multiplyByFloats(RAD_TO_DEG, RAD_TO_DEG, RAD_TO_DEG);
    }
  
    static identity(): QuaternionModel {
        return new QuaternionModel({ x: 0, y: 0, z: 0, w: 1, isIdentity: true });
    }

    static fromQuaternion(quaternion: Quaternion): QuaternionModel {
        return new QuaternionModel({ x: quaternion.x , y: quaternion.y, z: quaternion.z, w: quaternion.w, isIdentity: false });
    }

    static fromEulerAngles(eulerAngles: Vector3): QuaternionModel {
        const DEG_TO_RAD = Math.PI / 180;
        let q = Quaternion.FromEulerAngles(eulerAngles.x * DEG_TO_RAD, eulerAngles.y * DEG_TO_RAD, eulerAngles.z * DEG_TO_RAD);
        return this.fromQuaternion(q);
    }

}

export default QuaternionModel;