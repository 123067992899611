import { Vector3 } from "@babylonjs/core";
import TrackedObject from "./TrackedObject";
import TrackletDataPoint from "./TrackletDataPoint";
import Camera from "./Camera";

export enum TrackletType { Fused, CameraDetection }

export default class Tracklet {

    trackletId: string;
    objectId: string;
    trackedObject?: TrackedObject;
    camera?: Camera;
    startTime: Date;
    endTime: Date;
    startsAtEntrance?: string;
    endsAtEntrance?: string;
    dataPoints: TrackletDataPoint[] = [];
    trackletType: TrackletType = TrackletType.CameraDetection;

    constructor(trackletId: string, trackletType: TrackletType, objectId: string, startTime: Date, endTime: Date, startsAtEntrance: string, endsAtEntrance: string, camera?: Camera) {
        this.trackletId = trackletId;
        this.trackletType = trackletType;
        this.objectId = objectId;
        this.camera = camera;
        this.startTime = startTime;
        this.endTime = endTime;
        this.startsAtEntrance = startsAtEntrance;
        this.endsAtEntrance = endsAtEntrance;
    }

    setTrackedObject(trackedObject: TrackedObject) {
        this.trackedObject = trackedObject;
    }

    setCamera(camera: Camera) {
        this.camera = camera
    }

    overlapsTrackedObject (trackedObject: TrackedObject): boolean {
        return !(trackedObject.startTime > this.endTime || trackedObject.endTime < this.startTime);
      }

    overlapsTracklet (tracklet: Tracklet): boolean {
    return !(tracklet.startTime > this.endTime || tracklet.endTime < this.startTime);
    }

    findClosestDataPoint (currentTime: Date): TrackletDataPoint | undefined {
        if (currentTime < this.startTime || currentTime > this.endTime) 
            return undefined;

        let closestDataPoint : TrackletDataPoint | undefined = undefined;
        let minTimeDiff = Infinity;
      
        this.dataPoints.forEach(dp => {
          const timeDiff = Math.abs(currentTime.getTime() - dp.time.getTime());
          if (timeDiff < minTimeDiff) {
            minTimeDiff = timeDiff;
            closestDataPoint = dp;
          }
        });
      
        return closestDataPoint;
      };

    ToPoints(offset : Vector3) : Vector3[] {
        return this.dataPoints.map(dp => { return dp.position.add(offset)});
    }
}