import { FunctionComponent } from "react";
import { observer } from "mobx-react"

import { Box } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";

const Fixtures : FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  
  const breadcrumbs = [
    {name: "Accounts", link: "/accounts"},
    {name: `${siteBionicsApplcation.currentAccount?.accountName}`, link: `/accounts/${siteBionicsApplcation.currentAccount?.accountId}`}, 
    {name: "Sites", link: `/accounts/${siteBionicsApplcation.currentAccount?.accountId}/sites`}, 
    {name: `${siteBionicsApplcation.currentSite?.siteName}`, link: `/accounts/${siteBionicsApplcation.currentAccount?.accountId}/sites/${siteBionicsApplcation.currentSite?.siteId}`}, 
    {name: "Fixtures", link: ""},
  ];
  
  return (
    <Box component="div" display="flex" height="100%" overflow="auto" flexDirection="column">

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>
      <Box component="div"  flexGrow={1}>
      </Box>
    </Box>
  )
})

export default Fixtures;