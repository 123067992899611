import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import GeneralCard from './GeneralCard';
import { Box } from '@mui/material';
import { Storefront } from '@mui/icons-material';
import Account from '../models/Account';
import { NavLink } from "react-router-dom";
import CachedSiteControl from '../pages/CachedSiteControl';
import { observer } from "mobx-react";

import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';

interface AccountSitesCardProps {
    account: Account;
}

const AccountSitesCard : React.FC<AccountSitesCardProps> = observer(({ account }) => {

    const siteBionicsApplication = useSiteBionicsApplication();
        
    useEffect(() => {

        siteBionicsApplication.loadMRUDataAsync();

        account.loadSitesAsync();

    }, [account.sites, siteBionicsApplication.mruData]);

    return (

        <GeneralCard title="Sites"
          icon={<Storefront style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
          moreLink={`/accounts/${account.accountId}/sites`}
        >
          <Box component="div" display="flex" flexDirection="column" sx={{gap: "10px"}}>
            {account.sites ?
              <Typography>Number of sites: {account.sites.length}</Typography>
            :
              <Typography>Loading...</Typography>
            }
            {siteBionicsApplication.mruData?.sites && siteBionicsApplication.mruData.sites[account.accountId] && siteBionicsApplication.mruData.sites[account.accountId].length > 0 &&
              <>
                <Typography>
                  Recent Sites:
                </Typography>
                {siteBionicsApplication.mruData.sites[account.accountId].slice(0,3).map((i) => {
                  return (
                    <NavLink key={i} style={{ color: 'white' }} to={`/accounts/${account.accountId}/sites/${i}`}>
                      <CachedSiteControl siteId={i} accountId={account.accountId} />
                    </NavLink>
                  );
                })}
                  
              
              </>
            }
          </Box>
        </GeneralCard>
    );
})

export default AccountSitesCard;