import { FunctionComponent } from "react";
import { observer } from "mobx-react"

import { Box, Paper, TableContainer, Toolbar, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";

const Dashboards : FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  
  const breadcrumbs = [
    {name: "Accounts", link: "/accounts"},
    {name: `${siteBionicsApplcation.currentAccount?.accountName}`, link: `/accounts/${siteBionicsApplcation.currentAccount?.accountId}`}, 
    {name: "Sites", link: `/accounts/${siteBionicsApplcation.currentAccount?.accountId}/sites`}, 
    {name: `${siteBionicsApplcation.currentSite?.siteName}`, link: `/accounts/${siteBionicsApplcation.currentAccount?.accountId}/sites/${siteBionicsApplcation.currentSite?.siteId}`}, 
    {name: "Dashboards", link: ""},
  ];  

  return (
    <Box component="div" display="flex" height="100%" overflow="auto" flexDirection="column">

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>
      <Box component="div"  flexGrow={1} sx={{ width: '90%', marginLeft: '5%' }}>
      
        <TableContainer component={Paper}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div">
              Dashboards
            </Typography>
            <Box component="div" sx={{ flexGrow: 1}}/>
          </Toolbar>
        </TableContainer>

        <Box component="div" sx={{height: 20}}/>
      
        <TableContainer component={Paper}>
        </TableContainer>
      </Box>
    </Box>
  )
})

export default Dashboards;