import { Vector2, Vector3 } from '@babylonjs/core';
import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';


@model("SiteBionics/Vector2Model")
class Vector2Model extends Model({
    x: prop<number>(0).withSetter(),
    y: prop<number>(0).withSetter()
  },
  {
    valueType: true
  }
) {

  get asVector2() : Vector2 {
    return new Vector2(this.x, this.y);
  }

  static zero(): Vector2Model {
    return new Vector2Model({ x: 0, y: 0 });
  }

  static identity(): Vector2Model {
    return new Vector2Model({ x: 1, y: 1 });
  }

  static fromVector2(value: Vector2): Vector2Model {
    return new Vector2Model({ x: value.x, y: value.y });
  }
}

export default Vector2Model;