import { FC } from "react";
import { observer } from "mobx-react"
import { Box, FormControlLabel, List, ListItemButton, ListItemText, Stack, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CameraIndoorOutlinedIcon from '@mui/icons-material/CameraIndoorOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import SiteNavigator from "../models/SiteNavigator";
import SelectableListItemButton from "./SelectableListItemButton";
import { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";


const CamerasAccordion: FC<{ siteNavigator: SiteNavigator }> = observer(({ siteNavigator }) => {
    const layoutViewModel = useScanAreaLayoutViewModel();

    return (
        <Box component="div" width="100%" display="flex">
            <Accordion disableGutters sx={{ width: "100%" }}
                expanded={layoutViewModel?.showCameras ?? true}
                onChange={(e, expanded) => { layoutViewModel?.setShowCameras(expanded); }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row">
                        <CameraIndoorOutlinedIcon fontSize="small" />
                        <Box component="div" width={10} />
                        <Typography>Cameras</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <List dense={true} sx={{ bgcolor: 'background.paper', overflow: "clip" }}>
                        {layoutViewModel?.scanAreaLayout.cameras.map((c) => (
                            <ListItemButton 
                                key={c.cameraId} 
                                selected={c === layoutViewModel?.selectedEntity} 
                                onClick={(_event)=>{
                                    layoutViewModel?.selectEntity(c);
                                    siteNavigator?.setCurrentCameraById(c.cameraId);
                                     }}>
                                <ListItemText primary={c.camera?.cameraName ?? "Unamed Camera"} />
                           </ListItemButton>
                        ))}
                    </List>
                    <Box component='div' display="flex" flexDirection="row" justifyContent="flex-end">
                        <FormControlLabel control={
                            <Switch 
                                checked={layoutViewModel?.showCameraPane} onChange={(e)=>{layoutViewModel?.setShowCameraPane(e.target.checked)}} size="small" />
                            } label="Show Image" sx={{marginTop: 2}}/>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
})

export default CamerasAccordion;