
import { action, makeObservable, observable } from "mobx";

export default class ProvisionedHub {

    @observable hubId: string;
    @observable primaryThumbprint?: string;
    @observable secondaryThumbprint?: string;
    @observable provisionedByUserId?: string;
    @observable pairingCode?: string;
    @observable pairedSiteId?: string;
    @observable pairedAccountId?: string;
    @observable notes?: string;
    

    constructor(hubId: string, 
            primaryThumbprint?: string,
            secondaryThumbprint?: string,
            provisionedByUserId?: string,
            pairingCode?: string,
            pairedSiteId?: string,
            pairedAccountId?: string,
            notes?: string
        ) {
        this.hubId = hubId
        this.primaryThumbprint = primaryThumbprint;
        this.secondaryThumbprint = secondaryThumbprint;
        this.provisionedByUserId = provisionedByUserId;
        this.pairingCode = pairingCode;
        this.pairedSiteId = pairedSiteId;
        this.pairedAccountId = pairedAccountId;
        this.notes = notes;
        makeObservable(this);
    }

    @action
    setNotes(notes: string) {
        this.notes = notes;
    }

    @action
    reset(pairingCode: string) {
        this.pairedAccountId = undefined;
        this.pairingCode = pairingCode;
    }
}
