import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { Color3, Color4, LinesMesh, MeshBuilder, Vector3 } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Tracklet, { TrackletType } from "../models/Tracklet";
import SiteNavigator from "../models/SiteNavigator";
import TrackedObject from "../models/TrackedObject";

const TrackletLayer: FunctionComponent<{
  tracklet: Tracklet,
  siteNavigator: SiteNavigator,
  onTrackedObjectClicked?: (trackedObject: TrackedObject) => void
}> = observer(({ tracklet, siteNavigator, onTrackedObjectClicked }) => {

  const siteBionicsApplcation = useSiteBionicsApplication();
  const scene = useScene();
  const [currentPosition, setCurrentPosition] = useState<Vector3 | undefined>(undefined);

  useEffect(() => {
    if (siteBionicsApplcation.currentTime !== undefined) {
      let closestDataPoint = tracklet.findClosestDataPoint(siteBionicsApplcation.currentTime.toDate());
      if (closestDataPoint) {
        let pos = new Vector3(closestDataPoint.position.x, closestDataPoint.position.y + 0.5, closestDataPoint.position.z);
        setCurrentPosition(pos);
      } else {
        setCurrentPosition(undefined);
      }
    }
    else {
      setCurrentPosition(undefined);
    }
  }, [tracklet, siteBionicsApplcation.currentTime]);


  useEffect(() => {
    if (!scene ||
      tracklet.dataPoints.length === 0 ||
      (tracklet !== siteNavigator.currentTracklet && tracklet.trackedObject !== siteNavigator.currentTrackedObject)
    ) return;

    var allLines: LinesMesh[] = [];

    // Define the points and colors for the line
    const points = tracklet.ToPoints(new Vector3(0, 0.5, 0));
    const color3 = tracklet.camera?.color ?? Color3.Black();
    const color4 = new Color4(color3.r, color3.g, color3.b, 1)
    const colors = points.map(p => color4)
    // Create the line
    const lines = MeshBuilder.CreateLines("lines", {
      points,
      colors: colors,
      updatable: false
    }, scene);

    allLines.push(lines);

    // Cleanup function to remove the line from the scene
    return () => {
      allLines.forEach(line => line.dispose());
    };
  }, [scene, tracklet, siteNavigator.currentTrackedObject, siteNavigator.currentTracklet]);


  return (
    <>
      {currentPosition &&
        <>
          {tracklet.trackletType === TrackletType.Fused &&
            <transformNode name={`${tracklet.trackletId}`} position={currentPosition}>
              <sphere name={`${tracklet.trackletId}`} diameter={0.4} position={new Vector3(0, 1.5, 0)}>
                <standardMaterial name={`${tracklet.trackletId}-mat`}
                  diffuseColor={tracklet.trackedObject === siteNavigator.currentTrackedObject ? Color3.White() : tracklet.trackedObject?.color} />
                <pointerDragBehavior
                  dragAxis={new Vector3(0, 0, 0)} // using a trick here to disable dragging but enable events to simulate a click handler
                  useObjectOrientationForDragging={true}
                  // onDragStartObservable={(_ : any) => console.log('dragStart')}
                  // onDragObservable={(_ : any) => console.log('drag')}
                  onDragEndObservable={(_: any) => { if (onTrackedObjectClicked) onTrackedObjectClicked(tracklet.trackedObject!); }}
                />
              </sphere>
              <lines name="zaxis"
                points={[new Vector3(0, 0, 0), new Vector3(0, 1.5, 0)]}
                color={tracklet.trackedObject?.color} />
            </transformNode>
          }

          {tracklet.trackletType !== TrackletType.Fused &&
            <sphere name={`${tracklet.trackletId}`} diameter={0.2} position={currentPosition}>
              <standardMaterial name={`${tracklet.trackletId}-mat`}
                diffuseColor={tracklet.camera === undefined || (tracklet && siteNavigator.currentTracklet && tracklet === siteNavigator.currentTracklet) ? Color3.Purple() : tracklet.camera!.color!} />
            </sphere>
          }

        </>
      }

    </>
  )
})

export default TrackletLayer;


