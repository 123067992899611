import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Outlet, useParams} from "react-router-dom";
import Account from "../models/Account";
import NoAccess from "../components/NoAccess";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { accountCache } from '../models/Cache';
import { Box, Typography} from '@mui/material';

const AccountDataContext = createContext<Account | null | undefined>(undefined);

export const useAccount = (): Account => { 
  const context = useContext(AccountDataContext);
  if (context === undefined || context === null) {
    throw new Error("Cannot access account when null or undefined");    
  }
  return context;
};

export function useAccountBreadcrumbs(pageName: string) { 

  const account = useAccount();

  let breadcrumbs: {name: string, link: string}[] = [];
  if (pageName === "") {
    breadcrumbs = [
      {name: "Accounts", link: "/accounts"},
      {name: `${account.accountName}`, link: ""},
    ];
  } else {
    breadcrumbs = [
      {name: "Accounts", link: "/accounts"},
      {name: `${account.accountName}`, link: `/accounts/${account.accountId}`},
      {name: pageName, link: ""}
    ];
  }

  return breadcrumbs;
}

const AccountPage : FunctionComponent = observer(() => {

  const siteBionicsApplication = useSiteBionicsApplication();
  const {accountId} = useParams<{accountId: string}>();  

  const [account, setAccount] = useState<Account | null | undefined>(undefined);

  useEffect(() => {
    if (accountId === undefined) return;
    
    accountCache.getObject(accountId, () => siteBionicsApplication.service.fetchAccountAsync(accountId)).then((account: Account | null) => {
      setAccount(account);
      if (account) {
        siteBionicsApplication.setCurrentAccount(account);
      }
    });    
      
  }, [])

  useEffect(() => {
    
    if (!siteBionicsApplication.mruData) {
      siteBionicsApplication.loadMRUDataAsync();
      return;
    }
    
    if (!accountId || account === undefined) {
      return;
    } else if (account === null) {
      siteBionicsApplication.mruData.removeAccount(accountId);
    } else {
      siteBionicsApplication.mruData.addAccount(accountId);
    }
  }, [account, accountId, siteBionicsApplication, siteBionicsApplication.mruData]);

  
  return (
    <AccountDataContext.Provider value={account}>
      {account &&
        <Outlet/>
      }
      {account === null &&  
        <NoAccess title="Invalid Account" message="Account is either invalid or access is denied."></NoAccess>          
      }
      {account === undefined &&
        <Box component="div" display="flex" flexDirection="column" sx={{padding: '20px'}}> 
          <Typography>Loading...</Typography>
        </Box>        
      }
    </AccountDataContext.Provider>
  )
})

export default AccountPage;