import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import { RoleMap } from "../models/RoleMap";
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import CachedUserControl from "../pages/CachedUserControl";
import { useForm} from "react-hook-form";

interface Props {
    title?: string;
    userId?: string | null;
    siteRoles?: string[] | null;
    siteRolesLabel?: string;
    accountRoles?: string[] | null;
    accountRolesLabel?: string;
    requiresMatch?: boolean;
    onCancel: () => void;
    onSave: (email: string, requiresMatch: boolean, accountRoles: string[], siteRoles: string[]) => void;
    saveButtonLabel?: string;
}

interface FormValues {
  userId: string
}

interface FormData {
  requiresMatch: boolean;
  accountRoles: string[];
  siteRoles: string[];
}

const RoleDialog: React.FC<Props> = ({  
  title = "Account Roles",
  userId = null,
  siteRoles = null,
  accountRoles = null,
  siteRolesLabel = "Site Roles",
  accountRolesLabel = "Account Roles",
  requiresMatch = true,
  onCancel,
  onSave,
  saveButtonLabel = "Save"  
}) => {
  const [roleMap, setRoleMap] = useState<RoleMap | null>(null);

  const [formData, setFormData] = useState<FormData>({
    requiresMatch: requiresMatch,
    accountRoles: accountRoles ? accountRoles : [],
    siteRoles: siteRoles ? siteRoles : []
  });


  const siteBionicsApplcation = useSiteBionicsApplication();

  const {register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      userId: userId ? userId : ""
    },
    mode: "onBlur"
  });

  useEffect(() => {
        
    siteBionicsApplcation.currentAccount!.loadRoleMapAsync().then((roleMap: RoleMap) => {
      setRoleMap(roleMap);
    });        
    
  }, []);

  const handleCheckboxChange = (role: string, type: 'accountRoles' | 'siteRoles') => {
    setFormData(prevState => {
      const roles = prevState[type];
      const updatedRoles = roles.includes(role)
        ? roles.filter(r => r !== role)
        : [...roles, role];
      return {
        ...prevState,
        [type]: updatedRoles,
      };
    });
  };

  const handleRequiresMatchChange = () => {
    setFormData({...formData, requiresMatch: !formData.requiresMatch});
  }

  const handleCancelButton = () => {
    onCancel();
  }

  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    if (reason === "backdropClick") return;

    onCancel();

  }

  const handleSave = (data:FormValues ) => {        
    onSave(data.userId, formData.requiresMatch, formData.accountRoles, formData.siteRoles);
  }

  if (!roleMap) {
    return null; // Render nothing or a loading spinner while fetching role data
  }

  return (
    <Dialog open={true} onClose={handleCancelLocal} >
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(handleSave)}>
      <DialogContent>
        <FormLabel component="legend">User</FormLabel>
        {userId && userId.includes('@') && 
          <div>
            {userId}
          </div>
        }
        {userId && !userId.includes('@') &&
          <CachedUserControl userId={userId}></CachedUserControl>
        }
        {!userId &&
          <TextField
            disabled={!!userId} // Disable if email/userid is present
            required
            margin="dense"
            id="userId"
            label="Email (or user id)"
            type="text"
            fullWidth

            {...register("userId", {
              required: "Email (or user id) is required",
              pattern: {
                // Regex for an email address or a guid
                value: /^(?:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                message: "Must be a valid email or user id"
              }
            })}
            error={!!(touchedFields["userId"] && errors["userId"])}
            helperText={touchedFields["userId"] && errors.userId?.message}      
          />                  
        }

        {(!userId || userId.includes('@')) &&
          <FormControlLabel
            control={<Checkbox checked={formData.requiresMatch} onChange={() => handleRequiresMatchChange }/>}
            onClick={handleRequiresMatchChange}
            label="Requires Match"
          />
        }
        

        {accountRoles &&
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">{accountRolesLabel}</FormLabel>
            <FormGroup>
              {Object.keys(roleMap.accountRoles).map(roleName => (
                <FormControlLabel
                  key={roleName}
                  control={<Checkbox
                    checked={formData.accountRoles.includes(roleName)}
                    onChange={() => handleCheckboxChange(roleName, 'accountRoles')}
                    name={roleName}
                  />}
                  label={roleMap.accountRoles[roleName].friendlyName}
                />
              ))}
            </FormGroup>
          </FormControl>
        }
        {siteRoles && 
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">{siteRolesLabel}</FormLabel>
            <FormGroup>
              {Object.keys(roleMap.siteRoles).map(roleName => (
                <FormControlLabel
                  key={roleName}
                  control={<Checkbox
                    checked={formData.siteRoles.includes(roleName)}
                    onChange={() => handleCheckboxChange(roleName, 'siteRoles')}
                    name={roleName}
                  />}
                  label={roleMap.siteRoles[roleName].friendlyName}
                />
              ))}
            </FormGroup>
          </FormControl>
        }         
      </DialogContent>
      
      <DialogActions>
        <Button variant="outlined" onClick={handleCancelButton} color="secondary">
          Cancel
        </Button>
        <Button type="submit" variant="outlined" disabled={!isValid} color="primary">
          {saveButtonLabel}
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
};

export default RoleDialog;
