import { Color3 } from "@babylonjs/core";
import Tracklet, { TrackletType } from "./Tracklet";
import Site from "./Site";


export enum TrackedObjectType { Person, Vehicle }

export type TimeSpanDictionary = {
    [key: string]: number; // The value is the number of seconds representing the time span
};

export default class TrackedObject {

    site: Site;
    trackedObjectId: string;
    trackedObjectType: TrackedObjectType;
    startTime: Date;
    endTime: Date;
    startsAtEntrance?: string;
    endsAtEntrance?: string;
    isTeamMember: boolean = false; //todo: less RRS specific way to handle this
    color: Color3;
    tracklets: Tracklet[];
    fusedTracklet?: Tracklet;
    timeEngagedWithTags: TimeSpanDictionary = {};


    constructor(site: Site, trackedObjectId: string, trackedObjectType: TrackedObjectType, startTime: Date, endTime: Date, color: Color3, tracklets: Tracklet[], timeEngagedWithTags: TimeSpanDictionary) {
        this.site = site;
        this.trackedObjectId = trackedObjectId;
        this.trackedObjectType = trackedObjectType;
        this.startTime = startTime;
        this.endTime = endTime;
        this.color = color;
        this.timeEngagedWithTags = timeEngagedWithTags;
        this.tracklets = tracklets.filter(t => t.trackletType === TrackletType.CameraDetection);
        tracklets.forEach(tracklet => {
            tracklet.setTrackedObject(this);
            if (tracklet.trackletType === TrackletType.Fused) {
                this.fusedTracklet = tracklet;
            } else {
                if (tracklet.startsAtEntrance && (Math.abs(tracklet.startTime.getTime() - this.startTime.getTime()) < 4000)) {
                    this.startsAtEntrance = tracklet.startsAtEntrance;
                }
                if (tracklet.endsAtEntrance && (Math.abs(tracklet.endTime.getTime() - this.endTime.getTime()) < 4000)) {
                    this.endsAtEntrance = tracklet.endsAtEntrance;
                }
            }
            //todo: this is a hack
            if (this.startsAtEntrance === "BackOfHouse" || this.endsAtEntrance === "BackOfHouse") {
                this.isTeamMember = true;
                this.color = Color3.Green();
            }
            else if (this.startsAtEntrance === undefined && this.endsAtEntrance === undefined) {
                this.color = Color3.Gray();
            } else {
                this.color = Color3.Red();
            }
        });
        
    }

    overlapsTrackedObject (trackedObject: TrackedObject): boolean {
        return !(trackedObject.startTime > this.endTime || trackedObject.endTime < this.startTime);
      }

    overlapsTracklet (tracklet: Tracklet): boolean {
        return !(tracklet.startTime > this.endTime || tracklet.endTime < this.startTime);
      }

}