import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';


const steps = ['Create your site', 'Scan your site', 'Annotate your site', 'Connect your video cameras', 'Monitor your operations'];
const descriptions = [
  'Go to the Site Bionics Dashboard and navigate to the Site Editor. Select the option to create a new site and you will be asked for a few simple details about your site such as a site name, the number of floors, etc.', 
  'Use your mobile phone to scan your site with the Site Bionics app.  For most spaces this will only take a few minutes.  At the end of the scaning process you will upload your model to our service.', 
  'Return  to the Site Editor and you will see the new 3D model of your site. You will now be able to annotate the model of your site with area definitions, identify fixtures and merchandising spaces, etc.', 
  'Run our video camera configuration tool to give Site Bionics secure access to your video camera feeds.  At this time we will grab still images from your cameras and use AI tools to determine the precise camera locations and poses using your site scan from step 2.',
  'Go to the Site Bionics Dashboard and navigate to the Site Co-Pilot.  The Site Co-Pilot will give you intelligent and actionable insights into your daily opperations.', 
];
const images = ['HowToSiteEditor.png', 'site-bionics.jpg', 'HowToSiteEditor.png', 'site-bionics.jpg', 'HowToSiteCopilot.png'];

interface MediaCardProps  {
  stepIndex: number,
}

const MediaCard: React.FC<MediaCardProps> = (props: MediaCardProps) => {

  return (
    <Card sx={{ maxWidth: 700 }}>
      <CardMedia
        sx={{ height: 350 }}
        image={images[props.stepIndex]}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {steps[props.stepIndex]}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {descriptions[props.stepIndex]}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}

export default function HowItWorks() {
  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container>
    <Box component="div" sx={{ width: '100%' }}>
        <Stack>
          <br/><br/>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          How to make your site Bionic...
          </Typography>
          <br/><br/>

            <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                    optional?: React.ReactNode;
                } = {};
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                  <br/>
                  <Typography sx={{ mt: 2, mb: 1 }} variant="h3">
                      All steps completed.  Your site is now  Bionic!
                  </Typography>
                  <br/>
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box component="div" sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                  <br/><br/>
                  <Grid container direction="column" alignItems="center">
                      <MediaCard stepIndex={activeStep} />
                  </Grid>
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Back
                      </Button>
                      <Box component="div" sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                  </Box>
                </React.Fragment>
            )}
        </Stack>
    </Box>
    </Container>
    
    
  );
}