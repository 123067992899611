export class RoleInfo {
  name: string;
  friendlyName: string;
  description: string;
  capabilities: string[];

  constructor() {
    this.name = "";
    this.friendlyName = "";
    this.description = "";
    this.capabilities = [];
  }
}

export class RoleMap {
  accountRoles: { [key: string]: RoleInfo };
  siteRoles: { [key: string]: RoleInfo };

  constructor(accountRoles: {[key: string]: RoleInfo}, siteRoles: {[key: string]: RoleInfo}) {
    this.accountRoles = accountRoles;
    this.siteRoles = siteRoles;
  }

  getAccountFriendlyRoleNames(roles: string[]): string {
    return roles.map((role: string) => {
      return this.accountRoles[role] ? this.accountRoles[role].friendlyName : null;
    }).filter(name => name).join(", ")
  }

  getSiteFriendlyRoleNames(roles: string[]): string {
    return roles.map((role: string) => {
      return this.siteRoles[role] ? this.siteRoles[role].friendlyName : null;
    }).filter(name => name).join(", ")
  }



}

