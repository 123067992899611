import { useState, useRef, useEffect, FunctionComponent } from "react";
import { Box } from '@mui/material';
import React from "react";
import { encodeColor } from "../util/Colors";
import { Color3, Vector2, Vector3 } from "@babylonjs/core";
import { containerPointToImagePoint, imagePointToContainerPoint } from "../util/MathUtil";
import SiteNavigator from "../models/SiteNavigator";
import { observer } from "mobx-react";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";
import CameraEntity from "../models/layout/CameraEntity";

const SnapshotViewer : FunctionComponent<{siteNavigator: SiteNavigator, layoutViewModel: ScanAreaLayoutViewModel, onPointSelected?: (point: Vector3) => void}> = observer(({siteNavigator, layoutViewModel, onPointSelected}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const [mouseImagePoint, setMouseImagePoint] = useState(new Vector2(0, 0));
    const [snapshotUrl, setSnapShotUrl] = useState<string | undefined>(undefined);
    const [currentCameraEntity, setCurrentCameraEntity] = useState<CameraEntity | undefined>(undefined);

    // this effect is called whenever the current camera changes
    // and sets the current camera entity and the snapshot url
    useEffect(() => {
        setCurrentCameraEntity(layoutViewModel.scanAreaLayout?.cameras.find(c => c.cameraId === siteNavigator.currentCamera?.cameraId));
        siteNavigator.currentCamera?.getSnapShotSasUrlAsync().then((url: string) => {
            setSnapShotUrl(url);
        });
    }, [siteNavigator.currentCamera, layoutViewModel.scanAreaLayout]);

    // this effect is called whenever the mouse point changes or the camera changes
    // and renders the mouse point and the alignment points on the canvas
    useEffect(() => {
        if (canvasRef.current && imageRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                const { width: canvasWidth, height: canvasHeight } = imageRef.current.getBoundingClientRect();
                canvas.width = canvasWidth; canvas.height = canvasHeight;
                const imageSize = new Vector2(imageRef.current.naturalWidth, imageRef.current.naturalHeight);
                const containerSize = new Vector2(canvasWidth, canvasHeight);
                
                ctx.beginPath();
                ctx.strokeStyle = `rgb(255,255,255)`;
                // ctx.moveTo((canvasWidth/2)-10, canvasHeight/2);
                // ctx.lineTo((canvasWidth/2)+10, canvasHeight/2);
                ctx.moveTo(0, canvasHeight/2);
                ctx.lineTo(canvasWidth, canvasHeight/2);
                ctx.stroke();

                ctx.beginPath();
                ctx.strokeStyle = `rgb(255,255,255)`;
                // ctx.moveTo(canvasWidth/2, (canvasHeight/2)-10);
                // ctx.lineTo(canvasWidth/2, (canvasHeight/2)+10);
                ctx.moveTo(canvasWidth/2, 0);
                ctx.lineTo(canvasWidth/2, canvasHeight);
                ctx.stroke();

                // draw the distored and undistorted mouse points
                drawImagePoint(mouseImagePoint, containerSize, imageSize, ctx);
                
                // draw the distored and undistorted mouse points
                currentCameraEntity?.alignmentPoints.forEach((point) => {
                    drawImagePoint(point.asVector2, containerSize, imageSize, ctx);
                });
            }
        }

    }, [mouseImagePoint, currentCameraEntity, layoutViewModel.scanAreaLayout, currentCameraEntity?.fieldOfView, currentCameraEntity?.distortionK]);

    function drawImagePoint(imagePoint: Vector2, containerSize: Vector2, imageSize: Vector2, ctx: CanvasRenderingContext2D) {
        const containerPosition = imagePointToContainerPoint(imagePoint, containerSize, imageSize);
        ctx.beginPath();
        ctx.strokeStyle = encodeColor(Color3.Green());
        ctx.ellipse(containerPosition.x, containerPosition.y, 5, 5, 0, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();

        if (currentCameraEntity) {
            const imagePointUndistorted = currentCameraEntity.undistortPoint(imagePoint);
            const containerPositionUndistorted = imagePointToContainerPoint(imagePointUndistorted, containerSize, imageSize);
            ctx.beginPath();
            ctx.strokeStyle = encodeColor(Color3.Red());
            ctx.ellipse(containerPositionUndistorted.x, containerPositionUndistorted.y, 5, 5, 0, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(containerPosition.x, containerPosition.y);
            ctx.lineTo(containerPositionUndistorted.x, containerPositionUndistorted.y);
            ctx.stroke();
        }
    }

    const handleMouseClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        handleMouseEvent(event.clientX, event.clientY, true);
    };
    
      const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        handleMouseEvent(event.clientX, event.clientY, false);
    };
    
    const handleMouseEvent = (clientX: number, clientY: number, click: boolean) => {
        
        if (layoutViewModel.scanAreaLayout && currentCameraEntity && imageRef.current) {
  
            // the mouse pointer is in the container coordinate system and the image is padded to fit the container
            // so we need to do the work to convert the mouse pointer to the image coordinate system
            const clientRect = imageRef.current.getBoundingClientRect();
            const containerPoint = new Vector2(clientX - clientRect.left, clientY - clientRect.top);
            const containerSize = new Vector2(clientRect.width, clientRect.height);
            const imageSize = new Vector2(imageRef.current.naturalWidth, imageRef.current.naturalHeight);
            const imagePoint = containerPointToImagePoint(containerPoint, containerSize, imageSize);
            setMouseImagePoint(imagePoint);


            // we project both the mouse point and the undistorted mouse point to the ground plane
            let position = currentCameraEntity.projectPointToGroundPlane(imagePoint, layoutViewModel.scanAreaLayout.groundFloorHeight);
            siteNavigator.setCurrentPosition(position);
            let imagePointUndistorted = currentCameraEntity.undistortPoint(imagePoint);
            let positionUndistorted = currentCameraEntity.projectPointToGroundPlane(imagePointUndistorted, layoutViewModel.scanAreaLayout.groundFloorHeight);
            siteNavigator.setCurrentPositionUndistorted(positionUndistorted);

            
            if (click) {
                // the user clicked in the image so we add an alignment point
                currentCameraEntity.addAlignmentPoint(imagePoint)
            }
        }
    };

   return (
        <Box component="div" sx={{ width: '100%', height: '100%', position: 'relative' }} onMouseMove={handleMouseMove} onClick={handleMouseClick}>
            <img ref={imageRef} src={snapshotUrl} alt=""
                 style={{backgroundColor: "black", width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center'}} />
            <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', zIndex: 10 }} />
        </Box>
    )
})

export default SnapshotViewer;