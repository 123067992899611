import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"

import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSiteNavigator, useSitePageBreadcrumbs } from "./SitePage";
import OccupancyChart from "../charts/OccupancyChart";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TrackedObjectEvent from "../models/TrackedObjectEvent";
import StaffingChart from "../charts/StaffingChart";
import AddCardIcon from '@mui/icons-material/AddCard';
import AddchartIcon from '@mui/icons-material/Addchart';
import DeleteIcon from '@mui/icons-material/Delete';
import { action, makeObservable, observable } from "mobx";
import { GUID } from "@babylonjs/core";

enum ChartType { DailyOccupancy, WeeklyStaffing}

class ChartConfig {
  @observable date: Dayjs;
  @observable chartType: ChartType;
  @observable chartId: string;
  
  constructor (date: Dayjs, chartType: ChartType) { 
    this.date = date;
    this.chartType = chartType;
    this.chartId = GUID.RandomId();
    makeObservable(this);
  }

  
  @action
  setDate(date: Dayjs) {
      this.date = date;
  }

  @action
  setChartType(chartType: ChartType) {
      this.chartType = chartType;
  }
};


const ChartContainer: React.FC<{chartConfig: ChartConfig, showRemoveButton: boolean,  onAddChart: ()=>void, onRemoveChart: (chartConfig: ChartConfig)=>void}> = observer(({chartConfig, showRemoveButton, onAddChart, onRemoveChart}) => {

  
  const handleChartTypeChange =  (value: ChartType) => {
    chartConfig.setChartType(value);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" display="flex"  flexDirection="column">

        <Box component="div" sx={{ marginLeft: '10px',  display: 'flex', flexDirection: 'row', marginTop: '10px', marginBottom: '10px'}}>
        <FormControl>
          <InputLabel id="chart-type-label">Chart Type</InputLabel>
            <Select value={chartConfig.chartType} onChange={(e) => handleChartTypeChange(e.target.value as ChartType)} label="Chart Type">
                <MenuItem value={ChartType.DailyOccupancy}>Occupancy</MenuItem>
                <MenuItem value={ChartType.WeeklyStaffing}>Staffing</MenuItem>
            </Select>
        </FormControl>

          <DatePicker 
            label="Date"
            value={chartConfig.date}
            onChange={(newValue) => {
              chartConfig.setDate(newValue!);
            }}
          />

          <Button id="play" onClick={() => { onAddChart() }} size="small"><AddchartIcon/></Button>
          {showRemoveButton &&
            <Button id="play" onClick={() => { onRemoveChart(chartConfig) }} size="small"><DeleteIcon/></Button>
        }
        </Box>

        <Box component="div" >
        {chartConfig.chartType === ChartType.DailyOccupancy &&
        
            <>todo: Occupancy</>
          
        }

        {chartConfig.chartType === ChartType.WeeklyStaffing &&
          <StaffingChart date={chartConfig.date}/>
        }
        </Box>
      </Box>
    </LocalizationProvider>
  )
});

const Dashboard : FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Dashboard");
  const [chartConfigs, setChartConfigs] = useState<ChartConfig[]>([]);  
  
  const [trackedObjectsEvents, setTrackedObjectEvents] = useState<TrackedObjectEvent[]>([]);
  
  useEffect(() => {
    setChartConfigs( [new ChartConfig(dayjs().startOf('week'), ChartType.WeeklyStaffing)] );  
  }, []);

  function addChart() {
    const newChartConfigs = [...chartConfigs, new ChartConfig(dayjs().startOf('week'), ChartType.WeeklyStaffing)];
    setChartConfigs(newChartConfigs);
  }

  function removeChart(chartConfig: ChartConfig) {
    chartConfigs.splice(chartConfigs.indexOf(chartConfig), 1);
    setChartConfigs([...chartConfigs]);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="div" overflow="clip" display="flex" height="100%" flexDirection="column">

        <Box component="div" display="flex" width="100%" flexDirection="row" >
          <BreadcrumbBar breadcrumbs={breadcrumbs} />
          <Box component="div" sx={{ flexGrow: 1 }} />
        </Box>

        <Box component="div"  sx={{ flexGrow: 1, overflowY: 'auto'}}>
        {chartConfigs.map((chartConfig, index) => (
          <ChartContainer key={`chart-${chartConfig.chartId}`} chartConfig={chartConfig} showRemoveButton={chartConfigs.length > 1} onAddChart={addChart} onRemoveChart={removeChart}/>
        ))}
        </Box>

      </Box>
      </LocalizationProvider>
  )
})

export default Dashboard;