import { action, makeObservable, observable } from "mobx";

export class Invite {

    @observable id: string;
    @observable inviteId: string;
    @observable sentToEmail: string;  
    @observable requiresMatch: boolean;
    @observable sentDate: Date;
    @observable expDate: Date;
    @observable senderEmail: string;
    @observable senderUserId: string;  
    @observable accepted: boolean;
    @observable acceptedDate: Date;
    @observable acceptedEmail: string;
    @observable acceptedUserId: string;
    
    constructor(data: any = {}) {        
        this.inviteId = data.id;
        this.id = data.id;
        this.sentToEmail = data.sentToEmail || "";
        this.requiresMatch = data.requiresMatch;
        this.sentDate = new Date(data.sentDate) || new Date(-8640000000000000);
        this.expDate = new Date(data.expDate) || new Date(-8640000000000000);
        this.senderEmail = data.senderEmail || "";
        this.senderUserId = data.send || "";
        this.accepted = data.accepted;
        this.acceptedDate = data.acceptedDate || new Date(-8640000000000000);
        this.acceptedEmail = data.acceptedEmail || "";
        this.acceptedUserId = data.acceptedUserId || "";   
    }
}

export class AccountInvite extends Invite {
    @observable accountRoles: string[];
    @observable baseSiteRoles: string[];
    @observable accountId: string;
    @observable accountName: string;

    constructor(data: any = {}) {
        
        super(data);

        this.accountId = data.accountId;
        this.accountName = data.accountName;
        this.accountRoles = data.accountRoles.slice();
        this.baseSiteRoles = data.baseSiteRoles.slice();

    }

    @action
    updateData(accountRoles: string[], baseSiteRoles: string[], requiresMatch: boolean) {
        this.accountRoles = accountRoles.slice();
        this.baseSiteRoles = baseSiteRoles.slice();
        this.requiresMatch = requiresMatch;
    }
}

export class SiteInvite extends Invite {
    @observable siteRoles: string[];
    @observable accountId: string;
    @observable siteId: string;
    @observable accountName: string;
    @observable siteName: string;


    constructor(data: any = {}) {        
        super(data);

        this.accountId = data.accountId;
        this.siteId = data.siteId;
        this.siteRoles = data.siteRoles.slice();        
        this.accountName = data.accountName;
        this.siteName = data.siteName;
    }

    @action
    updateData(siteRoles: string[], requiresMatch: boolean) {
        this.siteRoles = siteRoles.slice();
        this.requiresMatch = requiresMatch;
    }
}