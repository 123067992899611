import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Tooltip, Box, Button, Checkbox, Backdrop, CircularProgress, Link, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CameraDialog from "../dialogs/CameraDialog";
import { useSite } from "./SitePage";
import Camera, { CameraType } from "../models/Camera";
import Confirm from "../components/Confirm";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { Color3 } from "@babylonjs/core";
import TitleToolbar from "../components/TitleToolbar";
import { HubStatusCamera, HubStatusResponse } from "../models/HubStatusResponse";
import StatusLED from "../components/StatusLED";
import Site from "../models/Site";
import CachedHubControl from "./CachedHubControl";

interface CamerasProps {

  hubStatuses: Record<string, HubStatusResponse | null> | null;
}

const Cameras : React.FC<CamerasProps> = observer(( {hubStatuses}) => {
  
  const site = useSite();

  const siteBionicsApplcation = useSiteBionicsApplication(); 
  const siteNavigator = siteBionicsApplcation.siteNavigator!;
  
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {

    if (!site.cameras) {
      setLoading(true);
      site.loadCamerasAsync().then(() => {
        setLoading(false);
      })

      return;
    }

    if (!siteNavigator.currentCamera && site.cameras && site.cameras.length > 0) {
      siteNavigator.setCurrentCamera(site.cameras[0]);
    }
  }, [site, site.cameras]);

  // For the view menu  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openViewMenu = Boolean(anchorEl);

  const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseViewMenu = () => {
    setAnchorEl(null);
  };

  // Dialogs

  const [cameraEditDraft, setCameraEditDraft] = useState<undefined | Camera>(undefined);
  const [editCameraDialogOpen, setEditCameraDialogOpen] = useState(false);
  const [createCameraDialogOpen, setCreateCameraDialogOpen] = useState(false);
  const [deleteCameraDialogOpen, setDeleteCameraDialogOpen] = useState(false);
  const [dialogKey, setDialogKey] = useState(0);

  const handleEditClick = () => {
    setCameraEditDraft(siteNavigator.currentCamera!.clone());
    setDialogKey(dialogKey + 1);
    setEditCameraDialogOpen(true);
  };
    
  const handleCreateClick = () => {
    const camera = new Camera(siteBionicsApplcation.service.dataContext, site, "", "", CameraType.ONVIF,
      "", "", "", "", "", "", 0, 0, true, "", 91.25, 2960, 1668, [-0.1675, 0.0, 0.0, 0.0, 0.0], Color3.Magenta());
    setCameraEditDraft(camera);
    setDialogKey(dialogKey + 1);
    setCreateCameraDialogOpen(true);    
  };  
  
  const handleSaveEditClick = (camera: Camera) => {
    setBusy(true);
    if (createCameraDialogOpen) {
      siteBionicsApplcation.service.createCameraAsync(site, camera!).then((newCamera: Camera) => {
        newCamera.cameraId = camera.cameraId;
        site.cameras!.push(newCamera);
        setCameraEditDraft(undefined);
        setCreateCameraDialogOpen(false);
        setBusy(false);
      });
      
    } else {
      setBusy(true);      
      siteBionicsApplcation.service.updateCameraAsync(camera).then(() => {
        siteNavigator.currentCamera!.updateFrom(camera);
        setCameraEditDraft(undefined);
        setEditCameraDialogOpen(false);
        setBusy(false);
      });
    }
  };

  const handleDelete = () => {
    setBusy(true);
    siteBionicsApplcation.service.deleteCameraAsync(siteNavigator.currentCamera!).then(() => {

      // Close the dialog
      setDeleteCameraDialogOpen(false);

      // Find the camera in the list
      let index = site.cameras!.findIndex(obj => siteNavigator.currentCamera!.cameraId === obj.cameraId);

      // Remove it
      if (index !== -1) {
        site.cameras!.splice(index!, 1);
      }

      // Set the current camera to null
      siteNavigator.setCurrentCamera(undefined);
    
      setBusy(false);
    });
    
  };

  const getCameraHealth = (cameraId: string): [string, number, number] => {
    if (hubStatuses === null) return ["grey", 0, 0];
  
    // Loop through the hubs    
    for (const key of Object.keys(hubStatuses)) {
  
      const hubStatus = hubStatuses[key];
  
      // Loop through the cameras
      for (const camera of hubStatus?.status.cameras ?? []) {
        if (camera.cameraId === cameraId) {
          if (camera.videoFPS < 10) return ["red", camera.videoFPS, camera.inferenceFPS];
          return ["green", camera.videoFPS, camera.inferenceFPS];
        }
      }
    }
    
    return ["red", 0, 0];
  };

  return (
    
    <>
      
      <TitleToolbar title="Cameras" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
        <>
          <Button variant="outlined" size="small" onClick={handleCreateClick}>Add...</Button>
          <Button variant="outlined" size="small" disabled={!siteNavigator.currentCamera} onClick={handleEditClick}>Edit...</Button>
          <Button variant="outlined" size="small" disabled={!siteNavigator.currentCamera} onClick={() => setDeleteCameraDialogOpen(true)}>Delete...</Button>            
          <Button variant="outlined" size="small" disabled={!siteNavigator.currentCamera} onClick={handleViewClick}>View...</Button>
          <Menu anchorEl={anchorEl} open={openViewMenu} onClose={handleCloseViewMenu}>
            <MenuItem component={Link} href="/camera/video" onClick={handleCloseViewMenu}>Video</MenuItem>
            <MenuItem component={Link} href="/camera/calibration" onClick={handleCloseViewMenu}>Calibration</MenuItem>
          </Menu>            
        </>
      </TitleToolbar>
      
      <Box component="div" sx={{ height: '10pt' }}/>      
      
      {loading && <Typography>Loading...</Typography>}
      
      {site.cameras && site.cameras.length === 0 && 
        <Typography>No available cameras</Typography>
      }

      {site.cameras && site.cameras.length > 0 &&
            
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Camera Name</TableCell>               
                <TableCell align="left">Health</TableCell>
                <TableCell align="left">Video / Inference FPS</TableCell>
                <TableCell align="left">Type</TableCell>                
                <TableCell align="left">Hub</TableCell>
                <TableCell align="left">Manufacturer / Model</TableCell>
                
              </TableRow>
            </TableHead>
            
            <TableBody>
              {site.cameras?.map((c) => (
                <TableRow key={c.cameraId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={c.cameraId === siteNavigator.currentCamera?.cameraId}
                      onClick={ () =>{siteNavigator.setCurrentCamera(c)}}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title={c.cameraId}>
                      <span>{c.cameraName ? c.cameraName : "[no name]"}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left"><StatusLED color={getCameraHealth(c.cameraId)[0]}/></TableCell>
                  <TableCell align="left">
                      {getCameraHealth(c.cameraId)[1].toFixed(1)} / {getCameraHealth(c.cameraId)[2].toFixed(1)}
                  </TableCell>
                  <TableCell align="left">{CameraType[c.cameraType]}</TableCell>
                  
                  <TableCell align="left">
                    <CachedHubControl site={site} hubId={c.hubId}/>                    
                  </TableCell>

                  <TableCell align="left">
                    {c.serialNumber ?
                      <Tooltip title={c.serialNumber}>
                        <span>{c.manufacturer ?? ""} / {c.model ?? "" }</span>
                      </Tooltip>
                    :
                      <span>{c.manufacturer ?? ""} / {c.model ?? "" }</span>
                    }
                    </TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      { deleteCameraDialogOpen && siteNavigator.currentCamera && (      
        <Confirm
          confirmationText={"Are you sure you want to delete the camera '" + siteNavigator.currentCamera!.cameraName + "'?"}
          confirmButtonLabel="Delete"
          onClose={() => setDeleteCameraDialogOpen(false)}
          onConfirm={handleDelete}
        />             
      )}            
      
      {cameraEditDraft && (editCameraDialogOpen || createCameraDialogOpen) && (
        <CameraDialog
          key={dialogKey}
          camera={cameraEditDraft}
          site={site}
          title={editCameraDialogOpen ? "Edit Camera" : "Create Camera"}
          saveButtonLabel={editCameraDialogOpen ? "Save" : "Create"}
          onCancel={() => {
            setCameraEditDraft(undefined);
            setEditCameraDialogOpen(false);
            setCreateCameraDialogOpen(false);
          }}
          onSave={handleSaveEditClick}
        />        
        
      )}

    </>
  )
})

export default Cameras;