//import { makeAutoObservable, runInAction } from 'mobx';
import Account from "./Account";
import Site from "./Site";
import Hub from "./Hub";
import Camera from "./Camera";

class Cache<T extends { id: string }> {
  private cache = new Map<string, T | Promise<T>>();
  private listCache = new Map<string, T[] | Promise<T[]>>();

  //constructor() {
  //  makeAutoObservable(this, {}, { autoBind: true });
  //}

  async getObject(id: string, fetchFunction: () => Promise<T | null>): Promise<T | null> {

    // If the object is in the cache, return it
    if (this.cache.has(id)) {
      const cachedObject = this.cache.get(id);
      return cachedObject as T;
    }

    // We need to fetch the object
    // @ts-ignore
    const fetchPromise: Promise<T> = fetchFunction().then((objectData: T | null) => {

      if (objectData === null) return null;

      // We have the new object (objectData)
        
      if (this.cache.has(id) && !(this.cache.get(id) instanceof Promise)) {
        return this.cache.get(id);
      } else {
        this.cache.set(id, objectData);
        return objectData;
      }                
    });

    // Store the promise in the cache
    this.cache.set(id, fetchPromise);

    // return the promise
    return fetchPromise;
  }

  async getList(listKey: string, fetchFunction: () => Promise<T[]>): Promise<T[]> {

    // If we already have the list in our cache, return it
    if (this.listCache.has(listKey)) {
      return this.listCache.get(listKey) as T[] | Promise<T[]>;
    }

    // Create the promise
    //@ts-ignore
    const fetchPromise: Promise<T[]> = fetchFunction().then((listData: T[]) => {

      // We have the newly fetched list of objects in "listData"

      // Process each item in the list into a new list; using either the fetched or cached copy
      const objects = listData.map((objectData) => {

        // If the item in the list is in the cache and isn't a promise, use it
        if (this.cache.has(objectData.id) && !(this.cache.get(objectData.id) instanceof Promise)) {
          return this.cache.get(objectData.id);
        } else {  
          // Insert the newly fetchec data into the cache and use it
          this.cache.set(objectData.id, objectData);

          return objectData;
        }
      });

      if (this.listCache.has(listKey) && !(this.listCache.get(listKey) instanceof Promise)) {
        return this.listCache.get(listKey);
      } else {
        this.listCache.set(listKey, objects as T[]);
        return objects;
      }                
    });
    
    // Store the promise in the cache
    this.listCache.set(listKey, fetchPromise);

    // Return the promise for the object
    return fetchPromise;
  }

  removeList(listKey: string) {
    this.listCache.delete(listKey);  
  }

  removeItem(key: string) {
    this.cache.delete(key);
  }

  addItem(item: T) {
    this.cache.set(item.id, item);
  }

  addList(listKey: string, list: T[]) {
    this.listCache.set(listKey, list);
  }

  resetCache() {
    this.cache.clear();
    this.listCache.clear();
  } 
}

export const accountCache = new Cache<Account>();
export const siteCache = new Cache<Site>();
export const hubCache = new Cache<Hub>();
export const cameraCache = new Cache<Camera>();
