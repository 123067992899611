

export function dotNetTicksToDate(ticks: string | number): Date {
    // Define the .NET epoch start date
    const netEpochStart = new Date(Date.UTC(1, 0, 1, 0, 0, 0, 0));
    // Calculate the ticks from the .NET epoch to the JavaScript epoch (1970-01-01)
    const jsEpochTicks = 621355968000000000; // This is the .NET tick count for the JavaScript epoch

    // If ticks is provided as a string, convert it to a number
    const tickCount = typeof ticks === 'string' ? BigInt(ticks) : BigInt(ticks);
    
    // Calculate milliseconds for JavaScript Date
    // Subtract .NET epoch ticks to get ticks since 1970, then convert from ticks to milliseconds (1 tick = 10000 microseconds = 10000 / 1000 milliseconds)
    const milliseconds = Number(tickCount - BigInt(jsEpochTicks)) / 10000;

    // Create the JavaScript Date object
    return new Date(milliseconds);
}

export function  parseTimeSpan(timeSpan: string): number {
    const parts = timeSpan.split(':');
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;
    const secondsParts = parts[2].split('.');
    const seconds = parseInt(secondsParts[0], 10) || 0;
    const milliseconds = parseInt(secondsParts[1], 10) || 0;
  
    return (hours * 3600 + minutes * 60 + seconds);
  };