import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"

import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { useSiteBionicsApplication } from "../../models/SiteBionicsApplication";
import BreadcrumbBar from "../../components/BreadcrumbBar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Color3 } from "@babylonjs/core";
import { encodeColor } from "../../util/Colors";
import { useSiteNavigator, useSitePageBreadcrumbs } from "../SitePage";
import { FragmentUploadPriority } from "../../models/FragmentUploadPriority";
import TitleToolbar from "../../components/TitleToolbar";

const ManageVideo: FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const [busy, setBusy] = useState(false);
  const [availableVideo, setAvailableVideo] = useState<any>(undefined);
  const [series, setSeries] = useState<ApexAxisChartSeries>();
  const [_dataEntries, setDataEntries] = useState<any>();
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Manage Video");

  const handleBarClick = (_event: React.MouseEvent, _chartContext: any, _config: any) => {
    
    // if (series && siteBionicsApplcation.tracklets && onTrackletClicked && onTrackedObjectClicked)
    // {
    //     const barIndex: number = config.dataPointIndex;
    //     const data  = dataEntries[barIndex];
    //     const tag = data.tag;
    //     if (tag instanceof Tracklet)
    //         onTrackletClicked(tag);
    //     else if (tag instanceof TrackedObject)
    //         onTrackedObjectClicked(tag);
    // }
  };

  async function runQuery(): Promise<void> {
    setBusy(true);
    await siteBionicsApplcation.service.fetchAvailableVideo(
        siteNavigator.site.account.accountId, siteNavigator.site.siteId,
        siteBionicsApplcation.startTime!, siteBionicsApplcation.endTime!).then((availableVideo) => {
          setAvailableVideo(availableVideo);
      });
    setBusy(false);
  }

  async function requestUpload(): Promise<void> {
    setBusy(true);
    await Promise.all([
      siteBionicsApplcation.service.postSiteUploadVideoRequest(
        siteNavigator.site.account.accountId, siteNavigator.site.siteId,
        FragmentUploadPriority.SystemHigh, siteBionicsApplcation.startTime!, siteBionicsApplcation.endTime!, 0),
      siteBionicsApplcation.service.postSiteUploadVideoRequest(
        siteNavigator.site.account.accountId, siteNavigator.site.siteId,
          FragmentUploadPriority.SystemHigh, siteBionicsApplcation.startTime!, siteBionicsApplcation.endTime!, 1),
    ]);
    setBusy(false);
  }

  async function cancelSystemUploads(): Promise<void> {
    setBusy(true);
    await siteBionicsApplcation.service.postSiteCancelSystemUploadVideoRequest(siteNavigator.site.account.accountId, siteNavigator.site.siteId);
    setBusy(false);
  }

  function dataEntryForFragment(fragment: any) {
    return {
        x: fragment.cameraId,
        y: [ new Date(fragment.timeRange.startTime).getTime(), new Date(fragment.timeRange.endTime).getTime()],
        fillColor: encodeColor(Color3.Magenta()),
        tag: fragment
    }
  }

  useEffect(() => {

    if (availableVideo !== undefined)
    {
        var newDataEntries : any[] = [];
        for (let cameraId in availableVideo) {
          let fragmentList = availableVideo[cameraId];
          fragmentList.forEach((fragment : any) => {
            newDataEntries.push(dataEntryForFragment(fragment));
          });
        }
        setDataEntries(newDataEntries);

        const newSeries = [
        {
          data: newDataEntries
        }];
        setSeries(newSeries);
    }
    else
    {
        setSeries(undefined);
    }

}, [availableVideo, siteBionicsApplcation.startTime, siteBionicsApplcation.endTime]);

  const options: ApexOptions = {
    chart: { type: 'rangeBar', 
        toolbar: {
            show: true, offsetX: 0, offsetY: 0,
            tools: {download: false, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true},
        },
        events: {
            dataPointSelection: handleBarClick
        }
    },
    plotOptions: { bar: { horizontal: true, barHeight: '90%' } },
    xaxis: { type: 'datetime' }
  }

  return (
    <>
      <TitleToolbar title="Manage Video" sx={{paddingLeft: "10pt", paddingRight: "10pt", marginTop: "10pt"}}>
        <>
          <Button variant="outlined" size="small" onClick={cancelSystemUploads}>Cancel Uploads</Button>
        </>
      </TitleToolbar>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component="div" display="flex" height="100%" flexDirection="column">

          <Box component="div" display="flex" width="100%" flexDirection="row" >
            <BreadcrumbBar breadcrumbs={breadcrumbs} />
            <Box component="div" sx={{ flexGrow: 1 }} />
          </Box>

          <Box component="div" margin={2}>
            <DateTimePicker
              label="Start Time"
              value={siteBionicsApplcation.startTime}
              onChange={(newValue) => { siteBionicsApplcation.setStartTime(newValue!); }}
            />
            <DateTimePicker
              label="End Time"
              value={siteBionicsApplcation.endTime}
              onChange={(newValue) => { siteBionicsApplcation.setEndTime(newValue!); }}
            />
            <Button id="play" onClick={() => { runQuery() }} size="large">
              Refresh  
            </Button>
            <Button id="upload" onClick={() => { requestUpload() }} size="large">
              Upload  
            </Button>
          </Box>
          <>
          {series &&
                  <ReactApexChart height={25 * (siteNavigator.site.cameras?.length ?? 1)} options={options} series={series} type="rangeBar"  />
              }

              {!availableVideo &&
                  <>No available video results. Select a time range and hit 'refresh'.</>
              }
          </>

        </Box>

      </LocalizationProvider>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>
    </>
  )
})

export default ManageVideo;