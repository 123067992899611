import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Box, Slider, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import RegionEntity from "../models/layout/RegionEntity";


const RegionProperties: FunctionComponent<{ region: RegionEntity }> = observer(({ region }) => {


  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Region Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {region.name}

          <form>
            <TextField
              label="Name"
              value={region.name}
              onChange={(e) => {region.setName(e.target.value)}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position X"
              value={region.pose.position.x}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              value={region.pose.position.y}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Z"
              value={region.pose.position.z}
              type="number"
              fullWidth
              margin="normal"
            />
            {/* <TextField
              label="Position X"
              value={camera.position?.x}
              type="number"
              onChange={(e) => {camera.position.setX(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              type="number"
              value={camera.position?.y}
              onChange={(e) => {camera.position.setY(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Z"
              type="number"
              value={camera.position?.z}
              onChange={(e) => {camera.position.setZ(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            /> */}
          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default RegionProperties;