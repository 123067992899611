import { FunctionComponent, useEffect, useState } from "react";
import { Alert, Box, CircularProgress } from '@mui/material';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import TaggedObjectCounts from "../models/TaggedObjectCounts";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useSiteNavigator } from "../pages/SitePage";


interface HeatmapData {
  hour: string;
  days: Array<{ day: string, min: number, avg: number, max: number }>;
}

const StaffingChart: React.FC<{ date: Dayjs}> = observer(({date}) => {
  const [series, setSeries] = useState<any[]>([]);
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const [taggedObjectCounts, setTaggedObjectCounts] = useState<TaggedObjectCounts[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    theme: { mode: 'dark' },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0.0000001,
              color: '#999999'
            },{
            from: 0.0000001,
            to: 5,
            color: '#FF5555'
          },
          {
            from: 5,
            to: 10,
            color: '#FFFF55'
          },
          {
            from: 10,
            to: 300,
            color: '#55FF55'
          }
          ],
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        if (typeof val === 'number') {
          const seriesData = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
          const min = typeof seriesData.goals[0].value === 'number' ? seriesData.goals[0].value.toFixed(1) : 'N/A';
          const max = typeof seriesData.goals[1].value === 'number' ? seriesData.goals[1].value.toFixed(1) : 'N/A';
          return (min === '0.0' && max === '0.0' && val === 0) ? '' :`${min} | ${val.toFixed(1)} | ${max}`; // Displaying min/avg/max
        }
        return val.toString(); // Fallback for non-number types
      },
      style: {
        colors: ['#fff']
      }
    },
    legend: { show: false },
    xaxis: {
      type: 'category',
      position: 'top',
      categories: []  // This will be dynamically set
    },
    tooltip: {
      enabled: true
    }
  });

  useEffect(() => {
    setIsLoading(true);
    siteBionicsApplcation.service.fetchTaggedObjectCountsAsync(siteNavigator.site,
            date, date.add(1, 'week'), "01:00:00", "").then((taggedObjectCounts) => {
            setTaggedObjectCounts(taggedObjectCounts);
            setIsLoading(false);
          });
  }, [date]);

  useEffect(() => {
    const data = taggedObjectCounts.reduce<HeatmapData[]>((acc : HeatmapData[], toc: TaggedObjectCounts) => {
      const hour = dayjs(toc.time).format('H:00');
      const day = dayjs(toc.time).format('ddd M/D');

      let hourData = acc.find(h => h.hour === hour);
      if (!hourData) {
        hourData = { hour, days: [] };
        acc.push(hourData);
      }

      let dayData = hourData.days.find(d => d.day === day);
      if (!dayData) {
        dayData = { day, min: toc.min, avg: toc.avg, max: toc.max };
        hourData.days.push(dayData);
      } else {
        dayData.min = Math.min(dayData.min, toc.min);
        dayData.max = Math.max(dayData.max, toc.max);
        dayData.avg = (dayData.avg + toc.avg) / 2; // Averaging the avg value
      }

      return acc;
    }, []);

    const seriesData = data.map((hourData) => ({
      name: hourData.hour,
      data: hourData.days.map((dayData) => ({
        x: dayData.day,
        y: dayData.avg, // Color based on avg
        goals: [
          {
            name: 'min',
            value: dayData.min,
            strokeWidth: 0,
            strokeColor: '#775DD0'
          },
          {
            name: 'max',
            value: dayData.max,
            strokeWidth: 0,
            strokeColor: '#775DD0'
          }
        ]
      }))
    })).reverse(); // Reverse the order of the hours, but not the days

    setSeries(seriesData);
    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: data[0]?.days.map((day: any) => day.day) || []  // Keep the original x-axis categories
      }
    }));
  }, [date, taggedObjectCounts]);

  return (
    <Box component="div" sx={{ marginLeft: '10px', marginRight: '10px' }}>
      {!isLoading && series.length > 0 &&
        <ReactApexChart options={options} series={series} height={350} type="heatmap" />
      }
      {!isLoading && series.length == 0 &&
        <Alert severity="warning">No data for this date.</Alert>
      }
      {isLoading &&
        <CircularProgress/>
      }
    </Box>
  );
});

export default StaffingChart;
