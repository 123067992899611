import { FunctionComponent, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import { Color3 } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import RegionEntity from "../models/layout/RegionEntity";
import { ActionManager, ExecuteCodeAction, Mesh } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Vector3Model from "../models/layout/Vector3Model";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";

const RegionLayer: FunctionComponent<{region: RegionEntity, layoutViewModel: ScanAreaLayoutViewModel}> = observer(({region, layoutViewModel}) => {
  const transformNodeRef = useRef<Mesh>(null);
  const meshRef = useRef<Mesh>(null);
  const scene = useScene();
  
  useEffect(() => {
    if (scene && meshRef.current) {
        meshRef.current.actionManager = new ActionManager(scene);
        meshRef.current.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, (event: any) => {
                layoutViewModel.selectEntity(region);
            })
        );
    }
  }, [scene, meshRef.current]);

  let isSelected = layoutViewModel.selectedEntity === region;

  return (
    <>
          <transformNode name="transformNode" ref={transformNodeRef} position={new Vector3(-(region.pose.position.x), region.pose.position.y + 1, region.pose.position.z)}>
            {layoutViewModel.selectedEntity === region && 
              <positionGizmo 
                        onDragEndObservable={ (e:any) => {
                          region.pose.setPosition(new Vector3Model({x: -transformNodeRef.current!.position.x, y: transformNodeRef.current!.position.y - 1, z: transformNodeRef.current!.position.z}));
                        }}/>
             }
            <box ref={meshRef} name="box" scaling={new Vector3(region.size.x, 1, region.size.z)} size={1} >
              <standardMaterial name="material" alpha={isSelected ? 0.8 : 0.6} diffuseColor={isSelected ? Color3.White() : Color3.Red()} />
            </box>
          </transformNode>
    </>
  )
})

const RegionsLayer: FunctionComponent<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  return (
    <>
      <utilityLayerRenderer>
      {layoutViewModel.scanAreaLayout?.regions?.map((region, index) => (
        <RegionLayer key={`region-${index}`} region={region} layoutViewModel={layoutViewModel}/>
      ))}
      </utilityLayerRenderer>

    </>
  )
})

export default RegionsLayer;


