import { useState } from "react";
import { observer } from "mobx-react"
import { TermsOfServiceRendered } from "./ToS";
import { Typography, Box, Button, TextField } from "@mui/material";
import {useForm} from 'react-hook-form';

interface InterstitialProps {
    newUser: boolean,
    firstName?: string,
    lastName?: string,
    onAccept: (firstName: string, lastName: string) => void;
  }

  interface FormValues {
    firstName: string, 
    lastName: string
  }
  
const Interstitial : React.FC<InterstitialProps> = observer(({
    newUser,
    firstName = "",
    lastName = "",    
    onAccept
}) => { 
      
  const { register, handleSubmit, formState: { errors, isValid }} = useForm<FormValues> ({
    defaultValues: {
      firstName: firstName,
      lastName: lastName
    },
    mode: "onBlur"
  });
  
    const onSubmit = (data: FormValues) => {
      onAccept(data.firstName, data.lastName);
    };


  return (
    <Box component="div" display="flex" flexDirection="column">

      <Box component="div" sx={{ marginX: '20pt' }}>
        <Box component="div" sx={{height: 20}}/>
        <Typography variant="h5" color="inherit" component="div">
            {newUser ? "Welcome" : "Welcome Back"}
        </Typography>            
        <Box component="div" sx={{height: 20}}/>
        {true ?
          <>
            <Typography>
                Welcome to Site Bionics. In order to get started we need to take care of a few things. Please confirm your first and last name, and accept our Terms of Service.
            </Typography>        
            <TextField 
                id="firstName" 
                label="First Name" 
                margin="dense" 
                type="string"                 
                variant="standard"
                {...register("firstName", {required: 'First name is required'})}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
            />
            <p/>
            <TextField 
                id="lastName" 
                label="Last Name" 
                margin="dense" 
                type="string" 
                variant="standard"
                {...register("lastName", {required: "Last name is required"})}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
            />
          </>
        :
            <Typography>
                Welcome back {firstName}. We've updated our Terms of Service. Please accept them to continue.
            </Typography>        
        }

        <TermsOfServiceRendered/>

        <Button disabled={!isValid} variant="outlined" onClick={handleSubmit(onSubmit)}>Accept</Button>
      </Box>

  </Box>
  )
})

export default Interstitial;