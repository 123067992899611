import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react"

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import 'm-react-splitters/lib/splitters.css';
import TrackedObject from "../models/TrackedObject";
import { encodeColor } from "../util/Colors";
import Tracklet from "../models/Tracklet";
import { Color3 } from "@babylonjs/core";
import { Box, Grid, Typography } from "@mui/material";
import { createPortal } from "react-dom";
import SiteNavigator from "../models/SiteNavigator";

const CustomTooltip: React.FC<{ trackedObject?: TrackedObject }> = ({ trackedObject }) => {
    if (!trackedObject) return null;
  
    // Prepare data for the pie chart
    const chartLabels = Object.keys(trackedObject.timeEngagedWithTags);
    const chartData = Object.values(trackedObject.timeEngagedWithTags);
  
    const totalTime = (trackedObject.endTime.getTime() - trackedObject.startTime.getTime()) / 1000;
    //const engagedTimeSum = Object.values(trackedObject.timeEngagedWithTags).reduce((acc, value) => acc + value, 0);

    // Calculate the time not engaged with any tag
    //const otherTime = totalTime - engagedTimeSum;
  
    chartLabels.push("Total Time");
    chartData.push(totalTime);  
    const options: ApexOptions = {
        chart: { 
            type: 'bar',
            animations: { enabled: false }
        },
        plotOptions: { bar: { horizontal: true, barHeight: '90%' } },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                // Ensure val is treated as a number
                const totalSeconds = Array.isArray(val) ? val[0] : Number(val);
        
                // Convert seconds to hh:mm:ss.0 format
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = Math.floor(totalSeconds % 60);
                const tenths = Math.floor((totalSeconds % 1) * 10); // get tenths of a second
        
                const pad = (num: number) => String(num).padStart(2, '0');
                return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${tenths}`;
              },
            style: {
              colors: ['#fff']
            },
            offsetX: 10,
          },
        xaxis: { categories: chartLabels},
    };

    const chartSeries = [{
        name: 'Time (ms)',
        data: chartData,
      }];
    
      return (
        <Grid container spacing={2} style={{ backgroundColor: 'gray', padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 5px rgba(0,0,0,0.3)' }}>
          {/* Left Panel */}
          <Grid item xs={6}>
            <Typography variant="h5">Person</Typography>
            <Typography variant="body1">Start: {trackedObject.startTime.toLocaleTimeString()} {trackedObject.startsAtEntrance ? " @ " + trackedObject.startsAtEntrance : ""}</Typography>
            <Typography variant="body1">End: {trackedObject.endTime.toLocaleTimeString()} {trackedObject.endsAtEntrance ? " @ " + trackedObject.endsAtEntrance : ""}</Typography>
            <Typography variant="body2">({trackedObject?.trackedObjectId})</Typography>
          </Grid>
          {/* Right Panel */}
          <Grid item xs={6}>
            <ReactApexChart options={options} series={chartSeries} type="bar" width="100%" />
          </Grid>
        </Grid>
      );
    };
    
  

interface TrackedObjectsTimelineProps {
    onTrackedObjectClicked?: (trackedObject: TrackedObject) => void;
    onTrackletClicked?: (tracklet: Tracklet) => void;
    trackedObjects: TrackedObject[];
    tracklets: Tracklet[];
    siteNavigator: SiteNavigator;
    showTracklets: boolean;
  }

const TrackedObjectsTimeline: FC<TrackedObjectsTimelineProps> = observer(({
   onTrackedObjectClicked, onTrackletClicked, trackedObjects, tracklets, siteNavigator, showTracklets
  }) => {
    const siteBionicsApplcation = useSiteBionicsApplication();
    const [series, setSeries] = useState<ApexAxisChartSeries>();
    const [dataEntries, setDataEntries] = useState<any>();
    const [tooltipData, setTooltipData] = useState<any>(null);
    const [tooltipTrackedObject, setTooltipTrackedObject] = useState<TrackedObject | undefined>(undefined);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    
    
    const handleBarClick = (event: React.MouseEvent, chartContext: any, config: any) => {
    
        if (series && tracklets && onTrackletClicked && onTrackedObjectClicked)
        {
            const barIndex: number = config.dataPointIndex;
            const data  = dataEntries[barIndex];
            const {type, id }= data.tag;
            if (type === "Tracklet") {
                let tracklet = tracklets.find(t => t.trackletId === id)
                if (tracklet) onTrackletClicked(tracklet);
            }
            else if (type === "TrackedObject") {
                let trackedObject = trackedObjects.find(t => t.trackedObjectId === id);
                if (trackedObject) onTrackedObjectClicked(trackedObject);
            }
        }
      };

    function dataEntryForTrackedOject(trackedObject: TrackedObject, _index: number) {
        return {
            x: trackedObject.trackedObjectId,
            y: [
                Math.max(trackedObject.startTime.getTime(), siteBionicsApplcation.startTime!.toDate().getTime()),
                Math.min(trackedObject.endTime.getTime(), siteBionicsApplcation.endTime!.toDate().getTime()),
            ],
            fillColor: encodeColor(Color3.White()),
            tag: { type: "TrackedObject", id: trackedObject.trackedObjectId },
            goals: [
                {
                  name: 'Entrance',
                  value: trackedObject.startTime.getTime(),
                  strokeWidth: 2,
                  strokeHeight: 8,
                  strokeColor: '#00FF00'
                },
                {
                    name: 'Exit',
                    value: trackedObject.endTime.getTime(),
                    strokeWidth: 2,
                    strokeHeight: 8,
                    strokeColor: '#FF0000'
                  }
              ]
        }
    }

    function dataEntryForTracklet(tracklet: Tracklet) {
        return {
            x: tracklet.trackedObject!.trackedObjectId + "_" + tracklet.camera!.cameraId,
            y: [
                Math.max(tracklet.startTime.getTime(), siteBionicsApplcation.startTime!.toDate().getTime()),
                Math.min(tracklet.endTime.getTime(), siteBionicsApplcation.endTime!.toDate().getTime()),
            ],
            fillColor: encodeColor(tracklet.camera?.color ?? Color3.Magenta()),
            tag: { type: "Tracklet", id: tracklet.trackletId }
        }
    }

    function dataEntryForFusedTracklet(tracklet: Tracklet) {
        return {
            x: tracklet.trackedObject!.trackedObjectId + "_" + tracklet.trackletId,
            y: [
                Math.max(tracklet.startTime.getTime(), siteBionicsApplcation.startTime!.toDate().getTime()),
                Math.min(tracklet.endTime.getTime(), siteBionicsApplcation.endTime!.toDate().getTime()),
            ],
            fillColor: encodeColor(Color3.Teal()),
            tag: { type: "FusedTracklet", id: tracklet.trackletId }
        }
    }

    useEffect(() => {

        if (trackedObjects != null && trackedObjects.length > 0)
        {
            console.time("AddTrackedObjectsToTimeline");
            var newDataEntries : any[] = [];
            
            newDataEntries.push({
                x: "Query Range", 
                y: [siteBionicsApplcation.startTime!.toDate().getTime(), siteBionicsApplcation.endTime!.toDate().getTime()], 
                fillColor: encodeColor(Color3.Teal()),
                tag: { type: "QueryRange", id: 0 }
            });

            trackedObjects.forEach( (trackedObject, idx) => {
                newDataEntries.push(dataEntryForTrackedOject(trackedObject, idx));
                
                // if (trackedObject.fusedTracklet)
                //     newDataEntries.push(dataEntryForFusedTracklet(trackedObject.fusedTracklet));
                // newDataEntries.push(dataEntryForTrackedOject(trackedObject, idx));
                
                if (showTracklets) {
                    trackedObject.tracklets.forEach(tracklet =>{
                        // skip tracklets that are outside the query range
                        if (tracklet.endTime.getTime() < siteBionicsApplcation.startTime!.toDate().getTime() 
                            || tracklet.startTime.getTime() > siteBionicsApplcation.endTime!.toDate().getTime()) 
                        return;
                        newDataEntries.push(dataEntryForTracklet(tracklet));
                    });
                }
            });
            setDataEntries(newDataEntries);

            const trackletSeries = [
            {
                data: newDataEntries
            }];
            setSeries(trackletSeries);
            console.timeEnd("AddTrackedObjectsToTimeline");
        }
        else
        {
            setSeries(undefined);
        }

    }, [trackedObjects, tracklets, siteNavigator.currentTrackedObject, showTracklets]);

    const options: ApexOptions = {
        chart: { type: 'rangeBar', 
            toolbar: {
                show: true, offsetX: 0, offsetY: 0,
                tools: {download: false, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true},
            },
            events: {
                dataPointSelection: handleBarClick,
                mouseLeave: () => {
                    setIsTooltipVisible(false);
                },
                dataPointMouseLeave: () => {
                    setIsTooltipVisible(false);
                },
            }
        },
        plotOptions: { bar: { horizontal: true, barHeight: '90%' } },
        xaxis: { type: 'datetime' },
        yaxis: { labels: { show: false }},
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              if (dataPointIndex === -1) {
                setIsTooltipVisible(false);
                return '';
              }
      
              const dataPoint = {
                label: w.globals.labels[dataPointIndex],
                value: series[seriesIndex][dataPointIndex],
              };
      
              // Get the tooltip position from ApexCharts
              const tooltipEl = w.globals.dom.baseEl.querySelector('.apexcharts-tooltip');
              if (tooltipEl) {
                const { top, left } = tooltipEl.getBoundingClientRect();
                setTooltipPosition({ top, left });
              }
      
              let trackedObject = trackedObjects.find((t: TrackedObject) => t.trackedObjectId === dataPoint.label);
              setTooltipTrackedObject(trackedObject);
              setTooltipData(dataPoint);
              setIsTooltipVisible(true);
      
              return ''; // Return an empty string to override the default tooltip
            },
          },
       
    };


    return (
        <Box component="div" display="flex" flexGrow="1" flexDirection={"column"} overflow="auto" width={"100%"}>
            {series &&
                <ReactApexChart height={dataEntries.length * 15} options={options} series={series} type="rangeBar"  />
            }

            {!series &&
                <>No tracked objects. Select a time range and hit 'refresh'.</>
            }

            {isTooltipVisible &&
                createPortal(
                <div
                    style={{
                    position: 'fixed',
                    top: tooltipPosition.top,
                    left: tooltipPosition.left,
                    pointerEvents: 'none', // Prevent the tooltip from interfering with mouse events
                    transform: 'translate(-50%, -100%)', // Adjust tooltip position to be above the cursor
                    zIndex: 1000,
                    }}
                >
                    <CustomTooltip trackedObject={tooltipTrackedObject}/>
                </div>,
                document.body
            )}
        </Box>
    )
})

export default TrackedObjectsTimeline;