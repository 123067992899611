import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

interface StatusLEDProps {
  color: string;
}

const StatusLED: React.FC<StatusLEDProps> = ({ color }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  
  return (
    <Box
        component="div"
        sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: color,
            display: 'inline-block',
        }}
    />
  );
};

export default StatusLED;
