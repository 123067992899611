import { observer } from "mobx-react";
import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import GeneralCard from './GeneralCard';
import { People } from '@mui/icons-material';
import { Box  } from '@mui/material';
import Account from '../models/Account';
import Site from '../models/Site';

interface SiteUsersCardProps {
    account: Account;
    site: Site;
}

const SiteUsersCard : React.FC<SiteUsersCardProps> = observer(({ account, site }) => {
    
    const [accessCount, setAccessCount] = useState(0);
    const [inviteCount, setInviteCount] = useState(0);

    useEffect(() => {

        site.loadUserSiteRolesAsync();

        account.loadUserAccountRolesAsync();
    
        site.loadSiteInvitesAsync();
        
        account.loadAccountInvites();

    }, []);

    useEffect(() => {        
    
      if (account.userAccountRoles && site.userSiteRoles) { 
        let count = site.userSiteRoles.length + account.userAccountRoles.reduce((acc, userSiteRole) => acc + userSiteRole.baseSiteRoles.length, 0);
        setAccessCount(count);
      }

      if (account.accountInvites && site.siteInvites) {
        let count = site.siteInvites.filter(item => item.accepted === false).length;
        count = count + account.accountInvites.filter(item => item.accepted === false).reduce((acc, accountInvite) => acc + accountInvite.baseSiteRoles.length, 0);        
        setInviteCount(count);
      }
      
    }, [account.userAccountRoles, site.userSiteRoles, account.accountInvites, site.siteInvites]);


    return (

        <GeneralCard title="Team Members"
            icon={<People style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
            moreLink={`/accounts/${account.accountId}/sites/${site.siteId}/users`}
        >
            <Box component="div" display="flex" flexDirection="column" sx={{gap: "10px"}}>
                    
                <Typography>Number of members: {site.userSiteRoles && account.userAccountRoles ? accessCount : "Loading..."}</Typography>                  
                                
                <Typography>Number of invited: {site.siteInvites && account.accountInvites ? inviteCount : "Loading..."}</Typography>

            </Box>
        </GeneralCard>    
    );
})

export default SiteUsersCard;