import React from 'react';
import { Box, Typography } from '@mui/material';


export const TermsOfServiceData = {
  version: 1.03,
  date: "8/13/2024",
  terms: [
    { header: "Terms of Service", subheader: "Effective Date: 8/13/2024" },
    { section: "1. Acceptance of Terms", content: "By accessing or using our website/application (the \"Service\"), you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our Service." },
    { section: "2. Changes to Terms", content: "We reserve the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting to the Service. Your continued use of the Service constitutes acceptance of the modified terms." },
    { section: "3. Use of Service", content: "You agree to use the Service only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of the Service does not violate any applicable laws or regulations." },
    { section: "4. Data Collection and Use", content: "Our Service tracks data related to people in your physical location. We collect, store, and use this data to provide and improve our Service. By using our Service, you consent to the collection and use of your data." },
    { section: "5. Account Responsibilities", content: "If you create an account on our Service, you are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account." },
    { section: "6. Termination", content: "We may terminate or suspend your access to the Service at our sole discretion, without prior notice, for any reason, including if you breach these Terms of Service." },
    { section: "7. Disclaimer of Warranties", content: "The Service is provided on an \"as is\" and \"as available\" basis. We make no warranties, express or implied, regarding the availability, reliability, or accuracy of the Service." },
    { section: "8. Limitation of Liability", content: "To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, or consequential damages arising from or related to your use of the Service." },
    { section: "9. Governing Law", content: "These Terms of Service are governed by and construed in accordance with the laws of Delaware, without regard to its conflict of law principles." },
    { section: "10. Contact Us", content: "If you have any questions about these Terms of Service, please contact us." }
  ]
};

export const TermsOfServiceRendered = () => (
  <Box component="div" p={2}>
    <Typography variant="h4" gutterBottom>
      Terms of Service (v{TermsOfServiceData.version})
    </Typography>
    <Typography variant="subtitle1" gutterBottom>
      Effective Date: {TermsOfServiceData.date}
    </Typography>
    {TermsOfServiceData.terms.map((item, index) => (
      <Box component="div" key={index} mb={2}>
        {item.section && (
          <Typography variant="h6" gutterBottom>
            {item.section}
          </Typography>
        )}
        {item.content && (
          <Typography variant="body1">
            {item.content}
          </Typography>
        )}
      </Box>
    ))}
  </Box>
);
