import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Box, Card, CardMedia, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CameraIndoorOutlinedIcon from '@mui/icons-material/CameraIndoorOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useSelectionManager } from "../util/SelectionManager";
import { PreAuthUrl } from "../util/PreAuthUrl";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import Camera from "../models/Camera";
import CameraEntity from "../models/layout/CameraEntity";
import QuaternionModel from "../models/layout/QuaternionModel";


const CameraProperties: FunctionComponent<{ camera: CameraEntity }> = observer(({ camera }) => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const selectionManager = useSelectionManager();
  const [cameraImagePreAuthUrl] = useState(new PreAuthUrl());

//   useEffect(() => {
//     cameraImagePreAuthUrl.setUrlPromise(
//       siteBionicsService.fetchSiteCameraImageSasUri(
//         siteBionicsApplcation.currentAccount!.accountId,
//         siteBionicsApplcation.currentSite!.siteId,
//         camera.name));
//      }, [selectionManager.selection, camera.name]);


  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <CameraIndoorOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Camera Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {camera.camera?.cameraName ?? "Unnamed Camera"}

          <form>
            <TextField
              label="Name"
              value={camera.camera?.cameraName ?? "Unnamed Camera"}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Field of View"
              type="number"
              value={camera.fieldOfView}
              inputProps={{step: 0.25, min: 45, max: 180}}
              onChange={(e) => {camera.setFieldOfView(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Distortion (K)"
              type="number"
              value={camera.distortionK}
              inputProps={{step: 0.0025, min: -2, max: +2}}
              onChange={(e) => {camera.setDistortionK(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position X" margin="normal"
              inputProps={{step: 0.025, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.position.x}
              onChange={(e) => {camera.pose.position.setX(parseFloat(e.target.value))}}
            />
            <TextField
              label="Position Y" margin="normal"
              inputProps={{step: 0.025, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.position.y}
              onChange={(e) => {camera.pose.position.setY(parseFloat(e.target.value))}}
            />
            <TextField
              label="Position Z" margin="normal"
              inputProps={{step: 0.025, min: -100, max: 100}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.position.z}
              onChange={(e) => {camera.pose.position.setZ(parseFloat(e.target.value))}}
            />
              <TextField
              label="Rotation X" margin="normal"
              inputProps={{step: 0.25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.orientation.asEulerAngles.x}
              onChange={(e) => {
                let eulerAngles = camera.pose.orientation.asEulerAngles;
                eulerAngles.set(parseFloat(e.target.value), eulerAngles.y, eulerAngles.z);
                camera.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
              }}
            />
            <TextField
              label="Rotation Y" margin="normal"
              inputProps={{step: 0.25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.orientation.asEulerAngles.y}
              onChange={(e) => {
                let eulerAngles = camera.pose.orientation.asEulerAngles;
                eulerAngles.set(eulerAngles.x, parseFloat(e.target.value), eulerAngles.z);
                camera.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
              }}
            />
            <TextField
              label="Rotation Z" margin="normal"
              inputProps={{step: 0.25, min: -360, max: 360}} type="number" variant="outlined" InputLabelProps={{shrink: true,}} fullWidth
              value={camera.pose.orientation.asEulerAngles.z}
              onChange={(e) => {
                let eulerAngles = camera.pose.orientation.asEulerAngles;
                eulerAngles.set(eulerAngles.x, eulerAngles.y, parseFloat(e.target.value));
                camera.pose.setOrientation(QuaternionModel.fromEulerAngles(eulerAngles));
              }}
            />
          </form>
        </AccordionDetails>
      </Accordion>
      {/* <Card>
        <CardMedia
          component="img"
          alt="Camera Image"
          image={cameraImagePreAuthUrl.url}
        />
      </Card> */}
    </div>

  )
})

export default CameraProperties;