
import { action, makeObservable, observable } from "mobx";

export class PreAuthUrl {
  @observable url?: string;
  
  private urlPromise? : Promise<string>;
  
  constructor() {
    makeObservable(this);
  }

  setUrlPromise(urlPromise: Promise<string>) {
    this.urlPromise = urlPromise;
    this.urlPromise.then((s) => {this.setUrl(s);} )
  }

  @action
  setUrl(url: string | undefined) {
    this.url = url;
  }
}

