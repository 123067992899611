import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { observer } from "mobx-react-lite";
import Address from "../models/Address";
import { useForm} from "react-hook-form";

interface NameAddressDialogProps {

  title: string;

  name?: string;

  address?: Address;

  saveLabel?: string;

  nameLabel?: string;

  addressLabel?: string;

  onClose: () => void;

  onSave: (name: string, address: Address) => void;
}

interface FormValues {
  name: string;

  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

const NameAddressDialog: React.FC<NameAddressDialogProps> = ({
    title,
    name = "",
    address = new Address("", "", "", "", "", ""),
    saveLabel = "Save",
    nameLabel = "Name",
    addressLabel = "Address",
    onClose,
    onSave
 }) => {
  
  const {register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      name: name,
      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      country: address.country
    },
    mode: "onBlur"
  });
      
  // When the cancel button is clicked
  const handleCancelEditClick = () => {
    onClose();
  }

  // Prevents clicks on the backdrop from cancelation
  const handleCancelLocal = (event: React.SyntheticEvent<Element, Event>, reason: string) => {
    
    if (reason === "backdropClick") return;

    onClose();

  }

  const handleSaveEditClick = (data:FormValues ) => {        
    const address = new Address(data.street1, data.street2, data.country, data.city, data.state, data.postalCode);
      onSave(data.name, address);    
  };
    
  const stateAbbreviations = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", 
    "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", 
    "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];

  return (
    <>    
      <Dialog open={true} onClose={handleCancelLocal} maxWidth="lg" >
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSaveEditClick)}>
        <DialogContent>
          
          <TextField 
            id="name" 
            label={nameLabel} 
            margin="dense" 
            type="string" 
            variant="standard"
            required
            fullWidth 
            {...register("name", { required: nameLabel + " is required"})}
            error={!!(touchedFields["name"] && errors["name"])}
            helperText={touchedFields["name"] && errors.name?.message}                        
          />

          <TextField 
            id="street1" 
            label={addressLabel + " Street 1"}
            margin="dense" 
            type="string" 
            fullWidth 
            variant="standard"            
            required
            {...register("street1", { required: addressLabel + " Street 1 is required"})}
            error={!!(touchedFields["street1"] && errors["street1"])}
            helperText={touchedFields["street1"] && errors.street1?.message}               
          />

          <TextField 
            id="street2" 
            label={addressLabel + " Street 2"}
            margin="dense" 
            type="string" 
            fullWidth 
            variant="standard"
            {...register("street2")}            
          />

          <TextField 
            id="city" 
            label={addressLabel + " City" }
            margin="dense" 
            type="string" 
            fullWidth            
            required
            variant="standard"            
            {...register("city", { required: addressLabel + " City is required"})}
            error={!!(touchedFields["city"] && errors["city"])}
            helperText={touchedFields["city"] && errors.city?.message}               
          />

          <TextField 
            id="state" 
            label={addressLabel + " State" }
            margin="dense" 
            type="string" 
            fullWidth 
            required
            variant="standard"          
            {...register("state", { required: addressLabel + " State is required"})}

            {...register("state", {
              required: addressLabel + " State is required",
              validate: value => stateAbbreviations.includes(value.toUpperCase()) || "Invalid state abbreviation"
            })}

            error={!!(touchedFields["state"] && errors["state"])}
            helperText={touchedFields["state"] && errors.state?.message}               
          />

          <TextField 
            id="postalCode" 
            label={addressLabel + " Postal Code"}
            margin="dense" 
            type="string" 
            fullWidth 
            required
            variant="standard"   
            {...register("postalCode", {
              required: addressLabel + " Postal Code is required",
              pattern: {
                value: /^\d{5}(-\d{4})?$/, // Regex for 5-digit or 9-digit (ZIP+4) zip code
                message: "Postal code must be 5 digit or 5-4 digit"
              }
            })}
            error={!!(touchedFields["postalCode"] && errors["postalCode"])}
            helperText={touchedFields["postalCode"] && errors.postalCode?.message}                      
          />

          <TextField 
              id="country" 
              label={addressLabel + " Country"}
              margin="dense" 
              type="string" 
              fullWidth 
              required
              variant="standard"            
              {...register("country", { required: addressLabel + " Country is required"})}
              error={!!(touchedFields["country"] && errors["country"])}
              helperText={touchedFields["country"] && errors.country?.message}               
          />
            
        </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleCancelEditClick} color="secondary">Cancel</Button>
        <Button variant="outlined" disabled={!isValid} onClick={handleSubmit(handleSaveEditClick)} color="primary">{saveLabel}</Button>
      </DialogActions>
      </form>
    </Dialog>      

</>
  )
};

export default observer(NameAddressDialog);