import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useSiteBionicsApplication } from "./SiteBionicsApplication";
import UserInfo from "./UserInfo";

interface CachedUsersContextType {
  userCache: { [key: string]: UserInfo };
  fetchUserInfoFromCache: (userId: string) => Promise<UserInfo | null>;
}

const CachedUsersContext = createContext<CachedUsersContextType | undefined>(undefined);

interface CachedUsersProviderProps {
  children: ReactNode;
}

export const CachedUsersProvider: React.FC<CachedUsersProviderProps> = ({ children }) => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const [userCache, setUserCache] = useState<{ [key: string]: UserInfo }>({});

  const fetchUserInfoFromCache = async (userId: string): Promise<UserInfo | null> => {
    if (userCache[userId]) {
      return userCache[userId];
    }

    // Get the user info
    const userInfo = await siteBionicsApplication.service.fetchUserInfo(userId);

    if (userInfo === null)
      return null;

    setUserCache((prevCache) => ({
      ...prevCache,
      [userId]: userInfo,
    }));

    return userInfo;
  };

  return (
    <CachedUsersContext.Provider value={{ userCache, fetchUserInfoFromCache }}>
      {children}
    </CachedUsersContext.Provider>
  );
};

export const useUserCache = (): CachedUsersContextType => {
  const context = useContext(CachedUsersContext);
  if (!context) {
    throw new Error('useUserCache must be used within a UserCacheProvider');
  }
  return context;
};
