import { ExtendedModel, idProp, model, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import PoseModel from "./PoseModel";


@model("SiteBionics/TriggerEntity")
export default class TriggerEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    pose: prop<PoseModel>(PoseModel.identity).withSetter(),
    radius: prop<number>(1).withSetter(),
}) {


}

