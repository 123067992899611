import { Vector3 } from '@babylonjs/core';
import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';


@model("SiteBionics/Vector3Model")
class Vector3Model extends Model({
    x: prop<number>(0).withSetter(),
    y: prop<number>(0).withSetter(),
    z: prop<number>(0).withSetter()
  },
  {
    valueType: true
  }
) {

  @computed
  get asLHVector3() : Vector3 {
    return new Vector3(-this.x, this.y, this.z);
  }

  @computed
  get asVector3() : Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }

  static zero(): Vector3Model {
    return new Vector3Model({ x: 0, y: 0, z: 0 });
  }

  static identity(): Vector3Model {
    return new Vector3Model({ x: 1, y: 1, z: 1 });
  }

  static fromVector3(value: Vector3): Vector3Model {
    return new Vector3Model({ x: value.x, y: value.y, z: value.z });
  }
}

export default Vector3Model;