import { FunctionComponent, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import { Color3 } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import EntranceEntity from "../models/layout/EntranceEntity";
import { ActionManager, ExecuteCodeAction, Mesh } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import Vector3Model from "../models/layout/Vector3Model";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";

const EntranceLayer: FunctionComponent<{entrance: EntranceEntity, layoutViewModel: ScanAreaLayoutViewModel}> = observer(({entrance, layoutViewModel}) => {
  const transformNodeRef = useRef<Mesh>(null);
  const meshRef = useRef<Mesh>(null);
  const scene = useScene();

  useEffect(() => {
    if (scene && meshRef.current) {
        meshRef.current.actionManager = new ActionManager(scene);
        meshRef.current.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, (event: any) => {
                layoutViewModel.selectEntity(entrance);
            })
        );
    }
  }, [scene, meshRef.current]);

  let isSelected = layoutViewModel.selectedEntity === entrance;

  return (
    <>
          <transformNode name="transformNode" ref={transformNodeRef} position={new Vector3(-(entrance.pose.position.x), entrance.pose.position.y, entrance.pose.position.z)}>
            {layoutViewModel.selectedEntity === entrance && 
              <positionGizmo
                        onDragEndObservable={ (e:any) => {
                          entrance.pose.setPosition(new Vector3Model({x: -transformNodeRef.current!.position.x, y: transformNodeRef.current!.position.y, z: transformNodeRef.current!.position.z}));
                        }}/>
             }
            <cylinder ref={meshRef} name="cylinder" scaling={new Vector3(entrance.radius, 1, entrance.radius)} diameter={2} height={1} updatable={true} >
              <standardMaterial name="material" alpha={isSelected ? 0.8 : 0.6} diffuseColor={isSelected ? Color3.White() : Color3.Blue()} />
            </cylinder>
          </transformNode>
    </>
  )
})

const EntrancesLayer: FunctionComponent<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
  return (
    <>
      <utilityLayerRenderer>
      {layoutViewModel.scanAreaLayout.entrances.map((entrance, index) => (
        <EntranceLayer key={`entrance-${index}`} entrance={entrance} layoutViewModel={layoutViewModel} />
      ))}
      </utilityLayerRenderer>

    </>
  )
})

export default EntrancesLayer;


