import { Draft, draft, UndoManager, undoMiddleware } from "mobx-keystone";
import ScanAreaLayout from "./ScanAreaLayout";
import ScanAreaLayoutViewModel from "./ScanAreaLayoutViewModel";

export default class ScanAreaLayoutEditor {
    private originalScanAreaLayout: ScanAreaLayout;
    private draft: Draft<ScanAreaLayout>;
    public undoManager: UndoManager;
    public layoutViewModel: ScanAreaLayoutViewModel;

    constructor(scanAreaLayout: ScanAreaLayout) {
        this.originalScanAreaLayout = scanAreaLayout;
        this.draft = draft(scanAreaLayout);
        this.layoutViewModel = new ScanAreaLayoutViewModel({scanAreaLayout: this.draft.data});
        this.undoManager = undoMiddleware(this.layoutViewModel, this.layoutViewModel.undoData);
    }

    commit() {
        this.draft.commit();
    }

    reset() {
        this.draft.reset();
    }  
    
    get isDirty() { return this.draft.isDirty; }
}