import { useState, useContext, createContext, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Outlet, useLoaderData, useOutletContext, useParams } from "react-router-dom";
import SiteNavigator from "../models/SiteNavigator";
import Site from "../models/Site";
import { useAccount } from "./AccountPage";
import NoAccess from "../components/NoAccess";
import { SiteBionicsApplication, useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { siteCache } from '../models/Cache';
import { Box, Typography} from '@mui/material';

// Leftover from when we were using loaders and context
export function useSiteNavigator(): SiteNavigator {  

  return SiteBionicsApplication.getInstance().siteNavigator!;
}

// Context for the site
const SiteDataContext = createContext<Site | null | undefined>(undefined);

// Return the site context
export const useSite = (): Site => { 
  const context = useContext(SiteDataContext);
  if (context === undefined || context === null) {
    throw new Error("Cannot access site when null or undefined");    
  }
  return context;
};

export function useSitePageBreadcrumbs(pageName: string) { 

  const site = useSite();
  const account = site.account;

  let breadcrumbs: {name: string, link: string}[] = [];
  if (pageName === "") {
    breadcrumbs = [
      {name: "Accounts", link: "/accounts"},
      {name: `${account.accountName}`, link: `/accounts/${account.accountId}`}, 
      {name: "Sites", link: `/accounts/${account.accountId}/sites`}, 
      {name: `${site.siteName}`, link: ""}
    ];
  } else {
    breadcrumbs = [
      {name: "Accounts", link: "/accounts"},
      {name: `${account.accountName}`, link: `/accounts/${account.accountId}`}, 
      {name: "Sites", link: `/accounts/${account.accountId}/sites`}, 
      {name: `${site.siteName}`, link: `/accounts/${account.accountId}/sites/${site.siteId}`}, 
      {name: pageName, link: ""},    
    ];
  }
  return breadcrumbs;
}


const SitePage : FunctionComponent = observer(() => {
  
  const siteBionicsApplcation = useSiteBionicsApplication();
  const account = useAccount();
  const {accountId, siteId} = useParams<{accountId: string, siteId: string}>();
  const [site, setSite] = useState<Site | null | undefined>(undefined);

  useEffect(() => {
    if (accountId === undefined || siteId === undefined) return;
    
    siteCache.getObject(siteId, () => siteBionicsApplcation.service.fetchSiteAsync(account, siteId)).then((site: Site | null) => {
      setSite(site);
      if (site) {
        siteBionicsApplcation.setCurrentSite(site);
      }
    });    
      
  }, [])


  useEffect(() => {
    
    if (!siteBionicsApplcation.mruData) {
      siteBionicsApplcation.loadMRUDataAsync();
      return;
    }

    if (accountId === undefined || siteId === undefined || site === undefined) {
      return;
    } else if (site === null) {
      siteBionicsApplcation.mruData.removeSite(accountId, siteId);
    } else {
      siteBionicsApplcation.mruData.addSite(accountId, siteId);
    }
    
  }, [accountId, siteId, site, siteBionicsApplcation, siteBionicsApplcation.mruData]);
  
  return (
    <SiteDataContext.Provider value={site}>
      
      {site &&
        <Outlet/>
      }
      {site === null  &&
        <NoAccess account={account} title="Invalid Site" message="Site is either invalid or not accessible."/>
      }
      {(site === undefined) &&
      
        <Box component="div" display="flex" flexDirection="column" sx={{padding: '20px'}}> 
          <Typography>Loading...</Typography>
        </Box>
      }
    </SiteDataContext.Provider>
  )
})

export default SitePage;