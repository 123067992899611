import { Matrix, Quaternion, Vector2, Vector3 } from "@babylonjs/core/Maths";

export function projectPointToGroundPlane(
  imagePoint: Vector2,
  aspectRatio: number,
  cameraDiagFovDeg: number,
  cameraPosition: Vector3,
  cameraOrientation: Quaternion,
  yGround: number
): Vector3 {
  // Calculate the diagonal field of view in radians
  const fovDiagonalRad = Math.PI * cameraDiagFovDeg / 180.0;

  // Calculate the vertical field of view based on the diagonal field of view
  const fovVerticalRad = 2.0 * Math.atan(Math.tan(fovDiagonalRad / 2) / Math.sqrt(1 + aspectRatio * aspectRatio));

  const fovHorizontalRad = 2.0 * Math.atan(Math.tan(fovDiagonalRad / 2) * aspectRatio / Math.sqrt(1 + aspectRatio * aspectRatio));

  // Calculate the normalized device coordinates (NDC) of the point
  const ndcX = 2.0 * imagePoint.x - 1.0;
  const ndcY = -2.0 * imagePoint.y + 1.0;

  // Calculate the direction vector from camera to the point in camera space
  const directionCameraSpaceX = Math.tan(fovHorizontalRad / 2) * ndcX;
  const directionCameraSpaceY = Math.tan(fovVerticalRad / 2) * ndcY;
  const directionCameraSpace = new Vector3(directionCameraSpaceX, directionCameraSpaceY, -1);

  // Convert camera orientation quaternion to rotation matrix
  const rotationMatrix = new Matrix();
  cameraOrientation.toRotationMatrix(rotationMatrix);

  // Rotate the direction vector to world space
  const directionWorldSpace = Vector3.TransformCoordinates(directionCameraSpace, rotationMatrix);

  // Calculate the distance from camera to ground plane
  const distanceToGroundPlane = (cameraPosition.y - yGround) / directionWorldSpace.y;

  // Calculate the intersection point with the ground plane
  const intersectionPoint = cameraPosition.subtract(directionWorldSpace.scale(distanceToGroundPlane));

  return intersectionPoint;
}

// export function calculateCameraMatrix(fovDiagonalDeg: number, width: number, height: number): cv.Mat {
//     // Convert FOV from degrees to radians
//     const fovDiagonalRad = (fovDiagonalDeg * Math.PI) / 180;
  
//     // Calculate the diagonal length of the image
//     const imageDiagonal = Math.sqrt(width ** 2 + height ** 2);
  
//     // Calculate the focal length using the diagonal FOV
//     const focalLength = imageDiagonal / (2 * Math.tan(fovDiagonalRad / 2));
  
//     // Principal point is centered
//     const cx = width / 2;
//     const cy = height / 2;
  
//     // Assuming square pixels, fx = fy
//     const fx = focalLength;
//     const fy = focalLength;
  
//     // Construct the camera matrix
//     const cameraMatrixData = [
//       [fx, 0, cx],
//       [0, fy, cy],
//       [0, 0, 1],
//     ];
  
//     const cameraMatrix = cv.matFromArray(3, 3, cv.CV_64F, cameraMatrixData.flat());
  
//     return cameraMatrix;
//   }
  

  export function undistortPointBrownConrady(
    point: Vector2, width: number, height: number, diagonalFovDegrees: number,
    k1: number, k2: number, k3: number, p1: number, p2: number): Vector2 {
    
    const diagonalFovRadians = diagonalFovDegrees * (Math.PI / 180);
    const imageDiagonal = Math.sqrt(width * width + height * height);
    const f = imageDiagonal / (2 * Math.tan(diagonalFovRadians / 2));

    const fx = f;
    const fy = f;

    const cx = width / 2;
    const cy = height / 2;

    // Initial guess: the point itself
    let x = (point.x - cx) / fx;
    let y = (point.y - cy) / fy;

    // Iterative refinement to undistort
    const maxIterations = 3;
    for (let i = 0; i < maxIterations; i++) {
        let r2 = x * x + y * y;
        let radialDistortion = 1 + k1 * r2 + k2 * r2 * r2 + k3 * r2 * r2 * r2;

        let deltaX = 2 * p1 * x * y + p2 * (r2 + 2 * x * x);
        let deltaY = p1 * (r2 + 2 * y * y) + 2 * p2 * x * y;

        let xDistorted = x * radialDistortion + deltaX;
        let yDistorted = y * radialDistortion + deltaY;

        x = x - (xDistorted - x);
        y = y - (yDistorted - y);
    }

    return new Vector2(x * fx + cx, y * fy + cy);
}

export function undistortPointFisheye4(
  point: Vector2, width: number, height: number, diagonalFovDegrees: number,
  k1: number, k2: number, k3: number, k4: number): Vector2 {
  
  const diagonalFovRadians = diagonalFovDegrees * (Math.PI / 180);
  const imageDiagonal = Math.sqrt(width * width + height * height);
  const f = imageDiagonal / (2 * Math.tan(diagonalFovRadians / 2));

  const fx = f;
  const fy = f;

  const cx = width / 2;
  const cy = height / 2;

  // Initial guess: the point itself
  let x = (point.x - cx) / fx;
  let y = (point.y - cy) / fy;

  // Iterative refinement to undistort
  const maxIterations = 3;
  for (let i = 0; i < maxIterations; i++) {
      let r2 = x * x + y * y;
      let radialDistortion = 1 + k1 * r2 + k2 * r2 * r2 + k3 * r2 * r2 * r2 + k4 * r2 * r2 * r2 * r2;

      let xDistorted = x * radialDistortion;
      let yDistorted = y * radialDistortion;

      x = x - (xDistorted - x);
      y = y - (yDistorted - y);
  }

  return new Vector2(x * fx + cx, y * fy + cy);
}

export function imagePointToContainerPoint(
  imagePoint: Vector2,
  containerSize: Vector2,
  imageSize: Vector2
) {
  // Calculate the aspect ratios
  const containerAspectRatio = containerSize.x / containerSize.y;
  const imageAspectRatio = imageSize.x / imageSize.y;

  if (containerAspectRatio > imageAspectRatio) {
    // Container is wider than its image aspect ratio
    const scale = containerSize.y / imageSize.y;
    const totalWidth = imageSize.x * scale;
    const paddingX = (containerSize.x - totalWidth) / 2;
    return new Vector2(imagePoint.x * scale + paddingX, imagePoint.y * scale);
  } else {
    // Container is narrower than its image aspect ratio
    const scale = containerSize.x / imageSize.x;
    const totalHeight = imageSize.y * scale;
    const paddingY = (containerSize.y - totalHeight) / 2;
    return new Vector2(imagePoint.x * scale, imagePoint.y * scale + paddingY);
  }
}

export function containerPointToImagePoint(
  containerPoint: Vector2,
  containerSize: Vector2,
  imageSize: Vector2
) {
  // Calculate the aspect ratios
  const containerAspectRatio = containerSize.x / containerSize.y;
  const imageAspectRatio = imageSize.x / imageSize.y;

  if (containerAspectRatio > imageAspectRatio) {
    // container is wider than its image aspect ratio
    const scale = containerSize.y / imageSize.y;
    const totalWidth = imageSize.x * scale;
    const paddingX = (containerSize.x - totalWidth) / 2;
    return new Vector2((containerPoint.x - paddingX) / scale, containerPoint.y / scale);
  } else {
    // container is narrower than its image aspect ratio
    const scale = containerSize.x / imageSize.x;
    const totalHeight = imageSize.y * scale;
    const paddingY = (containerSize.y - totalHeight) / 2;
    return new Vector2(containerPoint.x / scale, (containerPoint.y - paddingY) / scale);
  }
}