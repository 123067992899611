import { observer } from "mobx-react";
import { FunctionComponent, useEffect, useState } from "react";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { Tooltip, Backdrop, CircularProgress, Tabs, Tab, Checkbox, Toolbar, Typography, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSitePageBreadcrumbs } from "./SitePage";
import CachedUserControl from "./CachedUserControl";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import UserSiteRole from "../models/UserSiteRole";
import {SiteInvite} from "../models/Invite";
import RoleDialog from "../dialogs/RoleDialog";
import Confirm from "../components/Confirm";
import {useSite} from "./SitePage";
import NoAccess from "../components/NoAccess";
import TitleToolbar from "../components/TitleToolbar";

const SiteUsers: FunctionComponent = observer(() => {

  const site = useSite();
  const siteBionicsApplication = useSiteBionicsApplication();

  const [selectedUserSiteRole, setSelectedUserSiteRole] = useState<UserSiteRole | undefined>(undefined);
  const [selectedSiteInvite, setSelectedSiteInvite] = useState<SiteInvite | undefined>(undefined);

  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);

  const [busy, setBusy] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedUserSiteRole(undefined);
    setSelectedSiteInvite(undefined);
    setTabValue(newValue);
  };

  const breadcrumbs = useSitePageBreadcrumbs("Users");
  
  useEffect(() => {

    if (!site.hasSiteRole("SiteAdmin")) return;
    
    site.loadUserSiteRolesAsync();

    site.loadSiteInvitesAsync();

    site.account.loadUserAccountRolesAsync();

    site.account.loadAccountInvites();

    site.account.loadRoleMapAsync(); 
  });  

  const handleAdd = (userId: string, requiresMatch: boolean, accountRoles: string[], siteRoles: string[]) => {

    setBusy(true);
    if (userId.indexOf("@") !== -1) {
      siteBionicsApplication.service.createSiteInvitation(site.account.accountId, site.siteId, siteRoles, userId, requiresMatch).then(() => {
        site.loadSiteInvitesAsync(true);
        setAdding(false);
        setBusy(false);
      });
    } else {
      let newUSR = new UserSiteRole(site.account.accountId, site.siteId, userId, siteRoles);
      siteBionicsApplication.service.upsertUserSiteRoles(newUSR.accountId, newUSR.siteId, newUSR).then(() => {

        site.userSiteRoles!.push(newUSR);

        setAdding(false);
        setBusy(false);
      });    
    }
  } 

  const handleEdit = (userId: string, requiresMatch: boolean, accountRoles: string[], siteRoles: string[]) => {
    setBusy(true);
    if (selectedUserSiteRole) {
      let newUSR = new UserSiteRole(site.account.accountId, site.siteId, selectedUserSiteRole!.userId, siteRoles);
      siteBionicsApplication.service.upsertUserSiteRoles(newUSR.accountId, newUSR.siteId, newUSR).then(() => {

        selectedUserSiteRole?.updateFrom(newUSR);      

        setEditing(false);
        setBusy(false);
      });    
    } else {
      siteBionicsApplication.service.updateSiteInvitation(site.account.accountId, site.siteId, selectedSiteInvite!.id, siteRoles, requiresMatch, true).then(() => {

        selectedSiteInvite!.updateData(siteRoles, requiresMatch);

        setEditing(false);
        setBusy(false);
      });
      
    }
  } 


  const handleDelete = () => {
    setBusy(true);
    if (selectedUserSiteRole) {
      siteBionicsApplication.service.deleteUserSiteRoles(site.account.accountId, site.siteId, selectedUserSiteRole!.userId).then(() => {
        const index = site.userSiteRoles!.findIndex(obj => obj === selectedUserSiteRole);
        if (index !== -1) {
          site.userSiteRoles!.splice(index, 1)
        }
    
        setDeleting(false);
        setBusy(false);
      });
    } else {
      siteBionicsApplication.service.deleteSiteInvitation(site.account.accountId, site.siteId, selectedSiteInvite!.id).then(() => {

        const index = site.siteInvites!.findIndex(obj => obj === selectedSiteInvite);
        if (index !== -1) {
          site.siteInvites!.splice(index, 1)
        }

        setSelectedSiteInvite(undefined);
    
        setDeleting(false);
        setBusy(false);
      });
    }
  };

  if (!site.hasSiteRole("SiteAdmin")) {
    return (
      <NoAccess account={site.account} site={site}/>
    )
  }
  
  return (
    <>
    
      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleToolbar title="Site Users" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}>
        <>
          <Button variant="outlined" size="small" onClick={() => setAdding(true)} disabled={tabValue !== 1}>Invite...</Button>            
          <Button variant="outlined" size="small" onClick={() => setEditing(true)} disabled={!(selectedUserSiteRole || (selectedSiteInvite && selectedSiteInvite.acceptedEmail === ""))}>Edit...</Button>            
          <Button variant="outlined" size="small" onClick={() => setDeleting(true)} disabled={!selectedUserSiteRole && !selectedSiteInvite}>Delete...</Button>            
        </>
      </TitleToolbar>
      
      <Box component="div" sx={{ height: "10pt" }} />

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="users invites" sx={{paddingX: "10pt"}}>
        <Tab label="Users" />
        <Tab label="Invites" />
      </Tabs>          
        
      {(!site.userSiteRoles || !site.siteInvites || 
        !site.account.userAccountRoles || !site.account.accountInvites ||
        !site.account.roleMap) ?
        <Typography>Loading...</Typography>
      : 
        <>                                  
          {tabValue === 0 &&
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell component="th" scope="row">Site Role</TableCell>
                    <TableCell component="th" scope="row">All Sites</TableCell>
                  </TableRow>
                </TableHead>      
                                
                <TableBody>                                  

                  {site?.userSiteRoles?.map((r) => (
                    <TableRow key={r.userId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {siteBionicsApplication.me?.id === r.userId ?
                        <TableCell></TableCell> :
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={r === selectedUserSiteRole} onClick={()=>{setSelectedUserSiteRole(r);}}/>                      
                        </TableCell>
                      }
                      <TableCell>                                          
                        <Typography><CachedUserControl userId={r.userId}></CachedUserControl></Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{site?.account.roleMap?.getSiteFriendlyRoleNames(r.siteRoles)}</Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                                  
                  {site?.account?.userAccountRoles?.map((r) => ( 
                    <TableRow key={r.userId + "all"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell></TableCell>
                      <TableCell>                                          
                        <Typography><CachedUserControl userId={r.userId}></CachedUserControl></Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {r.baseSiteRoles.length > 0 ?
                            site?.account.roleMap?.getSiteFriendlyRoleNames(r.baseSiteRoles)
                          :
                            "None"}
                        </Typography>
                      </TableCell>
                      <TableCell><Typography>Yes</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {tabValue === 1 &&
              <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
              <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row">Email</TableCell>
                    <TableCell component="th" scope="row">Date Sent</TableCell>
                    <TableCell component="th" scope="row">Expires</TableCell>
                    <TableCell component="th" scope="row">Accepted</TableCell>
                    <TableCell component="th" scope="row">Site Role</TableCell>
                  </TableRow>
                </TableHead>           
                <TableBody>
                  {site?.siteInvites?.map((r) => (
                    <TableRow key={r.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>                  
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={r === selectedSiteInvite} onClick={()=>{setSelectedSiteInvite(r);}}/>                      
                      </TableCell>
                      <TableCell>
                        <Typography><Tooltip title={r.id}><span>{r.sentToEmail}</span></Tooltip></Typography>                                          
                      </TableCell>
                      <TableCell>
                        <Typography>{r.sentDate.toLocaleString()}</Typography>                                          
                      </TableCell>
                      <TableCell>
                        <Typography>{r.expDate.toLocaleString()}</Typography>                                          
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {r.accepted ? "Yes" : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {site?.account.roleMap?.getSiteFriendlyRoleNames(r.siteRoles)}
                        </Typography>
                      </TableCell>
                    </TableRow>                
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={busy}><CircularProgress color="inherit" /></Backdrop>

      {adding &&
        <RoleDialog 
          title="Invite User"
          siteRoles={[]}
          saveButtonLabel="Invite"
          onCancel={() => setAdding(false)} 
          onSave={handleAdd}>                
        </RoleDialog>
      }
      {editing &&
        <RoleDialog 
          title="Edit Roles"
          userId={selectedUserSiteRole ? selectedUserSiteRole!.userId : selectedSiteInvite?.sentToEmail}              
          siteRoles={selectedUserSiteRole? selectedUserSiteRole!.siteRoles : selectedSiteInvite?.siteRoles}
          saveButtonLabel="Save"
          onCancel={() => setEditing(false)} 
          onSave={handleEdit}   
          requiresMatch={selectedUserSiteRole ? true : selectedSiteInvite?.requiresMatch}             
        />
      }
      {deleting &&           
        <Confirm
          confirmationText={"Are you sure you want to delete this user's roles?"}
          onClose={() => setDeleting(false)}
          onConfirm={handleDelete}
        />
      }
    </>
  );
});

export default SiteUsers;