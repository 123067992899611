import React, {useEffect} from 'react';
import GeneralCard from './GeneralCard';
import { Hub as HubIcon } from '@mui/icons-material';
import Account from '../models/Account';
import Site from '../models/Site';
import { observer } from "mobx-react"
import { Typography, Box } from '@mui/material';
import HardwareStatus from './HardwareStatus';

interface HardwareCardProps {  
    account: Account;
    site: Site;  
}

const HardwareCard: React.FC<HardwareCardProps> = observer(({ account, site }) => {

  useEffect(() => {
    if (!site.hubs) {
      site.loadHubsAsync();
    }

    if (!site.cameras) {
      site.loadCamerasAsync();
    }
  }, [site.hubs, site.cameras]);


  return (
    <GeneralCard title="Hardware"
        icon={<HubIcon style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
        moreLink={`/accounts/${account.accountId}/sites/${site.siteId}/hardware`}>

      <Box component="div" display="flex" flexDirection="column" sx={{gap: "10px"}}>
        <Typography>Number of hubs: {site.hubs ? site.hubs.length : "Loading..."}</Typography>                  
      
        <Typography>Number of cameras: {site.cameras ? site.cameras.length : "Loading..."}</Typography>

        <HardwareStatus accountId={site.account.accountId} site={site} />

      </Box>

    </GeneralCard>
  );
})

export default HardwareCard;