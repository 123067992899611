import { FunctionComponent } from "react";
import { observer } from "mobx-react"

import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { NavLink } from "react-router-dom";
import Title from "./TitleSimple";
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

export class Breadcrumb {

    name: string;
    link: string;

    constructor(name: string, link: string) {
        this.name = name;
        this.link = link;
    }
}

const BreadcrumbBarLink : FunctionComponent<{breadcrumb: Breadcrumb}> = observer(({breadcrumb}) => {
    const siteBionicsApplcation = useSiteBionicsApplication();
    return (
        breadcrumb.link === "" ?
            (<Typography color="gray">{breadcrumb.name}</Typography>)
        :
            (<NavLink style={{ color: 'gray' }} to={breadcrumb.link}>
                {breadcrumb.name === "(SiteName)" ? siteBionicsApplcation.currentSite?.siteName : breadcrumb.name}
            </NavLink>)
    )
})

const BreadcrumbBar : FunctionComponent<{ breadcrumbs: Breadcrumb[], title?: string | null }> = observer(({breadcrumbs, title }) => {
  return (
    <div style={{wordBreak: "break-all"}}>
        <Breadcrumbs aria-label="breadcrumb" sx={{padding: 2}} separator={<NavigateNextIcon fontSize="small" />}>
            { breadcrumbs.map((b, idx) => (
                <BreadcrumbBarLink key={`breadcrumb-${idx}`} breadcrumb={b}/>
            ))}
        </Breadcrumbs>
        {title && 
            <Title title={title}/>
        }
    </div>
  )
})

export default BreadcrumbBar;