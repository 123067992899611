import { GUID, Vector3 } from "@babylonjs/core";
import { DataModel, Model, detach, idProp, model, prop, rootRef } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import PoseModel from "./PoseModel";

export const layoutEntityRef = rootRef<LayoutEntity>("SiteBionics/LayoutEntityRef", {
    onResolvedValueChange(ref, newEntity, oldEntity) {
      if (oldEntity && !newEntity) {
        detach(ref)
      }
    }
  })

@model("SiteBionics/LayoutEntity")
export default class LayoutEntity extends Model({
  id: idProp,
}) {

}
