import { FC } from "react";
import { observer } from "mobx-react"
import { Alert, Box, Button, List, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import SelectableListItemButton from "./SelectableListItemButton";
import TriggerEntity from "../models/layout/TriggerEntity";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";

const TriggersAccordion: FC<{layoutViewModel: ScanAreaLayoutViewModel}> = observer(({layoutViewModel}) => {
    
  function handleRemoveTrigger(): void {
    if (layoutViewModel.selectedEntity instanceof TriggerEntity) {
      layoutViewModel.scanAreaLayout.removeTrigger(layoutViewModel.selectedEntity);
      layoutViewModel.selectEntity(undefined);
    }
  }

  function handleAddTrigger(): void {
      let trigger = new TriggerEntity({name: "New Trigger"});
      layoutViewModel.scanAreaLayout.addTrigger(trigger);
  }

    return (
        <Box component="div" width={"100%"} display="flex">
            <Accordion disableGutters sx={{width: "100%"}}
                  expanded={layoutViewModel.showTriggers ?? true} 
                  onChange={(e, expanded) => {layoutViewModel.setShowTriggers(expanded);}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row">
                    <DoorSlidingOutlinedIcon fontSize="small"/>
                    <Box component="div" width={10}/>
                    <Typography>Triggers</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component='div' display="flex" flexDirection="column">
                      {layoutViewModel.scanAreaLayout.triggers?.length ?
                        (<List dense={true} sx={{ bgcolor: 'background.paper', overflow: "clip" }} >
                            {layoutViewModel.scanAreaLayout.triggers.map((trigger) => (
                                <SelectableListItemButton key={trigger.name} label={trigger.name?.length > 0 ?trigger.name : "Unnamed Trigger"} ctx={trigger}/>
                            ))}
                        </List>) : <Alert severity="warning">No triggers.</Alert>
                      }
                      <Box component='div' display="flex" flexDirection="row" justifyContent="flex-end">
                        <Button id="removeTriggerButton"
                          disabled={!(layoutViewModel.selectedEntity instanceof TriggerEntity)}
                          onClick={handleRemoveTrigger}>
                          remove
                        </Button>
                        <Button id="addTriggerButton" onClick={handleAddTrigger}>add</Button>
                      </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
      )
    })
    
export default TriggersAccordion;

