import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "0136c655-9040-40b0-951a-72268d5396a2",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/',
        postLogoutRedirectUri: "/"
    },
    //cache: {
    //    cacheLocation: "localStorage"
    //},
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["api://0136c655-9040-40b0-951a-72268d5396a2/Sites.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

