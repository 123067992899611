import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import Address from "../models/Address";

import { Box, Typography } from '@mui/material';

const AddressView : FunctionComponent<{ address: Address }> = observer(({ address }) => {
  return (
    <Box component="div" display="flex" flexDirection="column">
      {address && address.street1 && address.city && address.state && address.postalCode &&
        <>
          <Typography>{address.street1}</Typography>
          {address.street2 && <Typography>{address.street2}</Typography>}
          {address.city}&nbsp;{address.state},&nbsp;{address.postalCode}
        </>
      }
    </Box>          
  )
})

export default AddressView;