import { FC, FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Box, FormControl, InputLabel, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import SiteNavigator from "../models/SiteNavigator";
import { useSelectionManager } from "../util/SelectionManager";
import { ModelType } from "../models/ModelType";

const modelTypes = [
    { name: ModelType[ModelType.Lidar], modelType: ModelType.Lidar },
    { name: ModelType[ModelType.Photogrammetry], modelType: ModelType.Photogrammetry },
    { name: ModelType[ModelType.GaussianSplats], modelType: ModelType.GaussianSplats },
  ];

const dateOptions : Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const ScanAreaAccordion: FC<{siteNavigator: SiteNavigator}> = observer(({siteNavigator}) => {
    const handleAreaChange = (event: SelectChangeEvent) => {
    siteNavigator.setCurrentScanAreaById(event.target.value);
    };

    const handleScanVersionChange = (event: SelectChangeEvent) => {
    siteNavigator.setCurrentScanByScanId(event.target.value);
    };

    const handleModelTypeChange = (event: SelectChangeEvent) => {
    siteNavigator.setCurrentModelType(parseInt(event.target.value));
    };
        
    return (
        <Box component="div" width={"100%"} display="flex">
            <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row">
                <StoreOutlinedIcon fontSize="small"/>
                <Box component="div"  width={10}/>
                <Typography>Area</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl size="small" sx={{marginBottom: 2}} fullWidth>
                    <InputLabel id="scan-area-id-label">Area</InputLabel>
                    <Select
                        labelId="scan-area-id-label"
                        id="scan-area-id"
                        value={siteNavigator.currentScanArea?.scanAreaId ?? ""}
                        label="Area"
                        onChange={handleAreaChange}>

                        {siteNavigator.site.scanAreas?.map((sa) => (
                            <MenuItem key={sa.scanAreaId} value={sa.scanAreaId}>{sa.scanAreaName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small" sx={{marginBottom: 2}} fullWidth>
                    <InputLabel id="scan-version-label">Scan Version</InputLabel>
                    <Select
                        labelId="scan-version-label"
                        id="scan-version"
                        value={siteNavigator.currentScan?.scanId ?? ""}
                        label="Scan Version"
                        onChange={handleScanVersionChange}>

                        {siteNavigator?.currentScanArea?.scans?.map((s) => (
                            <MenuItem key={s.scanId} value={s.scanId}>v{String(s.scanVersion)} - {s.scanTime.toLocaleDateString(undefined, dateOptions)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small" fullWidth>
                    <InputLabel id="model-type-label">View Type</InputLabel>
                    <Select
                        labelId="model-type-label"
                        id="model-type"
                        value={siteNavigator.currentModelType.toString()}
                        label="Model Type"
                        onChange={handleModelTypeChange}>

                        {modelTypes.map((mt) => (
                            <MenuItem key={mt.modelType} value={mt.modelType}>{mt.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </AccordionDetails>
            </Accordion>
        </Box>
      )
    })
    
export default ScanAreaAccordion;