import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSiteNavigator, useSitePageBreadcrumbs } from "./SitePage";

const Integrations: FunctionComponent = observer(() => {
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Hubs");

  return (

    <Box component="div" display="flex" height="100%" overflow="auto" flexDirection="column">

      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <Box component="div" flexGrow={1} sx={{ width: '90%', marginLeft: '5%' }}>

        <TableContainer component={Paper}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div">
              Integrations
            </Typography>
            <Box component="div" sx={{ flexGrow: 1 }} />
          </Toolbar>
        </TableContainer>

        <Box component="div" sx={{ height: 20 }} />

        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Service</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                  {siteNavigator.site.integrations?.map((i) => (
                    <TableRow key={i.siteId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">{i.service}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
)})

export default Integrations;