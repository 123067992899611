import { DataModel, getSnapshot, model, Model, modelAction, prop } from "mobx-keystone";
import { action, makeObservable, observable } from "mobx";
import CameraPose from "../CameraPose";
import EntranceEntity from "./EntranceEntity";
import RegionEntity from "./RegionEntity";
import AreaModelEntity from "./AreaModelEntity";
import CameraEntity from "./CameraEntity";
import Camera from "../Camera";
import TriggerEntity from "./TriggerEntity";

@model("SiteBionics/ScanAreaLayout")
export default class ScanAreaLayout extends Model({
  accountId: prop<string>().withSetter(),
  siteId: prop<string>().withSetter(),
  scanAreaId: prop<string>().withSetter(),
  cameras: prop<CameraEntity[]>(() => []).withSetter(),
  areaModels: prop<AreaModelEntity[]>(() => []).withSetter(),
  entrances: prop<EntranceEntity[]>(() => []).withSetter(),
  triggers: prop<TriggerEntity[]>(() => []).withSetter(),
  regions: prop<RegionEntity[]>(() => []).withSetter(),
  // this are legacy properties and will move out when we make layouts its own thing
  scanId: prop<string>().withSetter(),
  groundFloorHeight: prop<number>().withSetter(),
  cameraPoses: prop<CameraPose[]>(() => []).withSetter(),
}) {

  @modelAction addCamera(camera: CameraEntity) {
    this.cameras.push(camera);
  }

  @modelAction removeCamera(camera: CameraEntity) {
    this.cameras = this.cameras.filter(e => e !== camera);
  }

  @modelAction addEntrance(entrance: EntranceEntity) {
    this.entrances.push(entrance);
  }

  @modelAction removeEntrance(entrance: EntranceEntity) {
    this.entrances = this.entrances.filter(e => e !== entrance);
  }

  @modelAction addTrigger(trigger: TriggerEntity) {
    this.triggers.push(trigger);
  }

  @modelAction removeTrigger(trigger: TriggerEntity) {
    this.triggers = this.triggers.filter(e => e !== trigger);
  }

  @modelAction addRegion(region: RegionEntity) {
    this.regions.push(region);
  }

  @modelAction removeRegion(region: RegionEntity) {
    this.regions = this.regions.filter(r => r !== region);
  }

  @modelAction addAreaModel(scan: AreaModelEntity) {
    this.areaModels.push(scan);
  }

  @modelAction removeAreaModel(scan: AreaModelEntity) {
    this.areaModels = this.areaModels.filter(s => s !== scan);
  }

  onInit() {
    // in the case of a first time edit we don't have any scans so add the default ones
    if (this.areaModels.length === 0) {
      this.addAreaModel(new AreaModelEntity({ scanId: this.scanId, modelType: "Lidar" }));
      this.addAreaModel(new AreaModelEntity({ scanId: this.scanId, modelType: "Photogrammetry", usePoseFromLidar: true }));
      this.addAreaModel(new AreaModelEntity({ scanId: this.scanId, modelType: "GaussianSplats", usePoseFromLidar: true }));
    }

    // in the case of a first time edit we don't have any cameras 
    // so migrate them from the cameras poses written out by photogrammetry
    if (this.cameras.length === 0) {
      this.cameraPoses.forEach((cameraPose) => {
        this.addCamera(CameraEntity.fromCameraPose(cameraPose));
      });
    }
  }

  @modelAction synchCameras(cameras: Camera[]) {
    cameras.forEach((camera) => {
      let cameraEntity = this.cameras.find(c => c.cameraId === camera.cameraId);
      if (cameraEntity) {
        cameraEntity.setCamera(camera);
      }
      else {
        this.addCamera(new CameraEntity({cameraId: camera.cameraId}));
      }
    });
  }
}
