import { makeObservable, observable, action } from "mobx";

export default class UserAccountRole {
  @observable accountId: string;
  @observable userId: string;  
  @observable accountRoles: string[];
  @observable baseSiteRoles: string[];
  
  constructor(accountId: string, userId: string, accountRoles: string[], baseSiteRoles: string[]) {
    this.accountId = accountId;
    this.userId = userId;
    this.accountRoles = accountRoles;
    this.baseSiteRoles = baseSiteRoles;
    makeObservable(this);
  }

  clone() {
    return new UserAccountRole(this.accountId, this.userId, this.accountRoles.slice(), this.baseSiteRoles.slice());
  }

  @action
  updateFrom(data: UserAccountRole) {
    this.accountRoles = data.accountRoles.slice();
    this.baseSiteRoles = data.baseSiteRoles.slice();
  }

}