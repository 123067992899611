import React, { useEffect, useState } from 'react';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import Site from '../models/Site';
import { observer } from "mobx-react";
import { Box } from '@mui/material';
import { HubStatusResponse} from "../models/HubStatusResponse";
import StatusLED from '../components/StatusLED';
import useSiteHubStatuses from '../hooks/useSiteHubStatuses';

interface HardwareStatusProps {
    accountId: string;
    site: Site;
  }

const HardwareStatus: React.FC<HardwareStatusProps> = observer(({ accountId, site }) => {
    
    const [hubStatuses, color] = useSiteHubStatuses(accountId, site);    
  
    return (
      <div>
            Health Status:&nbsp;<StatusLED color={color}/>
      </div>
    );
  });
  
  export default HardwareStatus;