import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Outlet, useParams } from "react-router-dom";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useAccount } from "./AccountPage";

const Sites : FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const params = useParams<{ accountId: string; siteId: string }>();
  const account = useAccount();
  
  useEffect(() => {
    if (params.accountId && params.siteId) {
      siteBionicsApplication.setCurrentSiteByIdAsync(params.accountId, params.siteId);
    }
  }, []);

  return (<Outlet context={account}/>)
})

export default Sites;