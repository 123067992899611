import { FC, useEffect, useRef, useState } from "react";
import { Box, BoxProps, MantineProvider } from "@mantine/core";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import dayjs, { Dayjs } from "dayjs";


interface VideoPlayerProps extends BoxProps {
  posterSrc: string;
  hlsSrc: string;
  options?: Player["options"];
  startTime?: Dayjs;
  onTimeUpdate?: (currentTime: Dayjs) => void;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({
  hlsSrc,
  posterSrc,
  options,
  startTime,
  onTimeUpdate,
  ...props
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);
  const updateInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const playerOptions = {
        controls: true,
        fluid: true,
        fill: true,
        responsive: true,
        sources: [{ src: hlsSrc, type: "application/x-mpegURL" }],
        poster: posterSrc,
        ...options,
      };

      playerRef.current = videojs(videoElement, playerOptions);

      
      // playerRef.current.on('loadedmetadata', () => {
      //   let playlists = (playerRef.current?.tech() as any).vhs.playlists.main.playlists;
      //   if (playlists.length > 0) {
      //     let startTime = playlists[0].dateTimeObject as Date;
      //     setStartTime(dayjs(startTime));
      //   }
      // });

      // playerRef.current.on('timeupdate', () => {
      //   const currentTime = playerRef.current?.currentTime();
      //   if (currentTime !== undefined && onTimeUpdate) {
      //     onTimeUpdate(currentTime);
      //   }
      // });

      updateInterval.current = setInterval(() => {
        const currentTime = playerRef.current?.currentTime();
        if (currentTime !== undefined && onTimeUpdate && startTime) {
          onTimeUpdate(startTime.add(currentTime, 'second'));
        }
      }, 100);  // Check every 1/10th second
    
    } 
    
    if (playerRef.current) {
      playerRef.current.autoplay(true);
      playerRef.current.src({ src: hlsSrc, type: "application/x-mpegURL"});
      //playerRef.current.play()
    }

    return () => {
      if (playerRef.current) {
        if (updateInterval.current) clearInterval(updateInterval.current);
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };

  }, [hlsSrc, options, posterSrc]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <MantineProvider>
      <Box data-vjs-player {...props}>
        <Box ref={videoRef} />
      </Box>
    </MantineProvider>
  );
};
