import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Box, Typography, Button } from "@mui/material";
import BreadcrumbBar from "../components/BreadcrumbBar";
import GeneralCard from "../cards/GeneralCard";
import AccountUsersCard from "../cards/AccountUsersCard";
import { useAccount, useAccountBreadcrumbs } from "./AccountPage";
import AddressView from "../components/AddressView";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { BusinessCenter } from '@mui/icons-material';
import TitleSimple from '../components/TitleSimple';
import AccountSitesCard from "../cards/AccountSitesCard";

const AccountCards : FunctionComponent = observer(() => {
  const account = useAccount();

  const breadcrumbs = useAccountBreadcrumbs("");

  function createAccountLink(page: string): string {
    return `/accounts/${account.accountId}/${page}`;
  }

  const siteBionicsApplication = useSiteBionicsApplication();


  return (
    <>

      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleSimple title={"Account: " + account.accountName} sx={{paddingLeft:"10pt", paddingRight: "10pt"}}/>

      <Box component="div" sx={{height: "10pt"}}/>

      <Box component="div"  display="flex" flexWrap="wrap" sx={{overflow:"auto"}}>  

        <AccountSitesCard account={account} />
        
        <GeneralCard title="Account Info"          
          icon={<BusinessCenter style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}        
          moreLink={createAccountLink("info")}
        >
          <Box component="div" display="flex" flexDirection="column">
            <Typography>{account.accountName}</Typography>
            <AddressView address={account.billingAddress}/>            
          </Box>
        </GeneralCard>      

                
        {account.hasAccountCapability("ManageAccountUsers") &&
          <AccountUsersCard account={account} />
        }
      </Box>
    </>
  )
})

export default AccountCards;
