import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useForm } from 'react-hook-form';

export interface PairHubDialogProps {
  onPair: (hubId: string, pairingCode: string) => void;
  onClose: () => void;
  siteName: string;
}

interface FormValues {
  hubId: string,
  pairingCode: string
}

function PairHubDialog(props: PairHubDialogProps) {
  const { onClose, onPair, siteName} = props;
  
  const {register, handleSubmit, formState: {errors, isValid, touchedFields}} = useForm<FormValues>({
    defaultValues: {
      hubId: "",
      pairingCode: ""
    },
    mode: "onBlur"
  });

  const handleClose = () => {
    onClose();
  };

  const handleOk = (data: FormValues) => {
    onPair(data.hubId, data.pairingCode);    
  };

  return (
    <Dialog onClose={handleClose} open={true}>
     <DialogTitle>Pair Hub to {siteName}</DialogTitle>
      <form onSubmit={handleSubmit(handleOk)}>
      <DialogContent>
        <TextField
          margin="dense"
          label="Hub Id"
          type="text"
          fullWidth
          required
          variant="outlined"
          {...register("hubId", { 
            required: "Hub Id is required",
            pattern: {
              // Regex for a guid
              value: /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/,
              message: "Must be a valid hub id"
            }
          })}
          error={!!(touchedFields["hubId"] && errors["hubId"])}
          helperText={touchedFields["hubId"] && errors.hubId?.message}               

        />
        <TextField
          margin="dense"
          label="Pairing Code"
          type="text"
          fullWidth
          variant="outlined"
          required
          {...register("pairingCode", { 
            required: "Pairing code is required",
            pattern: {
              // Regex for a guid
              value: /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/,
              message: "Must be a valid pairing code"
            }
          })}
          error={!!(touchedFields["pairingCode"] && errors["pairingCode"])}
          helperText={touchedFields["pairingCode"] && errors.pairingCode?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" variant="outlined" disabled={!isValid} color="primary">
          Pair
        </Button>
      </DialogActions>
      </form>
  </Dialog>
  )
}

export default PairHubDialog;
