
import { makeObservable, observable } from "mobx";

export default class SiteIntegration {

    @observable integrationId: string;
    @observable accountId: string;
    @observable siteId: string;
    @observable service: string;
    @observable details: any;

    constructor(integrationId: string, accountId: string, siteId: string, service: string, details: any) {
        this.integrationId = integrationId;
        this.accountId = accountId;
        this.siteId = siteId;
        this.service = service;
        this.details = details;
        makeObservable(this);
    }
}
