
import { action, makeObservable, observable } from "mobx";
import DataContext from "./DataContext";
import Account from "./Account";
import Camera from "./Camera";
import ScanArea from "./ScanArea";

import Hub from "./Hub";
import UserSiteRole from "./UserSiteRole";
import SiteIntegration from "./SiteIntegration";
import Address from "./Address";
import {SiteInvite} from "./Invite";
import { hubCache, cameraCache } from "./Cache";

export default class Site {

    private dataContext: DataContext

    @observable account: Account;
    @observable siteId: string;
    @observable id: string;
    @observable siteName: string;
    @observable siteRoles: string[];
    @observable siteCapabilities: string[];
    @observable address: Address;

    @observable userSiteRoles?: UserSiteRole[];
    @observable siteInvites?: SiteInvite[];
    @observable scanAreas?: ScanArea[];
    @observable cameras?: Camera[];
    @observable hubs?: Hub[];
    @observable integrations?: SiteIntegration[];
    

    constructor(
        dataContext: DataContext,
        account: Account, 
        siteId: string, 
        siteName: string, 
        address: Address | null | any, 
        siteRoles: string[] | null, 
        siteCapabilities: string[] | null) {
        this.dataContext = dataContext;
        this.account = account;
        this.siteId = siteId;
        this.id = siteId;
        this.siteName = siteName;        
        this.siteRoles = siteRoles ? siteRoles.slice() : []
        this.siteCapabilities = siteCapabilities ? siteCapabilities.slice() : []
        this.address = address ?
            new Address(address.street1, address.street2, address.country, address.city, address.state, address.postalCode) : 
            new Address("", "", "", "", "", "");

        makeObservable(this);
    }
    
    @action
    setUserSiteRoles(userSiteRoles?: UserSiteRole[]) {
        this.userSiteRoles =userSiteRoles;
    }

    @action
    setHubs(hubs?: Hub[]) {
        this.hubs = hubs;
    }

    @action
    setCameras(cameras?: Camera[]) {
        this.cameras = cameras;
    }

    @action
    setScanAreas(scanAreas?: ScanArea[]) {
        this.scanAreas = scanAreas;
    }

    @action
    async loadAreasAsync(refresh: boolean = false): Promise<ScanArea[] | undefined> {
        if (this.scanAreas === undefined || refresh) {
            let scanAreas = await this.dataContext.service.fetchScanAreaListAsync(this);
            this.setScanAreas(scanAreas);
        }
        return this.scanAreas;
    }

    @action
    async loadHubsAsync(refresh: boolean = false): Promise<Hub[] | undefined> {
        if (this.hubs === undefined || refresh) {
            let hubs = await hubCache.getList(this.siteId, () => this.dataContext.service.fetchHubListAsync(this) )
            this.setHubs(hubs ?? []);
        }
        return this.hubs;
    }

    @action
    async loadCamerasAsync(refresh: boolean = false): Promise<Camera[] | undefined> {
        if (this.cameras === undefined || refresh) {
            let cameras = await cameraCache.getList(this.siteId, () => this.dataContext.service.fetchCameraListAsync(this));
            cameras.sort((a, b) => a.cameraName && b.cameraName ? a.cameraName.localeCompare(b.cameraName) : a.cameraId.localeCompare(b.cameraId));
            this.setCameras(cameras ?? []);
        }
        return this.cameras;
    }
    
    @action
    async loadUserSiteRolesAsync(refresh: boolean = false): Promise<UserSiteRole[] | undefined> {
        if (this.userSiteRoles === undefined || refresh) {
        let userSiteRoles = await this.dataContext.service.fetchUserSiteRoles(this.account.accountId, this.siteId);
        this.setUserSiteRoles(userSiteRoles);
        }
        return this.userSiteRoles;
    } 

    @action
    setSiteInvites(siteInvites?: SiteInvite[]) {
        this.siteInvites = siteInvites;
    }

    @action
    async loadSiteInvitesAsync(refresh: boolean = false): Promise<SiteInvite[] | undefined> {
        if (this.siteInvites === undefined || refresh) {
            let siteInvites = await this.dataContext.service.fetchSiteInvites(this.account.accountId, this.siteId);
            this.setSiteInvites(siteInvites);
        }
        return this.siteInvites;
    } 

    hasSiteRole(siteRole : string) : boolean {
        if ((this.siteRoles && this.siteRoles?.indexOf(siteRole) !== -1) ||
            (this.account.baseSiteRoles && this.account.baseSiteRoles?.indexOf(siteRole) !== -1))
            return true;
        return false;
    }

    hasSiteCapability(capability : string) : boolean {
        if ((this.siteCapabilities && this.siteCapabilities?.indexOf(capability) !== -1) ||
            (this.account.baseSiteCapabilities && this.account.baseSiteCapabilities?.indexOf(capability) !== -1))
            return true;
        return false;
    }    

    @action
    updateData(name: string, address: Address) {
        this.siteName = name;
        this.address.updateFrom(address);
    }

    @action
    updateFrom(data: Site) {
        this.siteName = data.siteName;
        this.siteRoles = data.siteRoles.slice();
        this.siteCapabilities = data.siteCapabilities.slice();        
        this.address.updateFrom(data.address);
    }

    clone(): Site {
        return new Site(this.dataContext, this.account, this.siteId, this.siteName, this.address.clone(), this.siteRoles.slice(), this.siteCapabilities.slice());
    }
}
