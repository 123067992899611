import React, { MouseEvent, useState } from 'react';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

interface TimelineProps {
  startDate: Date;
  endDate: Date;
  onTimeChange?: (time: Dayjs) => void;
  onRangeSelect?: (start: Dayjs, end: Dayjs) => void;
}

const Timeline: React.FC<TimelineProps> = ({ startDate, endDate, onTimeChange, onRangeSelect }) => {
  const start = startDate;
  const end = endDate;
  const totalSeconds = (end.getTime() - start.getTime()) / 1000;
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const [selectionStart, setSelectionStart] = useState<number | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

  const calculateTimeFromPosition = (x: number, boundingRect: DOMRect) => {
    const percentage = x / boundingRect.width;
    const secondsFromStart = Math.floor(totalSeconds * percentage);
    return dayjs(new Date(start.getTime() + secondsFromStart * 1000));
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    const boundingRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const x = event.clientX - boundingRect.left;
    setCursorPosition(x);
    if (selectionStart !== null) {
      setSelectionEnd(x);
      if (onRangeSelect) {
        const startTime = calculateTimeFromPosition(selectionStart, boundingRect);
        const endTime = calculateTimeFromPosition(x, boundingRect);
        onRangeSelect(startTime, endTime);
      }
    } else {
        if (onTimeChange) {
            onTimeChange(calculateTimeFromPosition(x, boundingRect));
        }
    }
  };

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    const boundingRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const x = event.clientX - boundingRect.left;
    setSelectionStart(x);
    setSelectionEnd(x);
  };

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (selectionStart !== null && selectionEnd !== null && onRangeSelect) {
      const boundingRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
      const startTime = calculateTimeFromPosition(selectionStart, boundingRect);
      const endTime = calculateTimeFromPosition(selectionEnd, boundingRect);
      onRangeSelect(startTime, endTime);
    }
    setSelectionStart(null);
    setSelectionEnd(null);
  };

  // Generate tick marks
  const ticks = Array.from({ length: totalSeconds }, (_, index) => ({
    position: (index / totalSeconds) * 100,
    height: index % 3600 === 0 ? '100%' : index % 60 === 0 ? '50%' : '25%'
  }));

  return (
    <Box component="div"
      sx={{ position: 'relative', height: '100%', border: '1px solid black', width: '100%' }}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {/* Render tick marks */}
      {ticks.map((tick, index) => (
        <Box component="div"
          key={index}
          sx={{
            position: 'absolute',
            left: `${tick.position}%`,
            bottom: 0,
            height: tick.height,
            width: '1px',
            bgcolor: 'gray'
          }}
        />
      ))}

      {/* Cursor line */}
      {cursorPosition !== null && !selectionStart && (
        <Box component="div"
          sx={{
            position: 'absolute',
            left: cursorPosition,
            top: 0,
            height: '100%',
            width: '2px',
            bgcolor: 'red'
          }}
        />
      )}

      {/* Selection area */}
      {selectionStart !== null && selectionEnd !== null && (
        <Box component="div"
          sx={{
            position: 'absolute',
            left: Math.min(selectionStart, selectionEnd),
            top: 0,
            height: '100%',
            width: `${Math.abs(selectionEnd - selectionStart)}px`,
            bgcolor: 'rgba(0, 0, 255, 0.3)' // Use a semi-transparent blue for the selection area
          }}
        />
      )}
    </Box>
  );
};

export default Timeline;
