export class HeatmapCellEntry {
    cellRow: number;
    cellCol: number;
    weight: number;

    constructor(cellRow: number, cellCol: number, weight: number) {
        this.cellRow = cellRow;
        this.cellCol = cellCol;
        this.weight = weight;
    }
}

export default class HeatmapEntry {
    heatmapEntryId: string;
    accountId: string;
    siteId: string;
    scanAreaId: string;
    trackedObjectId: string;
    startTime: Date;
    endTime: Date;
    tags: string[];
    heatmapCellEntries: HeatmapCellEntry[];
    
    constructor(heatmapEntryId: string, accountId: string, siteId: string, scanAreaId: string, trackedObjectId: string, startTime: Date,  endTime: Date, tags: string[], heatmapCellEntries: HeatmapCellEntry[]) {
        this.heatmapEntryId = heatmapEntryId;
        this.accountId = accountId;
        this.siteId = siteId;
        this.scanAreaId = scanAreaId;
        this.trackedObjectId = trackedObjectId;
        this.startTime = startTime;
        this.endTime = endTime;
        this.tags = tags;
        this.heatmapCellEntries = heatmapCellEntries;
    }
    
}