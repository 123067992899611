import { Vector3 } from '@babylonjs/core';
import { computed } from 'mobx';
import { Model, model, prop } from 'mobx-keystone';
import Vector3Model from './Vector3Model';
import QuaternionModel from './QuaternionModel';
import Pose from '../Pose';


@model("SiteBionics/PoseModel")
class PoseModel extends Model({
    position: prop<Vector3Model>(Vector3Model.zero).withSetter(),
    orientation: prop<QuaternionModel>(QuaternionModel.identity).withSetter(),
  },
  {
    valueType: true
  }
) {
  
    static identity(): PoseModel {
        return new PoseModel({ position: Vector3Model.zero(), orientation: QuaternionModel.identity() });
        }

    static fromPose(pose: Pose): PoseModel {
        return new PoseModel({
            position: Vector3Model.fromVector3(pose.position),
            orientation: QuaternionModel.fromQuaternion(pose.orientation)});
    }
}

export default PoseModel;