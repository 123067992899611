import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import HeatmapEntry, { HeatmapCellEntry } from "../models/HeatmapEntry";
import { useScene } from "react-babylonjs";
import { Color3, Mesh, MeshBuilder, StandardMaterial } from "@babylonjs/core";

const HeatmapLayer: FunctionComponent<{heatmapEntries: HeatmapEntry[], clipValue: number}> = observer(({heatmapEntries, clipValue}) => {
  
  const scene = useScene();


  useEffect(() => {
    if (scene) {
      var allMeshes : Mesh[] = [];
      const combinedEntries: Map<string, HeatmapCellEntry> = new Map();

      heatmapEntries.forEach(heatmapEntry => {
        heatmapEntry.heatmapCellEntries.forEach(cellEntry => {
          const key = `${cellEntry.cellRow}-${cellEntry.cellCol}`;
          if (combinedEntries.has(key)) {
              combinedEntries.get(key)!.weight += cellEntry.weight;
          } else {
              combinedEntries.set(key, new HeatmapCellEntry(cellEntry.cellRow, cellEntry.cellCol, cellEntry.weight));
          }
        })
      });

      var cellEntries = Array.from(combinedEntries.values());
      let maxWeight = Math.max(...cellEntries.map(entry => entry.weight));
      maxWeight = Math.min(maxWeight, clipValue);

      cellEntries.forEach(entry => {
        const position = new Vector3(-entry.cellCol * 0.25, 0, entry.cellRow * 0.25); // negative for left handed coord system
        const quad = MeshBuilder.CreatePlane(`quad-${entry.cellCol}-${entry.cellRow}`, { size: 0.25 }, scene);
        quad.position = position;
        quad.rotation.x = Math.PI / 2; // Rotate to lie flat on the ground

        const material = new StandardMaterial(`material-${entry.cellCol}-${entry.cellRow}`, scene);
        const normalizedWeight = entry.weight / maxWeight;
        const weightColor = Color3.Lerp(Color3.Blue(), Color3.Red(), normalizedWeight);
        material.diffuseColor = weightColor;
        quad.material = material;
        allMeshes.push(quad);
      });

      // Cleanup function to remove the meshes from the scene
      return () => {
        allMeshes.forEach(mesh => mesh.dispose());
      };
    }
  }, [heatmapEntries, scene, clipValue]);

  return (
    <>
      

    </>
  )
})

export default HeatmapLayer;


