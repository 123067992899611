
import { action, makeObservable, observable } from "mobx";
import DataContext from "./DataContext";
import Site from "./Site";

export default class Hub {

    private dataContext: DataContext;

    @observable site: Site;
    @observable hubId: string;
    @observable name: string;
    @observable id: string;

    constructor(dataContext: DataContext, site: Site, hubId: string, name: string) {
        this.dataContext = dataContext;
        this.name = name;
        this.site = site;
        this.hubId = hubId;
        this.id = hubId;
        makeObservable(this);
    }

    @action
    async deleteAsync() {
        // client side lie... remove locally first
        let remainingHubs = this.site.hubs?.filter(h => h.hubId !== this.hubId);
        this.site.setHubs(remainingHubs);
        await this.dataContext.service.unpairHubAsync(this);
        //todo: handle errer
    }

    async restartHubAsync() {
        await this.dataContext.service.restartHubAsync(this);
    }

    @action
    async setName(name: string) {
        this.name = name;
    }
}
