import { FunctionComponent } from "react";
import { observer } from "mobx-react"

import { Box, Typography } from '@mui/material';

interface TitleProps {
  title: string;
  sx?: React.CSSProperties;
}

const Title: FunctionComponent<TitleProps> = observer(({ title, sx }) => {
  return (
    <Box component="div" sx={sx}>
        <Typography variant="h6" color="inherit" component="div">
            {title}
        </Typography> 
    </Box>            
  )
})

export default Title;
