import { ExtendedModel, idProp, model, modelAction, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import PoseModel from "./PoseModel";
import { action, observable } from "mobx";
import Camera from "../Camera";
import CameraPose from "../CameraPose";
import { Vector2, Vector3 } from "@babylonjs/core";
import Vector2Model from "./Vector2Model";
import { projectPointToGroundPlane, undistortPointBrownConrady } from "../../util/MathUtil";


@model("SiteBionics/CameraEntity")
export default class CameraEntity extends ExtendedModel(LayoutEntity, {
    pose: prop<PoseModel>(PoseModel.identity).withSetter(),
    cameraId: prop<string>("").withSetter(),
    fieldOfView: prop<number>(91.25).withSetter(),
    distortionK: prop<number>(-0.1675).withSetter(),
    alignmentPoints: prop<Vector2Model[]>(() => []).withSetter(),
}) {
    @modelAction addAlignmentPoint(alignmentPoint: Vector2) {
        this.alignmentPoints.push(Vector2Model.fromVector2(alignmentPoint));
    }

    @observable camera?: Camera
    @action setCamera(camera: Camera) { this.camera = camera; };

    undistortPoint(point: Vector2): Vector2 {   
        if (!this.camera) return point;

        

        return undistortPointBrownConrady(point,
            this.camera.width, this.camera.height, 
            this.fieldOfView, this.distortionK, 0, 0, 0, 0);

            //return undistortPointFisheye4(point, this.camera.width, this.camera.height, this.camera.fov,  -0.023625047142807405, -0.052997311672815219, -0.06365360245026884, 0.077436597562199477);

            //this.fieldOfView, -0.023625047142807405, -0.052997311672815219, -0.06365360245026884, 0.077436597562199477, 0);
            //this.camera.fov,
            //this.camera.distortionCoeff[0], this.camera.distortionCoeff[1], 
            //this.camera.distortionCoeff[2], this.camera.distortionCoeff[3], 
            //this.camera.distortionCoeff[4]);
    }

    projectPointToGroundPlane(point: Vector2, planeHeight: number = 0): Vector3 {
        if (!this.camera) return Vector3.Zero();
        const normalizedPoint = new Vector2( point.x / this.camera.width, point.y / this.camera.height);
        return projectPointToGroundPlane(normalizedPoint, 
            this.camera.width/this.camera.height, this.fieldOfView,
            this.pose.position.asVector3, this.pose.orientation.asQuaternion,
            planeHeight);
    }

    static fromCameraPose(cameraPose: CameraPose): CameraEntity {
        return new CameraEntity({cameraId: cameraPose.cameraId, pose: PoseModel.fromPose(cameraPose.pose)});
    }
}

