import { Vector3 } from "@babylonjs/core";
import { DataModel, ExtendedModel, idProp, Model, model, prop } from "mobx-keystone";
import Vector3Model from "./Vector3Model";
import LayoutEntity from "./LayoutEntity";
import PoseModel from "./PoseModel";


@model("SiteBionics/RegionEntity")
export default class RegionEntity extends ExtendedModel(LayoutEntity, {
    name: prop<string>().withSetter(),
    pose: prop<PoseModel>(PoseModel.identity).withSetter(),
    size: prop<Vector3Model>(() => {return new Vector3Model({x:1,y:1,z:1})}).withSetter(),
}) {

}
