export interface HubStatusFragmentAgeOutPolicy {
    minimumFreeBytes: number;
    maximumAgeTimeSpan: number;
}

export interface HubStatusCamera {
    cameraId: string;
    name: string;
    videoFPS: number;
    inferenceFPS: number;
}

export interface HubStatus {
    runningSince: string;
    recordingState: number;
    fragmentAgeOutPolicy: HubStatusFragmentAgeOutPolicy;
    versionsUrl: string;
    version: string;
    rootApiUrl: string;
    cameras: HubStatusCamera[];
}

export class HubStatusResponse {
    public id: string;
    public time: string;
    public status: HubStatus;

    constructor(id: string, data: any) {
        this.id = id;
        this.time = data.time;
        this.status = data.status;
    }

    public getHubStatusAverageInferenceFPS(): number {
        const totalFPS = this.status.cameras.reduce((sum, camera) => sum + camera.inferenceFPS, 0);
        return totalFPS / this.status.cameras.length;        
    }

    public getHubStatusAverageVideoFPS(): number {        
    
        const totalFPS = this.status.cameras.reduce((sum, camera) => sum + camera.videoFPS, 0);
        return totalFPS / this.status.cameras.length;
    }

    public getHubStatusHealth(): string {    

        const now = new Date();
        const lastUpdate = new Date(this.time);
        const differenceInMinutes = (now.getTime() - lastUpdate.getTime()) / (1000 * 60);
    
        const averageFPS = this.getHubStatusAverageVideoFPS();
    
        if (differenceInMinutes < 10 && averageFPS > 10)
            return "green";
        else if (differenceInMinutes < 120)
            return "yellow";        
        else
            return "red";
    }    
}


