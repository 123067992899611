import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { useSearchParams, useNavigate } from 'react-router-dom';

import {AccountInvite, SiteInvite, Invite} from '../models/Invite';

import { Typography, Box, Button } from '@mui/material';

import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { accountCache, siteCache } from "../models/Cache";


const InvitePage : FunctionComponent = observer(() => {
    
    const [searchParams] = useSearchParams();
    
    const siteBionicsApplication = useSiteBionicsApplication();
    const inviteId = searchParams.get('inviteId');
    const accountId = searchParams.get('accountId');
    const siteId = searchParams.get('siteId');

    const [loading, setLoading] = useState(false);
    const [accepting, setAccepting] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [inviteTitle, setInviteTitle] = useState("");
    
    const handleAccept = () => {
        setAccepting(true);
        if (siteId === null) {
            siteBionicsApplication.service.acceptAccountInvitation(accountId!, inviteId!).then(() => {
                accountCache.removeList(siteBionicsApplication.me?.id!);                
                accountCache.removeItem(accountId!);
                siteBionicsApplication.accountList = undefined;
                siteBionicsApplication.loadAccountsAsync().then(() => {
                    navigate(`/accounts/${accountId}`);                
                });
                
            });
        } else {
            siteBionicsApplication.service.acceptSiteInvitation(accountId!, siteId!, inviteId!).then(() => {
                accountCache.removeList(siteBionicsApplication.me?.id!);
                accountCache.removeItem(accountId!);
                siteCache.removeList(accountId!);
                siteCache.removeItem(siteId!);
                siteBionicsApplication.accountList = undefined;
                siteBionicsApplication.loadAccountsAsync().then(() => {
                    navigate(`/accounts/${accountId}/sites/${siteId}`);
                });
                
            });
        }
    };

    useEffect(() => {


        const validateInvite = (invite: Invite) => {
        
            if (invite.sentToEmail !== siteBionicsApplication.me?.email && invite.requiresMatch) {
                setErrorMessage("Signed in user not able to accept this invitation.");
                return;
            }
    
            if (siteBionicsApplication.me && invite.senderEmail === siteBionicsApplication.me?.email) {
                setErrorMessage("Unable to accept your own invitation");
                return;
            }
    
            if (invite.accepted) {
                setErrorMessage("This invite has already been accepted");
                return;
            }
    
            const now = new Date();
            if (invite.expDate.getTime() < now.getTime()) {
                setErrorMessage("This invitation has expired");
                return;
            }        
        }

        if (!siteBionicsApplication.me) {
            return;
        }
    
        if (accountId === null || inviteId === null) {
            setErrorMessage("Invalid invitation");
            return;
        }

        setLoading(true);
        if (siteId === null) {
            siteBionicsApplication.service.fetchAccountInvitation(accountId, inviteId).then((accountInvite: AccountInvite | null) => {

                if (accountInvite === null) {
                    setErrorMessage("Invalid invitation");
                } else {
                    validateInvite(accountInvite!);

                    setInviteTitle(`You've been invited to use the Site Bionics account '${accountInvite.accountName}'`);    
                }

                
                setLoading(false);
            });
        } else {
            siteBionicsApplication.service.fetchSiteInvitation(accountId, siteId, inviteId).then((siteInvite: SiteInvite | null) => {
                if (siteInvite === null) {
                    setErrorMessage("Invalid invitation");
                } else {
                    validateInvite(siteInvite);

                    setInviteTitle(`You've been invited to use the site Site Bionics site '${siteInvite.siteName}' in the account '${siteInvite.accountName}'`);                    
                }
                setLoading(false);
            });
        }

    }, [accountId, inviteId, siteBionicsApplication.me, siteBionicsApplication.service, siteId]);

    return (
      <Box component="div" display="flex" flexDirection="column">

        <Box component="div" sx={{ marginX: '20pt' }}> 

            <Box component="div" sx={{height: 20}}/>

            <Typography variant="h5" color="inherit" component="div">
                Invitation
            </Typography>         
            
            <Box component="div" sx={{height: 20}}/>

            {loading && <Typography>Loading...</Typography>}

            {!loading && 
                <>
                    {errorMessage != null ?
                        <Box component="div" sx={{display: "flex", flexDirection: "column"}}>
                            <Typography>{errorMessage}</Typography>
                            <Box component="div" sx={{height: "10pt"}}>                                
                            </Box>
                            <Button onClick={() => navigate("/accounts")}>My Accounts</Button>
                        </Box>
                    :
                        <>
                            {!siteBionicsApplication.me ?
                                <Typography>Please login to accept the invitation.</Typography>
                            :
                                <>
                                    <Typography>{inviteTitle}</Typography>

                                    <Box component="div" sx={{height: 20}}/>

                                    <Typography>Click Accept for access</Typography>

                                    <Box component="div" sx={{height: 20}}/>
                                    
                                    <Button onClick={handleAccept} disabled={accepting}>Accept</Button>
                                </>
                            }                
                        </>
                    }                    
                </>
            }            
        </Box>
      </Box>
    );
})

export default InvitePage;