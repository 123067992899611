import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { ListItemButton, ListItemText } from '@mui/material';
import { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";



const SelectableListItemButton: FunctionComponent<{ label: string, ctx: any}> = observer(({label, ctx}) => {
    const layoutViewModel = useScanAreaLayoutViewModel();
    return (
      <ListItemButton selected={ctx === layoutViewModel?.selectedEntity} onClick={(_event)=>{ layoutViewModel?.selectEntity(ctx) }}>
        <ListItemText primary={label} />
      </ListItemButton>
    );
  })

export default SelectableListItemButton;