import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"

import { Box, Typography } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import { useSitePageBreadcrumbs, useSite } from "./SitePage";
import AddressView from "../components/AddressView";
import TitleSimple from "../components/TitleSimple";

const SiteInfo : FunctionComponent = observer(() => {

  const site = useSite();
  const account = site.account;
  const address = site.address;
  
  const breadcrumbs = useSitePageBreadcrumbs("Site Information");

  useEffect(() => {
    account.loadRoleMapAsync();
  });  
    
  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleSimple title="Site Information" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}/>

      <Box component="div" sx={{paddingX: '10pt', overflow: "auto"}}>        

        <Typography sx={{marginTop: "20pt"}}>Name: {site.siteName}</Typography>

        <Box component="div" sx={{ height: '20pt' }} ></Box>


        <Typography>Site Address:</Typography>
        <AddressView address={address} />

        <Box component="div" sx={{ height: '20pt' }} ></Box>

        {account.roleMap ?            
          <Typography>Roles: {account.roleMap.getSiteFriendlyRoleNames(account.baseSiteRoles.concat(site.siteRoles).filter((item, index, array) => array.indexOf(item) === index))} </Typography>          
        :
          <Typography>Roles: Loading...</Typography>
        }
      </Box>
      
    </>
  )
})

export default SiteInfo;