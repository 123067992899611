
import { makeObservable, observable } from "mobx";

export default class UserInfo {

    @observable firstName: string;
    @observable lastName: string;
    @observable email: string;
    @observable id: string;
    @observable tosVersion: number;
    @observable tosDate: Date;

    constructor(id: string, email: string, firstName: string, lastName: string, tosVersion: number, tosDate: Date) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.tosVersion = tosVersion;
        this.tosDate = tosDate;
        makeObservable(this);
    }
}