import React from 'react';
import { Box, Typography} from '@mui/material';
import BreadcrumbBar from './BreadcrumbBar';
import Account from '../models/Account';
import Site from '../models/Site';
import TitleSimple from '../components/TitleSimple';

interface NoAccessProps {
  title?: string;    
  message?: string;
  account?: Account;
  site?: Site;
}


const NoAccess: React.FC<NoAccessProps> = ({
  title = "No Access",
  message = "You do not have permission to access this functionality",
  account = null,
  site = null
}) => {

  let breadcrumbs = [
      {name: "Accounts", link: "/accounts"},
    
  ];

  if (account) {
    breadcrumbs.push({ name: account.accountName, link: `/accounts/${account.accountId}`});
    breadcrumbs.push({ name: "Sites", link: `/accounts/${account.accountId}/sites`});
    if (site) {
      breadcrumbs.push({ name: site.siteName, link: `/accounts/${account.accountId}/sites/${site.siteId}`});
    }
  }

  breadcrumbs.push(  {name: "No Access", link: ""});

  return (
    <Box component="div" display="flex" flexDirection="column">      

        <BreadcrumbBar breadcrumbs={breadcrumbs}/>

        <Box component="div" sx={{height: '10px'}}></Box>

        <Box component="div" display="flex" flexDirection="column" sx={{paddingX: '10px'}}> 

          <TitleSimple title="Invalid Site"/>

          <Box component="div" sx={{height: '10px'}}></Box>
                
          <Typography>{message}</Typography>
        </Box>

      </Box>
  );
};

export default NoAccess;
