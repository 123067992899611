import { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import SiteNavigator from "../models/SiteNavigator";


export interface HubStatusDialogProps {
  statusData : any;
  onClose: () => void;
}

function HubStatusDialog(props: HubStatusDialogProps) {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const { statusData, onClose } = props;

  return (
    <Dialog onClose={onClose} open={true}>
     <DialogTitle>Hub Status</DialogTitle>
      <form onSubmit={onClose}>
      <DialogContent>
        <pre>{JSON.stringify(statusData, null, 2)}</pre>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
      </form>
  </Dialog>
  )
}

export default HubStatusDialog;
