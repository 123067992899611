import { FunctionComponent, useEffect} from "react";
import { observer } from "mobx-react"
import { Box } from '@mui/material';
import BreadcrumbBar from "../components/BreadcrumbBar";
import AddressView from "../components/AddressView";
import { Typography } from '@mui/material';
import { useAccountBreadcrumbs, useAccount } from "./AccountPage";
import TitleSimple from "../components/TitleSimple";

const AccountInfo : FunctionComponent = observer(() => {
  
  const account = useAccount();
  
  const breadcrumbs = useAccountBreadcrumbs("Account Information");  

  useEffect(() => {
    account.loadRoleMapAsync();
  });  
  
    
  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbs}/>

      <TitleSimple title="Account Information" sx={{paddingLeft: "10pt", paddingRight: "10pt"}}/>

      <Box component="div" sx={{ paddingX: '10pt', overflow: "auto" }}> 

        

        <Typography sx={{marginTop: "20pt"}}>Name: {account.accountName}</Typography>

        <Box component="div" sx={{ height: '20pt' }} ></Box>

        <Typography>Billing Address:</Typography>
        <AddressView address={account.billingAddress} />

        <Box component="div" sx={{ height: '20pt' }} ></Box>

        {account.roleMap ?
          <>
            <Typography>Account Roles: {account.roleMap.getAccountFriendlyRoleNames(account.accountRoles)}</Typography>
            <Typography>All Sites Roles: {account.roleMap.getSiteFriendlyRoleNames(account.baseSiteRoles)}</Typography>
            </> 
        :
          <>
            <Typography>Account Roles: Loading...</Typography>
            <Typography>All Site Roles: Loading...</Typography>
          </>
        }
    
      </Box>
      
    </>
  )
})

export default AccountInfo;