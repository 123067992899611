import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Scene, Engine} from 'react-babylonjs'
import '@babylonjs/loaders'
import "@babylonjs/loaders/glTF";
import { Color3, Color4, Quaternion} from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import CamerasLayer from "./CamerasLayer";
import SiteModelLayer from "./SiteModelLayer";
import TrackletsLayer from "./TrackletsLayer";
import SiteNavigator from "../models/SiteNavigator";
import CameraPose from "../models/CameraPose";
import CameraCoverageLayer from "./CameraCoverageLayer";
import { useScanAreaLayoutViewModel } from "../models/layout/ScanAreaLayoutViewModel";
import AreaModelEntity from "../models/layout/AreaModelEntity";
import { ModelType } from "../models/ModelType";
import PointersLayer from "./PointersLayer";


const SiteViewerScene : FunctionComponent<{siteNavigator: SiteNavigator, children?: React.ReactNode}> = observer(({siteNavigator, children}) => {
  const viewModel = useScanAreaLayoutViewModel();
  const [areaModelEntity, setAreaModelEntity] = useState<AreaModelEntity | undefined>(undefined);
  const [areaModelEntityToUse, setAreaModelEntityToUse] = useState<AreaModelEntity | undefined>(undefined);

  useEffect(() => {

    if (viewModel && viewModel.scanAreaLayout && siteNavigator.currentScan) {
      const areaModelEntityLidar = viewModel.scanAreaLayout.areaModels.find(areaModel => areaModel.modelType === ModelType[ModelType.Lidar]);
      const areaModelEntity = viewModel.scanAreaLayout.areaModels.find(areaModel => areaModel.modelType === ModelType[siteNavigator.currentModelType]);
      setAreaModelEntity(areaModelEntity);
      if (areaModelEntity === undefined || areaModelEntity.usePoseFromLidar === false) {
        setAreaModelEntityToUse(areaModelEntity);  
      } else {
        setAreaModelEntityToUse(areaModelEntityLidar);
      }
    }
  }, [siteNavigator.currentScan, siteNavigator.currentModelType, viewModel, viewModel?.scanAreaLayout, areaModelEntity?.usePoseFromLidar]);


  return (
   <>
        <Engine antialias adaptToDeviceRatio canvasId="babylon-js" renderOptions={{whenVisibleOnly: true,}}>
            <Scene clearColor={new Color4(.5,.5,1,1)}>

              {true &&
              <arcRotateCamera position={new Vector3(0, 5, -5)} name="Camera1" radius={1} alpha={Math.PI / 2} beta={Math.PI / 2} target={new Vector3(0,0,0) }
                  inertia={0} wheelDeltaPercentage={.05} panningSensibility={500} panningInertia={0} maxZ={1000} minZ={.1} setActiveOnSceneIfNoneActive/>
              };

              <hemisphericLight name="light1" intensity={0.7} direction={new Vector3(0, 1, 0)}/>
              
              <lines name="zaxis" points={[new Vector3(0, 0, 0), new Vector3(0, 0, 2)]} color={Color3.Blue()} />
              <lines name="yaxis" points={[new Vector3(0, 0, 0), new Vector3(0, 2, 0)]} color={Color3.Green()} />
              <lines name="xaxis" points={[new Vector3(0, 0, 0), new Vector3(2, 0, 0)]} color={Color3.Red()} />

              {children}
              <transformNode name="modelPosition" position={areaModelEntityToUse?.pose.position.asLHVector3 ?? Vector3.Zero()}>
                <transformNode name="modelRotation" rotationQuaternion={areaModelEntityToUse?.pose.orientation.asLHQuaternion ?? Quaternion.Identity()}>
                  <transformNode name="modelScale" scaling={new Vector3(areaModelEntityToUse?.scale ?? 1, areaModelEntityToUse?.scale ?? 1, areaModelEntityToUse?.scale ?? 1)}>
                    <SiteModelLayer siteNavigator={siteNavigator} />
                    {viewModel && viewModel.showCameras && viewModel &&
                      <CamerasLayer layoutViewModel={viewModel}/>}
                      {/* <PointersLayer siteNavigator={siteNavigator}/> */}
                  </transformNode>
                </transformNode>
              </transformNode>
            </Scene>
        </Engine>
    </>
  )
})

export default SiteViewerScene;


