import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import GeneralCard from './GeneralCard';
import { People } from '@mui/icons-material';
import { Box } from '@mui/material';
import Account from '../models/Account';

interface AccountUsersCardProps {
    account: Account;
}

const AccountUsersCard: React.FC<AccountUsersCardProps> = observer(({ account }) => {
    useEffect(() => {
        account.loadUserAccountRolesAsync();
        account.loadAccountInvites();
    }, [account]);

    return (
        <GeneralCard
            title="Team Members"
            icon={<People style={{ color: 'aqua', width: "50pt", height: "50pt" }} />}
            moreLink={`/accounts/${account.accountId}/users`}
        >
            <Box component="div" display="flex" flexDirection="column" sx={{ gap: "10px" }}>
                <Typography>Number of members: {account.userAccountRoles ? account.userAccountRoles.length : "Loading..."}</Typography>
                <Typography>Number of invited: {account.accountInvites ? account.accountInvites.filter(item => !item.accepted).length : "Loading..."}</Typography>
            </Box>
        </GeneralCard>
    );
});

export default AccountUsersCard;
