import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Typography, Box } from "@mui/material";

const Welcome : FunctionComponent = observer(() => {

  
  
  return (
    <Box component="div" display="flex" flexDirection="column">

      <Box component="div" sx={{ marginX: '20pt' }}>
        <Box component="div" sx={{height: 20}}/>
        <Typography variant="h5" color="inherit" component="div">
          Welcome
        </Typography>            
        <Box component="div" sx={{height: 20}}/>
        <Typography>
          Welcome to Site Bionics. This application requires that you login and have access to an account.
        </Typography>
        <Box component="div" sx={{height: 20}}/>
        <Typography>
          In order to login you need a Microsoft account. You can you your O365 account, your outlook.com account, or create an account using your existing email address. 
          Click the login button to continue
        </Typography>
        <Box component="div" sx={{height: 20}}/>
        <Typography>
          Once you login you need to have access to a Site Bionics account. This can be granted by your Site Bionics account administrator. Or you can create a Site Bionics 
          account.
        </Typography>
      </Box>

  </Box>
  )
})

export default Welcome;