import { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react"
import { Color3, Quaternion, Vector2 } from '@babylonjs/core/Maths/math'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import ScanAreaLayout from "../models/layout/ScanAreaLayout";
import ScanAreaLayoutViewModel from "../models/layout/ScanAreaLayoutViewModel";
import CameraEntity from "../models/layout/CameraEntity";
import { undistortPointBrownConrady } from "../util/MathUtil";


const CameraAlignmentPointLayer: FunctionComponent<{ alignmentPoint: Vector2, cameraEntity: CameraEntity, groundPlaneHeight: number}> 
      = observer(({ alignmentPoint, cameraEntity, groundPlaneHeight}) => {
  const [position, setPosition] = useState<Vector3 | undefined>(undefined);
  const [positionUndistorted, setPositionUndistorted] = useState<Vector3 | undefined>(undefined);

  // this effect is called whenever the camera pose or ground plane changes
  // it calculates the position of the alignment point projected to the ground plane in 3d space
  // for both distorted and undistorted points
  useEffect(() => {
    
    let position = cameraEntity.projectPointToGroundPlane(alignmentPoint, groundPlaneHeight);
    setPosition(position);

    let alignmentPointUndistorted = cameraEntity.undistortPoint(alignmentPoint);
    let positionUndistorted = cameraEntity.projectPointToGroundPlane(alignmentPointUndistorted, groundPlaneHeight);
    setPositionUndistorted(positionUndistorted);      

  }, [cameraEntity.fieldOfView, cameraEntity.distortionK,
      cameraEntity.pose.position.x, cameraEntity.pose.position.y, cameraEntity.pose.position.z, 
      cameraEntity.pose.orientation.x, cameraEntity.pose.orientation.x, cameraEntity.pose.orientation.z, groundPlaneHeight]);

  return (
    <>
      {position && positionUndistorted && 
      <>
        {/* <transformNode name="transformNode" position={new Vector3(-(position.x), position.y, position.z)}>
          <sphere name="sphere" diameter={0.25} position={new Vector3(0, 0, 0)}>
            <standardMaterial name="matial" diffuseColor={Color3.Green()} />
            <lines name="lines" points={[new Vector3(0, 0, 0), new Vector3(0, 2, 0)]} color={Color3.Green()} />
          </sphere>
        </transformNode> */}
        <transformNode name="transformNode" position={new Vector3(-(positionUndistorted.x), positionUndistorted.y, positionUndistorted.z)}>
          <sphere name="sphere" diameter={0.25} position={new Vector3(0, 0, 0)}>
            <standardMaterial name="material" diffuseColor={cameraEntity.camera?.color} />
            <lines name="lines" points={[new Vector3(0, 0, 0), new Vector3(0, 2, 0)]} color={cameraEntity.camera?.color} />
          </sphere>
        </transformNode>
      </>
      }
    </>
  )
})

const CamerasLayer: FunctionComponent<{ layoutViewModel: ScanAreaLayoutViewModel }> = observer(({ layoutViewModel }) => {
  const lineLength = 0.25;

  return (
    <>
      {layoutViewModel.scanAreaLayout.cameras.map((c) => (
        <>
        <transformNode name="tranform" key={c.cameraId} position={new Vector3(-(c.pose.position.x), c.pose.position.y, c.pose.position.z)}>
          <transformNode name="tranform" rotationQuaternion={new Quaternion((c.pose.orientation.x), -c.pose.orientation.y, -c.pose.orientation.z, c.pose.orientation.w)}>
            <sphere name="sphere" diameter={0.05}>
              {/* <standardMaterial name="material" diffuseColor={siteNavigator.currentCamera?.cameraId !== c.cameraId ? Color3.Red() : Color3.Green()} /> */}
              <standardMaterial name="material" diffuseColor={Color3.Green()} />
              <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(0, 0, lineLength)]} color={Color3.Blue()} />
              <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(0, lineLength, 0)]} color={Color3.Green()} />
              <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(lineLength, 0, 0)]} color={Color3.Red()} />
              <lines name="line" points={[new Vector3(0, 0, 0), new Vector3(0, 0, -10)]} color={Color3.White()} />
            </sphere>
          </transformNode>
        </transformNode>
        {c.alignmentPoints.map((point, index) => (  
          <CameraAlignmentPointLayer key={`alignment-point-${index}`} alignmentPoint={point.asVector2} cameraEntity={c} groundPlaneHeight={layoutViewModel.scanAreaLayout.groundFloorHeight} />
        ))}
        </>
      ))}
    </>
  )
})

export default CamerasLayer;


