import React, { useState, useEffect } from 'react';

interface ElapsedTimeProps {
  time: Date;
}

const ElapsedTime: React.FC<ElapsedTimeProps> = ({ time }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const formatDateDifference = (startDate: Date, endDate: Date): string => {
    const diff = endDate.getTime() - startDate.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    let result = "";
    if (days > 0) result += `${days} day${days > 1 ? 's' : ''}, `;
    if (hours > 0) result += `${hours} hour${hours > 1 ? 's' : ''}, `;
    if (minutes > 0) result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    if (days === 0 && hours === 0) {
        if (minutes > 0)
            result += ', '
        result += `${seconds} second${seconds > 1 ? 's' : ''}`;
    }

    return result;
  };

  return (
    <div>
      {formatDateDifference(time, currentTime)}
    </div>
  );
};

export default ElapsedTime;
