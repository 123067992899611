import { FunctionComponent } from "react";
import { observer } from "mobx-react"
import { Box, Slider, Stack, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import TriggerEntity from "../models/layout/TriggerEntity";


const TriggerProperties: FunctionComponent<{ trigger: TriggerEntity }> = observer(({ trigger }) => {


  return (
    <div>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row">
            <DoorSlidingOutlinedIcon fontSize="small"/>
            <Box component="div"  width={10}/>
            <Typography>Trigger Properties</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {trigger.name}

          <form>
            <TextField
              label="Name"
              value={trigger.name}
              onChange={(e) => {trigger.setName(e.target.value)}}
              fullWidth
              margin="normal"
            />
            <Slider
              size="small"
              value={trigger.radius}
              min={0.5}
              max={5}
              step={0.1}
              valueLabelDisplay="auto"
              onChange={(e, v) => {trigger.setRadius(v as number)}}
            />
            <TextField
              label="Position X"
              value={trigger.pose.position.x}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              value={trigger.pose.position.y}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Z"
              value={trigger.pose.position.z}
              type="number"
              fullWidth
              margin="normal"
            />
            {/* <TextField
              label="Position X"
              value={camera.position?.x}
              type="number"
              onChange={(e) => {camera.position.setX(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Y"
              type="number"
              value={camera.position?.y}
              onChange={(e) => {camera.position.setY(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Position Z"
              type="number"
              value={camera.position?.z}
              onChange={(e) => {camera.position.setZ(parseFloat(e.target.value))}}
              fullWidth
              margin="normal"
            /> */}
          </form>
        </AccordionDetails>
      </Accordion>
    </div>

  )
})

export default TriggerProperties;