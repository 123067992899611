
import { action, makeObservable, observable } from "mobx";
import DataContext from "./DataContext";
import Site from "./Site";
import Scan from "./Scan";

export default class ScanArea {

    private dataContext: DataContext

    @observable site: Site;
    @observable scanAreaId: string;
    @observable scanAreaName: string
    @observable scans?: Scan[];

    constructor(dataContext: DataContext, site: Site, scanAreaId: string, areaName: string) {
        this.dataContext = dataContext;
        this.site = site;
        this.scanAreaId = scanAreaId;
        this.scanAreaName = areaName;
        makeObservable(this);
    }
    
    @action
    setScans(scans?: Scan[]) {
        this.scans = scans;
    }

    @action
    async loadScansAsync(refresh: boolean = false): Promise<Scan[] | undefined> {
        if (this.scans === undefined || refresh) {
            let scans = await this.dataContext.service.fetchScanListAsync(this);
            this.setScans(scans);
        }
        return this.scans;
    }

}
