import React, { useState, useEffect } from 'react';
import Account from "../models/Account";
import { accountCache } from '../models/Cache';
import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import { observer } from "mobx-react-lite";
import { Tooltip } from '@mui/material';

interface CachedAccountControlProps {
  accountId: string;
}

const CachedAccountControl: React.FC<CachedAccountControlProps> = ({ accountId }) => {

  const [account, setAccount] = useState<Account | undefined | null>(undefined);

  const siteBionicsApplcation = useSiteBionicsApplication();

  useEffect(() => {
    const fetchData = async () => {
      let account = await accountCache.getObject(accountId, () => siteBionicsApplcation.service.fetchAccountAsync(accountId));

      setAccount(account);
    };

    fetchData();
  });

  if (account === undefined) {
    return (<Tooltip title={accountId}><span>Loading...</span></Tooltip>)
  } else if (account === null) {
    return (<span>{accountId}</span>);
  } else {
    return (<Tooltip title={accountId}><span>{account.accountName}</span></Tooltip>)
  }

};

export default observer(CachedAccountControl);
