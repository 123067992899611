import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";


export class SelectionManager {
  @observable selection: any = undefined;

  constructor() {
    makeObservable(this);
  }
  
  @action setSelection(newSelection: any) {
    this.selection = newSelection;
  }
}


export const SelectionManagerContext = createContext<SelectionManager | null>(null);

export const useSelectionManager = () => {
    const selectionManager = useContext(SelectionManagerContext);
  
    if (!selectionManager) {
      throw new Error(
        "useSelectionManager has to be used within <SelectionManagerContext.Provider>"
      );
    }
  
    return selectionManager;
};
