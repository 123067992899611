import { DataModel, detach, Draft, draft, getSnapshot, model, Model, modelAction, prop, Ref, rootRef, UndoManager, undoMiddleware, UndoStore } from "mobx-keystone";
import { action, computed, makeObservable, observable } from "mobx";
import CameraPose from "../CameraPose";
import EntranceEntity from "./EntranceEntity";
import RegionEntity from "./RegionEntity";
import ScanAreaLayout from "./ScanAreaLayout";
import LayoutEntity, { layoutEntityRef } from "./LayoutEntity";
import { createContext, useContext } from "react";

export const ScanAreaLayoutViewModelContext = createContext<ScanAreaLayoutViewModel | undefined>(undefined);

export const useScanAreaLayoutViewModel = () => {
    const viewModel = useContext(ScanAreaLayoutViewModelContext);
    return viewModel;
};


@model("SiteBionics/ScanAreaLayoutViewModel")
export default class ScanAreaLayoutViewModel extends Model({
    scanAreaLayout: prop<ScanAreaLayout>().withSetter(),
    selectedModelRef: prop<Ref<LayoutEntity> | undefined>(),
    undoData: prop<UndoStore>(() => new UndoStore({})),
}) {
    

    @computed
    get selectedEntity() {
      return this.selectedModelRef ? this.selectedModelRef.current : undefined
    }
  
    @modelAction
    selectEntity(entity: LayoutEntity | undefined) {
      this.selectedModelRef = entity ? layoutEntityRef(entity) : undefined
    }

    // things that need undo/redo are declared above as Model properties
    // things that do not need undo/redo are declared below as observable properties

    @observable showEntrances: boolean = false;
    @observable showTriggers: boolean = false;
    @observable showRegions: boolean = false;
    @observable showCameras: boolean = false;
    @observable showCameraPane: boolean = false;
  
    @action setShowEntrances(showEntrances: boolean) { this.showEntrances = showEntrances; }
    @action setShowTriggers(showTriggers: boolean) { this.showTriggers = showTriggers; }
    @action setShowRegions(showRegions: boolean) { this.showRegions = showRegions; }
    @action setShowCameras(showCameras: boolean) { this.showCameras = showCameras; }
    @action setShowCameraPane(showCameraPane: boolean) { this.showCameraPane = showCameraPane; }
}

