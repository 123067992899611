import { makeObservable, observable, action } from "mobx";

export default class UserSiteRole {
  @observable accountId: string;
  @observable siteId: string;
  @observable userId: string;  
  @observable siteRoles: string[];
  
  constructor(accountId: string, siteId: string, userId: string, siteRoles: string[]) {
    this.accountId = accountId;
    this.siteId = siteId;
    this.userId = userId;
    this.siteRoles = siteRoles;
    makeObservable(this);
  }

  clone() {
    return new UserSiteRole(this.accountId, this.siteId, this.userId, this.siteRoles.slice());
  }

  @action
  updateFrom(data: UserSiteRole) {
    this.siteRoles = data.siteRoles.slice();    
  }
}