import React, { useState, useEffect } from 'react';
import UserInfo from "../models/UserInfo";
import { useUserCache } from '../models/CachedUsersContext';
import { Tooltip } from '@mui/material';

interface CachedUserControlProps {
  userId: string;
}

const CachedUserControl: React.FC<CachedUserControlProps> = ({ userId }) => {

  const [userInfo, setUserInfo] = useState<UserInfo | undefined | null>(undefined);

  const { fetchUserInfoFromCache } = useUserCache();

  useEffect(() => {
    const fetchData = async () => {
      var userInfo = await fetchUserInfoFromCache(userId);
      setUserInfo(userInfo);
    };

    fetchData();
  });

  if (userInfo === undefined) {
    return (<Tooltip title={userId}><span>Loading...</span></Tooltip>);
  } else if (userInfo === null) {
    return (<span>{userId}</span>);
  } else {
    return (<Tooltip title={userId}><span>{userInfo.email}</span></Tooltip>);
  }  
};

export default CachedUserControl;
