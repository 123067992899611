import { useState, FunctionComponent, useRef, useEffect } from "react";
import { observer } from "mobx-react"

import { Box, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Slider, Switch, TextField, Typography } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { VideoPlayer } from "../components/VideoPlayer";
import SiteViewerScene from "../components-3d/SiteViewerScene";
import { SelectionManager, SelectionManagerContext } from "../util/SelectionManager";
import { ModelType } from "../models/ModelType";
import TrackedObjectsTimeline from "../components/TrackedObjectsTimeline";
import TrackedObject from "../models/TrackedObject";
import { encodeColor } from "../util/Colors";
import { Color3, Vector2, Vector3 } from "@babylonjs/core";
import Tracklet from "../models/Tracklet";
import { useSiteNavigator, useSitePageBreadcrumbs, useSite } from "./SitePage";
import Timeline from "../components/Timeline";
import DurationPicker from "../components/DurationPicker";
import { useSearchParams } from "react-router-dom";
import Camera from "../models/Camera";
import { projectPointToGroundPlane, undistortPointBrownConrady } from "../util/MathUtil";
import NoAccess from "../components/NoAccess";
import TrackletDataPoint from "../models/TrackletDataPoint";
import { FragmentUploadPriority } from "../models/FragmentUploadPriority";
import TrackletsLayer from "../components-3d/TrackletsLayer";
import FilterDialog, { FilterEntry } from "../components/FilterDialog";

const Tracking: FunctionComponent = observer(() => {
  const siteBionicsApplication = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const [selectionManager] = useState(new SelectionManager());
  const [playListUrl, setPlayListUrl] = useState<string>("");
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoDivRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const breadcrumbs = useSitePageBreadcrumbs("Tracking");
  const [tracklets, setTracklets] = useState<Tracklet[]>([]);
  const [trackedObjects, setTrackedObjects] = useState<TrackedObject[]>([]);
  const [duration, setDuration] = useState<number>(1);
  const [videoStartTime, setVideoStartTime] = useState<Dayjs|undefined>(undefined);
  const [videoCurrentTime, setVideoCurrentTime] = useState<Dayjs|undefined>(undefined);
  const [videoTimeOffset, setVideoTimeOffset] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<Dayjs|undefined>(undefined);
  const [showTrackletsInTimeline, setShowTrackletsInTimeline] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterEntry[]>([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const site = useSite();
    
  // This effect runs only once when the component is mounted.
  // As a side effect the first scan area is selected.
  useEffect(() => {
    siteNavigator.site.loadAreasAsync().then((scanAreas) => {
      if (scanAreas?.length) {
          siteNavigator.setCurrentScanArea(scanAreas[0]);
      }
    });
    siteNavigator.site.loadCamerasAsync().then((cameras) => {
      if (cameras?.length) {
        siteNavigator.setCurrentCameraById(cameras[0].cameraId);
      }
    });
  }, []);

  // This effect is run when the current scan area changes (from above effect or UI).
  // It loads the scan area layout for the selected scan area.
  useEffect(() => {
    siteNavigator.currentScan?.loadScanAreaLayoutAsync().then((scanAreaLayout) => {
      
      siteNavigator.setCurrentScanAreaLayout(scanAreaLayout);

      const filterEntries: FilterEntry[] = scanAreaLayout?.triggers.map(trigger => ({
        tag: trigger.name, operation: 'None', duration: 0})) ?? [];
      setFilters(filterEntries);

    })
  }, [site, siteNavigator, siteNavigator.currentScan]);

  // This effect runs whenever the videoCurrentTime (or videoTimeOffset slider) changes
  // It propogates the time to both local state and the site navigator.
  useEffect(() => {
    if (!videoCurrentTime) return;
    let currentTimeWithOffset = videoCurrentTime.add(videoTimeOffset, "second");
    siteBionicsApplication.setCurrentTime(currentTimeWithOffset);
    setCurrentTime(currentTimeWithOffset);
  }, [videoCurrentTime, videoTimeOffset]);


  useEffect(() => {
    if (canvasRef.current && videoContainerRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const { width: canvasWidth, height: canvasHeight } = videoContainerRef.current.getBoundingClientRect();
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // camera view outline
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.strokeStyle = encodeColor(siteNavigator.currentCamera?.color ?? Color3.Black());
        ctx.lineWidth = 2; 
        ctx.beginPath();
        ctx.rect(2, 2, canvasWidth-2, canvasHeight-2);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      
        // bounding boxes
        tracklets.forEach(tracklet => {
          if (currentTime && tracklet.camera?.cameraId === siteNavigator.currentCamera?.cameraId) {
            let  closestDataPoint = tracklet.findClosestDataPoint(currentTime.toDate());
            if (closestDataPoint) {
              var belongsToSelectedTrackedObject = (siteNavigator.currentTrackedObject && tracklet.trackedObject === siteNavigator.currentTrackedObject);
              var isSelectedTracklet = (siteNavigator.currentTracklet && tracklet === siteNavigator.currentTracklet);

              var left = closestDataPoint.bbox[0];
              var top = closestDataPoint.bbox[1];
              var width = closestDataPoint.bbox[2];
              var height = closestDataPoint.bbox[3];
              ctx.strokeStyle = belongsToSelectedTrackedObject ?  "#FFFFFF" : (encodeColor(siteNavigator.currentCamera?.color ?? Color3.Black()));
              ctx.fillStyle = "#00000000"
              ctx.lineWidth = belongsToSelectedTrackedObject ? 2 : 1;
              ctx.strokeRect(left * canvasWidth, top * canvasHeight, width * canvasWidth, height * canvasHeight);
              

               // Add trackletId label above the bounding box
              ctx.strokeStyle = "#FFFFFF"
              ctx.lineWidth = 1;
              ctx.font = '15px Arial';
              ctx.strokeText(`ID: ${tracklet.objectId}`, left * canvasWidth, (top * canvasHeight) - 10);
              if (tracklet.trackedObject?.startsAtEntrance)
                ctx.strokeText(`In: ${tracklet.trackedObject.startsAtEntrance}`, left * canvasWidth, (top * canvasHeight) + 15);
              if (tracklet.trackedObject?.endsAtEntrance)
                ctx.strokeText(`Out: ${tracklet.trackedObject.endsAtEntrance}`, left * canvasWidth, (top * canvasHeight) + 30);

            }
          }
        });
      }
    }
  }, [siteNavigator.currentTracklet, currentTime, tracklets]);

  function handleCameraChange(event: SelectChangeEvent<string>): void {
    siteNavigator.setCurrentCameraById(event.target.value);
    if (siteNavigator.currentTracklet && siteNavigator.currentCamera) {
      showVideoForCamera(siteNavigator.currentTracklet!, siteNavigator.currentCamera!);
    }
}
  const handleVideoTimeUpdate = (currentTime: Dayjs) => {
    setVideoCurrentTime(currentTime);
  };

  const handleVideoTimeOffsetUpdate = (event: Event, newValue: number | number[]) => {
    setVideoTimeOffset(newValue as number);    
  };

  const handleTrackedObjectClicked = (trackedObject: TrackedObject) => {
    siteNavigator.setCurrentTrackedObject(trackedObject);
    let tracklet = trackedObject.tracklets[0];
    siteNavigator.setCurrentTracklet();
    siteNavigator.setCurrentCamera(tracklet.camera);
    if (tracklet.camera) {
      showVideoForTracklet(tracklet);
    }

  };

  const handleTrackletClicked = (tracklet: Tracklet) => {
    siteNavigator.setCurrentTrackedObject(tracklet.trackedObject);
    siteNavigator.setCurrentTracklet(tracklet);
    siteNavigator.setCurrentCamera(tracklet.camera);
    if (tracklet.camera) {
      showVideoForTracklet(tracklet);
    }
  };

  function showVideoForTracklet(tracklet: Tracklet) {
    showVideoForCamera(tracklet, tracklet.camera!);
  }

  function showVideoForCamera(tracklet: Tracklet, camera: Camera) {
    const clippedStartTime = dayjs(Math.max(tracklet.startTime.getTime(), siteBionicsApplication.startTime!.toDate().getTime()));
    const clippedEndTime = dayjs(Math.min(tracklet.endTime.getTime(), siteBionicsApplication.endTime!.toDate().getTime()));
    siteBionicsApplication.service.postUploadVideoRequest(
      siteNavigator.site.account.accountId, siteNavigator.site.siteId, siteNavigator.currentCamera!.cameraId,
      FragmentUploadPriority.UserHigh, clippedStartTime, clippedEndTime)
      .then((actualStartTime) => {
        setVideoStartTime(actualStartTime ?? clippedStartTime);
        siteNavigator.setCurrentCamera(camera);

        siteBionicsApplication.service.videoPlayListUrl(
            siteNavigator.site.account.accountId, siteNavigator.site.siteId, siteNavigator.currentCamera!.cameraId,
            clippedStartTime, clippedEndTime).then((url) => {setPlayListUrl(url)});
    });
  }

  function runQuery(): void {
    siteBionicsApplication.service.fetchTrackedObjectWithTrackletDataAsync(siteNavigator.site,
        siteBionicsApplication.startTime!, siteBionicsApplication.endTime!).then(([trackedObjects, tracklets]) => {
        setTrackedObjects(trackedObjects);
        setTracklets(tracklets);
        siteNavigator.setCurrentTracklets(tracklets);
      });
  }

  const handleOpenDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseDialog = () => {
      setFilterDialogOpen(false);
  };

  const handleSaveFilters = (updatedEntries: FilterEntry[]) => {
      setFilters(updatedEntries);
  };

  return (
    <SelectionManagerContext.Provider value={selectionManager}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>

        <Box component="div" overflow="hidden" display="flex" height="100%" flexDirection="column">

          <Box component="div" display="flex" width="100%" flexDirection="row" >
            <BreadcrumbBar breadcrumbs={breadcrumbs} />
            <Box component="div" sx={{ flexGrow: 1}}/>
            <Typography style={{alignSelf: "flex-end", marginRight: 15}} color='gray'>time: {currentTime?.format('HH:mm:ss.SSS')}</Typography>
          </Box>
          
          <Box component="div" width='100%'  sx={{  display: 'flex', flexDirection: 'row', marginLeft: '10px', marginTop: '10px', marginBottom: '10px'}}>
            <Box component="div" display="flex" width='100%' >
              <DateTimePicker
                label="Start Time"
                value={siteBionicsApplication.startTime}
                onChange={(newValue) => {
                  siteBionicsApplication.setStartTime(newValue!);
                  siteBionicsApplication.setEndTime(siteBionicsApplication.startTime!.add(duration, "minute"));
                }}
              />
              <DurationPicker 
                onChange={(newDuration) => {
                  setDuration(newDuration);
                  siteBionicsApplication.setEndTime(siteBionicsApplication.startTime!.add(newDuration, "minute"));
                }}
              />
              <FormControl>
                <InputLabel id="camera-list-label">Camera</InputLabel>
                <Select
                  labelId="camera-list-label"
                  id="camera-list"
                  value={siteNavigator.currentCamera?.cameraId ?? ""}
                  label="Camera"
                  onChange={handleCameraChange}>
                  {siteNavigator.site.cameras?.map((c) => (
                    <MenuItem key={c.cameraId} value={c.cameraId}>{c.cameraName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              
                 
              <Button id="play" onClick={() => { runQuery() }} size="large">Refresh</Button>
              
              <Button onClick={handleOpenDialog}>Filter</Button>
              <FilterDialog
                  open={filterDialogOpen}
                  onClose={handleCloseDialog}
                  entries={filters}
                  onSave={handleSaveFilters}
              />

              <Box component="div" sx={{ flexGrow: 1}}/>
       
              <FormControlLabel style={{alignSelf: "flex-end", marginRight: 15}} control={<Slider sx={{ width: "100px", marginRight: "10px"}} value={videoTimeOffset} max={15} min={-15} step={0.1} onChange={handleVideoTimeOffsetUpdate}/>} label="Video Offset"/>
              <FormControlLabel style={{alignSelf: "flex-end", marginRight: 20}} control={<Switch checked={showTrackletsInTimeline} onChange={(event) => setShowTrackletsInTimeline(event.target.checked)}/>} label="Show Tracklets" />
            </Box>
          </Box>

          <Box component="div" sx={{ width: '100%', height: '50px', flexGrow: 0, flexShrink: 0}} margin={'5px'}>
            <Timeline startDate={siteBionicsApplication.startTime!.toDate()} endDate={siteBionicsApplication.endTime!.toDate()} onTimeChange={(time) => {setCurrentTime(time); siteBionicsApplication.setCurrentTime(time);}}/>
          </Box>

          <Box component="div" sx={{ width: '100%', display: 'flex', flexGrow: 1, flexDirection: 'row' }}>
            <Box component="div" ref={videoDivRef} sx={{ width: '50%', height: '100%'}} >
              <Box component="div" ref={videoContainerRef} sx={{ width: '100%', position: 'relative'}}>
                <VideoPlayer  hlsSrc={playListUrl} startTime={videoStartTime} posterSrc="" onTimeUpdate={handleVideoTimeUpdate} />
                <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', zIndex: 10}} />
              </Box>
            </Box>
            <Box component="div" display={'flex'} sx={{width: '50%', height: '100%'}} style={{backgroundColor: '#00FF00FF'}}>
              <SiteViewerScene siteNavigator={siteNavigator}>
                <TrackletsLayer siteNavigator={siteNavigator} onTrackedObjectClicked={handleTrackedObjectClicked} showDetailedTracklets={showTrackletsInTimeline}/>
              </SiteViewerScene>
            </Box>
          </Box>

          <Box component="div" display="flex" sx={{ flexGrow: 1, width: '100%', height: '100%'}}>
            <TrackedObjectsTimeline trackedObjects={trackedObjects} tracklets={tracklets} onTrackletClicked={handleTrackletClicked} onTrackedObjectClicked={handleTrackedObjectClicked} siteNavigator={siteNavigator} showTracklets={showTrackletsInTimeline}/>
          </Box>

        </Box>
      </LocalizationProvider >
    </SelectionManagerContext.Provider>
  )
})

export default Tracking;