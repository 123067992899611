import { getModelMetadata, fromSnapshot, Model } from "mobx-keystone";
import ScanArea from "../models/ScanArea";
import EntranceEntity from "../models/layout/EntranceEntity";
import RegionEntity from "../models/layout/RegionEntity";
import PoseModel from "../models/layout/PoseModel";
import Vector3Model from "../models/layout/Vector3Model";
import QuaternionModel from "../models/layout/QuaternionModel";
import { GUID } from "@babylonjs/core";

function pascalToCamelCase(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function convertPascalToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => convertPascalToCamelCase(item)) as unknown as any;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = pascalToCamelCase(key);
      acc[camelCaseKey] = convertPascalToCamelCase(obj[key]);
      return acc;
    }, {} as Record<string, any>) as any;
  }
  return obj;
}

function camelToPascalCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertCamelToPascalCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => convertCamelToPascalCase(item)) as unknown as any;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const pascalCaseKey = camelToPascalCase(key);
      acc[pascalCaseKey] = convertCamelToPascalCase(obj[key]);
      return acc;
    }, {} as Record<string, any>) as any;
  }
  return obj;
}

const modelPropertyTypes : any = {
  "SiteBionics/ScanAreaLayout": {
    entrances: "SiteBionics/EntranceEntity",
    triggers: "SiteBionics/TriggerEntity",
    regions: "SiteBionics/RegionEntity",
    areaModels: "SiteBionics/AreaModelEntity",
    cameras: "SiteBionics/CameraEntity"
  },
  "SiteBionics/AreaModelEntity": {
    pose: "SiteBionics/PoseModel",
  },
  "SiteBionics/RegionEntity": {
    pose: "SiteBionics/PoseModel",
  },
  "SiteBionics/EntranceEntity": {
    pose: "SiteBionics/PoseModel",
  },
  "SiteBionics/TriggerEntity": {
    pose: "SiteBionics/PoseModel",
  },
  "SiteBionics/CameraEntity": {
    pose: "SiteBionics/PoseModel",
    alignmentPoints: "SiteBionics/Vector2Model",
  },
  "SiteBionics/PoseModel": {
    position: "SiteBionics/Vector3Model",
    orientation: "SiteBionics/QuaternionModel",
  },
  "SiteBionics/Vector3Model": {
    // no nested models here
  },
  "SiteBionics/Vector2Model": {
    // no nested models here
  },
  "SiteBionics/QuaternionModel": {
    // no nested models here
  },

};

// Utility function to add $modelType recursively
export function addModelTypeAndId(json: any, modelType: string): any {
  if (Array.isArray(json)) {
    return json.map(item => addModelTypeAndId(item, modelType));
  } else if (json !== null && typeof json === 'object') {
    const properties = modelPropertyTypes[modelType] || {};

    const transformed = {
      $modelType: modelType,
      id: GUID.RandomId(),
      ...Object.keys(json).reduce((acc, key) => {
        const value = json[key];
        const propModelType = properties[key];

        if (Array.isArray(value) && propModelType) {
          // Handle arrays of models
          acc[key] = addModelTypeAndId(value, propModelType);
        } else if (propModelType) {
          // Handle nested models
          acc[key] = addModelTypeAndId(value, propModelType);
        } else {
          // Handle other properties
          acc[key] = value;
        }
        return acc;
      }, {} as Record<string, any>)
    };

    return transformed;
  }
  return json;
}

export function removeModelType(json: any): any {
  if (Array.isArray(json)) {
    return json.map(item => removeModelType(item));
  } else if (json !== null && typeof json === 'object') {
    return Object.keys(json).reduce((acc, key) => {
      if (key !== "$modelType") {
        acc[key] = removeModelType(json[key]);
      }
      return acc;
    }, {} as Record<string, any>);
  }
  return json;
}
